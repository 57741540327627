import { useSearchParams } from "react-router-dom";

import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Text,
  HStack,
  useDisclosure,
  Box,
  PopoverFooter,
  ButtonGroup,
} from "@chakra-ui/react";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { Suspense } from "react";
import { Option } from "src/types/Options";
import { useTranslation } from 'react-i18next';
import TextIntl from "./TextIntl";

interface FilterProps {
  title: string,
  options: Option[],
  name: string,
  optionsObj: any
}

function Filter({
  title, options, name, optionsObj,
}: FilterProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentType = searchParams.get(name);

  const { t } = useTranslation();

  return (
    <Popover onClose={onClose} isOpen={isOpen} colorScheme="red">
      <PopoverTrigger>
        <Button
          rightIcon={<TriangleDownIcon boxSize={4} />}
          // colorScheme="gray"
          background="#1C2F4E"
          color={currentType ? "##FF8008" : '##FFFFFF'}
          variant="ghost"
          // textTransform="capitalize"
          onClick={onOpen}
          borderRadius="17px"
          h={{ base: '40px', xl: '50px' }}
          display="flex"
          alignItems="center"
          minW="150px"
          justifyContent="space-between"
          fontSize={{ base: '14px', xl: '18px' }}
          mb={{ base: '8px !important' }}
        >
          {optionsObj[currentType as string] || currentType || (title && t(title))}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        bgColor="white"
        color="#5F5F5F"
        boxShadow="4px 5px 16px rgba(0, 0, 0, 0.25);"
        borderRadius="8px"
      >
        <PopoverCloseButton />
        <PopoverHeader borderColor="#EBEBEB" fontWeight="bold" color="#000000">{title && t(title)}</PopoverHeader>
        <PopoverBody>
          <Box maxH="56" overflowY="auto">
            {options.map((item) => (
              <HStack
                onClick={() => {
                  searchParams.set(name, item.value);
                  setSearchParams(searchParams);
                  onClose();
                }}
                color={item.value === currentType ? "orange.500" : undefined}
                cursor="pointer"
                _hover={{ bg: "orange.100" }}
                borderRadius="md"
                key={item.value}
                p={2}
              >
                <Box
                  flexShrink={0}
                  w={2.5}
                  h={2.5}
                  bg="orange.500"
                  borderRadius="full"
                />
                <Text
                  fontSize="sm"
                  fontWeight="semibold"
                  textTransform="capitalize"
                >
                  {item.label}
                </Text>
              </HStack>
            ))}
          </Box>
        </PopoverBody>
        <PopoverFooter borderColor="#EBEBEB">
          <ButtonGroup size="sm">
            <Button
              variant="outline"
              colorScheme="orange"
              onClick={() => {
                searchParams.delete(name);
                setSearchParams(searchParams);
                onClose();
              }}
            >
              <TextIntl text="delete" />
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

function FilterByReviewType({
  title, options, name, optionsObj,
}: FilterProps) {
  return (
    <Suspense
      fallback={(
        <Button variant="ghost" isLoading>
          {title}
        </Button>
      )}
    >
      <Filter options={options} title={title} name={name} optionsObj={optionsObj} />
    </Suspense>
  );
}

export default FilterByReviewType;
