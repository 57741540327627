import {
  Box, Table, Td, Tr,
  Text,
  Flex,
  Image,
  HStack,
  Tbody,
  Link,
} from "@chakra-ui/react";
import { ICON_BY_TOKENS } from "src/constants/token";
import FilterSingle from 'src/components/FilterSingle';
import FilterMultiple from 'src/components/FilterMultiple';
import FilterByRangerDate from 'src/components/FilterByRangerDate';
import SortByCreatedTime from 'src/components/SortByCreatedTime';
import {
  TRANSACTION_TYPE_OPTIONS,
  TRANSACTION_TYPE_OBJ,
  ALL_CATEGORIES,
  TRAN_TYPE_OBJ,
  TRANSACTION_STATE_OPTIONS,
  TRANSACTION_STATE_OBJ,
  TOKENS,
  TRAN_FLOW,
} from 'src/constants/transaction';
import useSearchParams from "src/utils/useSearchParams";
import { TransactionDocument, useTransactions } from "src/api/profile";
import dayjs from 'dayjs';
import { formatStringToNumber } from "src/utils/utils";
import FilterByPage from "src/components/FilterByPage";
import TextIntl from "src/components/TextIntl";

function Transactions() {
  const { params } = useSearchParams();
  const { data } = useTransactions(params);

  return (
    <Box
      bgColor="#132035"
      borderRadius="29px"
      py="24px"
      px={{ lg: "40px", base: '20px' }}
    >
      <Flex
        flexDirection="row"
        mb="10px"
        pb="10px"
        gap="8px"
        // borderBottom="1px solid #ebebeb"
        flexWrap="wrap"
      >
        <FilterSingle
          title="Type"
          name="trans_flow"
          options={TRANSACTION_TYPE_OPTIONS}
          optionsObj={TRANSACTION_TYPE_OBJ}
        />

        <FilterMultiple
          title="Category"
          name="trans_type"
          options={ALL_CATEGORIES}
          optionsObj={TRAN_TYPE_OBJ}
        />
        <FilterByRangerDate />
        <FilterSingle
          title="State"
          name="trans_state"
          options={TRANSACTION_STATE_OPTIONS}
          optionsObj={TRANSACTION_STATE_OBJ}
        />
        <FilterSingle
          title="Token"
          name="token"
          options={TOKENS}
          optionsObj={{}}
        />
        <SortByCreatedTime />
        {/* <FilterByReviewType /> */}
      </Flex>
      <hr style={{
        height: '4px',
        background: "#2A3951",
        border: "none",
        marginBottom: "18px",
      }}
      />
      <Box overflowX="auto">
        <Table w="full">
          <Tbody>
            {data?.items?.map((transaction: TransactionDocument) => (
              <Tr py="10px" verticalAlign="bottom" key={transaction.id}>
                <Td borderBottom={0} minW="300px">
                  <Text
                    fontSize="18px"
                    fontWeight="bold"
                    color="#fff"
                    letterSpacing="0.01em"
                    mb="6px"
                  >
                    {TRAN_TYPE_OBJ[transaction.trans_type] || transaction.trans_type}
                  </Text>
                  <Text
                    fontSize="16px"
                    fontWeight="400"
                    color="#fff"
                    letterSpacing="0.01em"
                  >
                    {transaction.description}
                  </Text>
                  {transaction.trans_hash
                    && (
                      <Link
                        isExternal
                        display="block"
                        color="#FF8008"
                        mt="4px"
                        textDecoration="underline"
                        href={`${process.env.REACT_APP_AVALANCHE_SCAN}/tx/${transaction.trans_hash}`}
                        target="_blank"
                      >
                        <TextIntl
                          fontSize="16px"
                          fontWeight="400"
                          letterSpacing="0.01em"
                          text="viewTransaction"
                        />
                      </Link>

                    )}
                </Td>
                <Td borderBottom={0} minW="300px">
                  <Text
                    fontSize="16px"
                    fontWeight="400"
                    color="rgba(255, 255, 255, 0.5)"
                    letterSpacing="0.01em"
                  >
                    {dayjs(transaction.created_at).format('DD MMM YYYY hh:mm A')}
                  </Text>
                </Td>
                <Td borderBottom={0}>
                  <Flex alignItems="center" justifyContent="flex-end">
                    <Image src={ICON_BY_TOKENS[transaction.token]} w="24px" mr="11px" />
                    <Text
                      fontSize="20px"
                      fontWeight="bold"
                      color={transaction?.trans_flow === TRAN_FLOW.sub ? "red.300" : 'green.300'}
                      letterSpacing="0.01em"
                    >
                      {transaction?.trans_flow === TRAN_FLOW.sub && '-'}
                      {formatStringToNumber(transaction.amount)}
                    </Text>
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>

        </Table>
      </Box>

      <FilterByPage totalPages={data?.meta?.totalPages} />
    </Box>
  );
}

export default Transactions;
