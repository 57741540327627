import { useWeb3React } from '@web3-react/core';
import React, { useEffect, useMemo, useState } from 'react';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import {
  Link, Button, Text,
} from '@chakra-ui/react';
import { displayAddress } from 'src/utils/utils';
import classes from './WalletStatus.module.scss';
import {
  injected,
  SUPPORT_CHAINIDS,
  walletconnect,
} from '../../utils/connectors';
import ConnectWallet from '../ConnectWallet/ConnectWallet';
// import { displayAddress } from '../../utils/utils'
import WrongNetwork from '../WrongNetwork';
import CopyButton from '../CopyButton';

function WalletStatus() {
  const {
    account, activate, deactivate, connector, active, chainId,
  } = useWeb3React();
  const [showConnectWallet, setShowConnectWallet] = useState(false);

  const wrongNetwork = useMemo(
    () => chainId && SUPPORT_CHAINIDS.indexOf(chainId) === -1,
    [chainId],
  );

  const handleDisconnect = async () => {
    localStorage.removeItem('currentAccount');
    localStorage.removeItem('connector');
    if (connector instanceof WalletConnectConnector) {
      await connector.walletConnectProvider?.disconnect();
      await connector.close();
    }

    await deactivate();

    window.location.reload();
  };

  useEffect(() => {
    if (account) {
      localStorage.setItem('currentAccount', account);
      localStorage.setItem(
        'connector',
        connector === injected ? 'INJECTED' : 'WALLET_CONNECT',
      );
      setShowConnectWallet(false);
    } else if (localStorage.getItem('currentAccount') && !active) {
      const currentConnector = localStorage.getItem('connector');
      if (currentConnector === 'WALLET_CONNECT') {
        activate(walletconnect, undefined, true).catch(() => {
          // console.log('error ====>', e)
          handleDisconnect();
        });
      } else {
        injected.isAuthorized().then((isAuthorized: boolean) => {
          if (isAuthorized) {
            activate(injected, undefined, true).catch(() => {});
          }
        });
      }
    }
  }, [account, active, connector]);

  return (
    <div className={classes.container}>
      {wrongNetwork && (
        <WrongNetwork
          className="h-[40px]"
          handleDisconnect={handleDisconnect}
        />
      )}
      {account && !wrongNetwork && (
        <div className={classes.wallet}>
          <Text fontSize="14px" fontWeight="500" color="#fff" mr="10px">
            {displayAddress(account)}
          </Text>
          <CopyButton text={account} />
          <Link
            onClick={handleDisconnect}
            borderColor="#FF8008"
            borderWidth={1}
            borderRadius="16px"
            h="50px"
            p="8px 32px"
            display="flex"
            _hover={{
              backgroundColor: '#FF8008',
              color: '#ffffff',
            }}
            color="#FF8008"
            alignItems="center"
            justifyContent="center"
            fontSize="16px"
            fontWeight="600"
            ml="20px"
          >
            Disconnect
          </Link>
        </div>
      )}
      {!account && (
        <Button
          bgColor="#FF8008"
          borderRadius="16px"
          p="8px 32px"
          _hover={{
            backgroundColor: '#FF8008',
          }}
          type="submit"
          onClick={() => {
            setShowConnectWallet(true);
          }}
          fontSize="16px"
          fontWeight="600"
        >
          <Text>
            Connect Wallet
          </Text>
        </Button>
      )}
      <ConnectWallet
        show={showConnectWallet}
        handleClose={() => setShowConnectWallet(false)}
      />
    </div>
  );
}

export default WalletStatus;
