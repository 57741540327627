import React, { useCallback } from "react";
import {
  SimpleGrid,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { isLoggedIn } from "src/utils/utils";
import AuthStore from "src/stores/AuthStore";
import { useItemOrders, itemShoe } from "src/api/item";
import useSearchParams from "../../utils/useSearchParams";
import Item from "./Item";

interface CardSneakerProps {
  userId?: number
}

export function CardSneaker({ userId }: CardSneakerProps) {
  const { params } = useSearchParams();
  const { data } = useItemOrders({ ...params, limit: 12 });
  const navigate = useNavigate();
  const handleDetail = useCallback((id: number) => {
    if (isLoggedIn()) {
      navigate(`/itemDetail/${id}`, { replace: true });
    } else {
      AuthStore.handleShowLoginModal(() => {
        navigate(`/itemDetail/${id}`, { replace: true });
      });
    }
  }, [navigate]);
  return (
    <SimpleGrid
      columns={{
        base: 1, sm: 2, md: 3, '2xl': 4,
      }}
      spacing={{ base: '12px', sm: '16px', md: '20px' }}
      justifyItems="center"

    >
      {data?.items?.map((item: itemShoe) => (
        <Item
          key={item.id}
          item={item}
          handleDetail={handleDetail}
        />
      ))}

    </SimpleGrid>
  );
}
