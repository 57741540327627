import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Button, Flex } from "@chakra-ui/react";
import { ConfirmInfoDto } from "src/stores/GlobalStore";
import TextIntl from "./TextIntl";

interface ConfirmDialogProps {
  isOpen: boolean;
  onClose: () => void;
  confirmInfo: ConfirmInfoDto | null | undefined
}

function ConfirmDialog({
  isOpen,
  onClose,
  confirmInfo,
}: ConfirmDialogProps) {
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      isCentered

    >
      <ModalOverlay />
      <ModalContent
        w="500px"
        maxW="95%"
        className="modal-content"
        bg="#132035"
        border="1px solid #BAAA96"
        rounded="xl"
      >
        <ModalBody className="modal-body">
          <TextIntl
            fontSize="24px"
            fontWeight="bold"
            color="#fff"
            mb="10px"
            textAlign="center"
            mt="40px"
            text={confirmInfo?.title}
          />

          <TextIntl
            fontSize="16px"
            fontWeight="400"
            color="#fff"
            mb="40px"
            textAlign="center"
            text={confirmInfo?.description}
          />

          <Flex alignItems="center" mb="40px">
            <Button
              flex={1}
              mr="23px"
              border="1px solid #FFFFFF"
              borderRadius="8px"
              color="#FFFFFF"
              fontWeight="bold"
              bgColor="transparent"
              h={{ lg: "50px", base: '45px' }}
              onClick={onClose}
              fontSize={{ lg: "16px", base: '14px' }}
            >
              <TextIntl text="cancel" />
            </Button>
            <Button
              flex={1}
              border="1px solid #FF8008"
              borderRadius="8px"
              color="#ffffff"
              fontSize={{ lg: "16px", base: '14px' }}
              fontWeight="bold"
              bgColor="#FF8008"
              h={{ lg: "50px", base: '45px' }}
              _hover={{
                backgroundColor: "#FF8008",
              }}
              onClick={() => {
                if (confirmInfo?.callback) {
                  confirmInfo.callback();
                }
                onClose();
              }}
            >
              <TextIntl text="Ok" />
            </Button>
          </Flex>
        </ModalBody>

      </ModalContent>
    </Modal>
  );
}

export default ConfirmDialog;
