import { Form } from "react-final-form";
import { Suspense } from "react";
import {
  Image,
  Button,
  Box,
  Flex,
} from "@chakra-ui/react";
import { LoginDto, useLogin } from "src/api/login";
import logoIcon from 'src/assets/images/logo.png';
import AuthStore from "src/stores/AuthStore";
import LabeledTextField from "../LabeledTextField";
import { validateEmail } from "../../utils/validators";
import TextIntl from "../TextIntl";

interface LoginError {
  email?: string,
  password?: string
}

const validate = (values: LoginDto) => {
  const errors: LoginError = {};

  if (!values.email || !values.email.trim()) {
    errors.email = 'Please enter your email';
  } if (!validateEmail(values.email)) {
    errors.email = 'Please enter valid email';
  }

  if (!values.password || !values.password.trim()) {
    errors.password = 'Please enter your password';
  }

  return errors;
};

function LoginForm() {
  const loginMutation = useLogin();

  const handleLogin = async (values: LoginDto) => {
    await loginMutation.mutateAsync({ ...values });

    AuthStore.handleToggleLoginModal();
  };

  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={handleLogin}
      validate={validate}
      render={({ handleSubmit }) => (
        <form id="login-form" onSubmit={handleSubmit}>
          <Image src={logoIcon} w="75px" mb="24px" mx="auto" mt="36px" />

          <TextIntl
            fontSize="24px"
            fontWeight="800"
            textTransform="uppercase"
            color="#FFFFFF"
            mb="36px"
            textAlign="center"
            text="Welcome to XTRIP"
          />

          <Box mb="36px">
            <LabeledTextField
              placeholder="Email address"
              name="email"
            />
          </Box>
          <Box mb="36px">
            <LabeledTextField
              placeholder="Password"
              name="password"
              type="password"
            />

            <Flex
              fontSize="14px"
              lineHeight="21px"
              color="rgba(224, 224, 224, 0.5))"
              textAlign="center"
              justifyContent="center"
              mb="36px"
              mt="16px"
            >
              <TextIntl text="dontRememberPassword" />

              {' '}
              <TextIntl
                mb="0"
                color="#FF8008"
                fontWeight="bold"
                ml="8px"
                cursor="pointer"
                onClick={() => {
                  AuthStore.handleToggleLoginModal();
                  setTimeout(() => {
                    AuthStore.handleToggleForgotPasswordModal();
                  }, 300);
                }}
                text="forgotPassword"
                _hover={{
                  textDecoration: 'underline',
                }}
              />
            </Flex>

          </Box>
          <Flex justifyContent="center">
            <Button
              bgColor="#FF8008"
              borderRadius="8px"
              h="60px"
              w="210px"
              mb="18px"
              mx="auto"
              _hover={{
                backgroundColor: '#FF8008',
              }}
              type="submit"
              isLoading={loginMutation.isLoading}
              fontSize="18px"
              fontWeight="600"
              textTransform="uppercase"
            >
              <TextIntl text="login" />
            </Button>
          </Flex>

          <Flex
            fontSize="18px"
            lineHeight="21px"
            color="rgba(224, 224, 224, 0.5)"
            textAlign="center"
            justifyContent="center"
            mb="46px"
          >
            <TextIntl text="dontHaveAccount" />

            {' '}
            <TextIntl
              mb="0"
              color="#FF8008"
              fontWeight="bold"
              ml="8px"
              cursor="pointer"
              onClick={() => {
                AuthStore.handleToggleLoginModal();
                setTimeout(() => {
                  AuthStore.handleToggleRegisterModal();
                }, 300);
              }}
              text="sign up"
              _hover={{
                textDecoration: 'underline',
              }}
            />
          </Flex>

        </form>
      )}
    />
  );
}

function LoginFormWrapper() {
  return (
    <Suspense fallback="loading...">
      <LoginForm />
    </Suspense>
  );
}

export default LoginFormWrapper;
