import React, { Suspense } from "react";
import { useItemOrders } from "src/api/item";
import useSearchParams from "../../utils/useSearchParams";
import FilterByPage from "../../components/FilterByPage";

function SneakerPaging() {
  const { params } = useSearchParams();
  const { data } = useItemOrders({ ...params, limit: 12 });
  return (
    <FilterByPage totalPages={data?.meta?.totalPages} />
  );
}

function SneakerPagingWrapper() {
  return (
    <Suspense fallback="">
      <SneakerPaging />
    </Suspense>
  );
}

export default SneakerPagingWrapper;
