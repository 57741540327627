import {
  Box, Button, Flex, Grid, GridItem, HStack, Image, Text,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CanBuyDto } from 'src/api/item';
import bgBox from 'src/assets/images/box-card.png';
import boxIcon from 'src/assets/images/box.png';
import TextIntl from 'src/components/TextIntl';
import AuthStore from 'src/stores/AuthStore';
import { isLoggedIn } from 'src/utils/utils';
import xtrToken from 'src/assets/images/xtr.svg';
import nftBg from 'src/assets/images/nft-bg.png';
import box from 'src/assets/images/box.webp';
import { getCurrencySVG } from 'src/utils/coincurrency';

function BoxItem({ boxesInfo }: { boxesInfo?: CanBuyDto }) {
  const navigate = useNavigate();
  const currencySrc = getCurrencySVG(boxesInfo?.currency || '');
  const handleBuy = () => {
    if (isLoggedIn()) {
      navigate('/box-payment');
    } else {
      AuthStore.handleToggleLoginModal(() => {
        navigate('/box-payment');
      });
    }
  };

  return (
    <Box
      // key={item.id}
      position="relative"
      w="100%"
      rounded="8px"
      bgColor="rgba(0,0,0,0)"
      pt="127.27%"
      bgImage="linear-gradient(rgba(19, 52, 63, 1),
    rgba(7, 52, 66, 1),
    rgba(17, 51, 58, 1));"
      _hover={{
        outline: "2px solid rgba(255, 142, 38, 1);",
      }}
    >
      {/* <Image src={bgCard} w="100%" /> */}
      <Box
        rounded="26px"
        justifyContent="center"
        // h={{ base: '394px', xl: '450px' }}
        // onClick={() => userInfo?.id !== item?.seller && handleDetail(item.id)}
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        display="flex"
        justifyItems="flex-start"
        flexDirection="column"

      >
        <Box
          position="relative"
          pt="100%"
          rounded="2"

        >
          <Box
            borderTopLeftRadius="8px"
            borderTopRightRadius="8px"
            height="100%"
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            p="16px"
            background="linear-gradient(180deg, #132A3F 2.56%, #073442 45.31%, #11333A 100%);"
          >
            <Grid
              h="200px"
              height="100%"
              // margin={4}
              borderRadius="8px"
              borderWidth="1px"
              p="6px"
              // bgImage={nftBg}
              // bgImage={`url(${boxIcon})`}
              bgImage={`url(${box}),url(${nftBg})`}
              // bgImage={item.image || shoe}
              bgPosition="center"
              bgRepeat="no-repeat"
              bgSize="60%,81.81%"
              borderColor="linear-gradient(rgba(255, 255, 255, 1),
            rgba(255, 255, 255, 0));"
              templateRows="repeat(5, 1fr)"
              templateColumns="repeat(4, 1fr)"
            >
              {/* <GridItem colSpan={1} justifyContent="flex-start" alignItems="flex-start">
              <Text fontWeight={800} fontSize={14}>{item?.efficiency}</Text>
              <Image src={iconECard} w={{ base: '25%', xl: '50%' }} />
            </GridItem>
            <GridItem colStart={4} bg="tomato" />

            <GridItem justifyContent="flex-start" alignItems="flex-end"
            colSpan={1} rowStart={4} display="flex">
              <Text fontWeight={800} fontSize={14}>{item?.recovery}</Text>
              <Image src={iconRCard} w={{ base: '25%', xl: '20%' }} />
            </GridItem>
            <GridItem colStart={4} rowStart={4} bg="tomato" /> */}
              {/* <GridItem
              colSpan={2}
              rowSpan={2}
              colStart={2}
              rowStart={2}
              bgImage={item.image || shoe}
              bgPosition="center"
              bgRepeat="no-repeat"
              bgSize="contain"

            /> */}
            </Grid>
          </Box>

        </Box>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          bg="#12475C"
          flex={1}
          borderBottomLeftRadius="8px"
          borderBottomRightRadius="8px"
          px="18px"
        >

          {/* <HStack justifyContent="space-between">

          <HStack justifyContent="center">
            <Image src={iconLCard} w={{ base: '25%', xl: '20%' }} />

            <Text color="#31B07B">{item?.luck}</Text>
          </HStack>
          <HStack justifyContent="center">
            <Image src={iconDCard} w={{ base: '25%', xl: '20%' }} />
            <Text color="#31B07B">{item?.distance}</Text>
          </HStack>
        </HStack>
        <Divider size="xl" mt={0} color="#ACACAC" /> */}
          <HStack justifyContent="center" alignItems="center" fontWeight={{ base: "800" }}>
            <Text fontSize={{ base: 14, xl: 18 }}>
              {boxesInfo?.price}
              {' '}
            </Text>

            <Image src={currencySrc} w={{ base: '14px', md: '18px', xl: '22px' }} />
          </HStack>

          <Button
            color="#FFFFFF"
            backgroundColor="#FF8008"
            borderRadius="8px"
            onClick={handleBuy}
            py={{ base: '6px', md: '8px', xl: '10px' }}
            px={{ base: '14px', md: '22', xl: '32px' }}
            height="auto"
            fontSize={{ base: 14, xl: 18 }}
          >
            Buy
          </Button>

        </Flex>
      </Box>
    </Box>
    // <Box
    //   w="100%"
    //   position="relative"
    //   width="100%"
    // >
    //   <Image src={bgBox} w="100%" />
    //   <Box
    //     position="absolute"
    //     top={0}
    //     right={0}
    //     left={0}
    //     bottom={0}
    //   >
    //     <Image src={boxIcon} maxW="100%" w="80%" mx="auto" mt="10%" />
    //     <Flex
    //       alignItems="center"
    //       justifyContent="space-between"
    //       px="10%"
    //       position="absolute"
    //       left={0}
    //       right={0}
    //       bottom={{ base: '10%', '2xl': "8%" }}
    //       zIndex={9}
    //     >
    //       <Text fontSize="18px" fontWeight="bold" mr="10px">
    //         {boxesInfo?.price}
    //         {' '}
    //         {boxesInfo?.currency}
    //       </Text>
    //       <Button
    //         bgColor="#FF8008"
    //         borderRadius="8px"
    //         h="46px"
    //         w="100px"
    //         _hover={{
    //           backgroundColor: '#FF8008',
    //         }}
    //         fontWeight="bold"
    //         onClick={handleBuy}
    //         fontSize="16px"
    //       >
    //         <TextIntl fontSize="16px" text="buy" />
    //       </Button>
    //     </Flex>
    //   </Box>

  // </Box>

  );
}

export default BoxItem;
