import { Form } from "react-final-form";
import { Suspense } from "react";
import {
  Image,
  Button,
  Box,
  Flex,
} from "@chakra-ui/react";
import {
  ActiveAccountDto, useActiveAccount,
} from "src/api/register";
import logoIcon from 'src/assets/images/logo.png';
import AuthStore from "src/stores/AuthStore";
import LabeledTextField from "../LabeledTextField";
import { validateEmail } from "../../utils/validators";
import TextIntl from "../TextIntl";

interface ActiveAccountError {
  email?: string,
  activationCode?: string
}

const validate = (values: ActiveAccountDto) => {
  const errors: ActiveAccountError = {};

  if (!values.email || !values.email.trim()) {
    errors.email = 'Please enter your email';
  } if (!validateEmail(values.email)) {
    errors.email = 'Please enter valid email';
  }

  if (!values.activationCode || !values.activationCode.trim()) {
    errors.activationCode = 'Please enter activation code';
  }

  return errors;
};

function ActiveAccountForm() {
  const email = AuthStore.currentEmail;

  const activeAccountMutation = useActiveAccount();

  const handleActiveAccount = async (values: ActiveAccountDto) => {
    await activeAccountMutation.mutateAsync(values);
    AuthStore.handleToggleActiveAccountModal();

    setTimeout(() => {
      AuthStore.handleToggleLoginModal();
    }, 300);
  };

  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={handleActiveAccount}
      validate={validate}
      initialValues={{
        email,
      }}
      render={({ handleSubmit }) => (
        <form id="active-account-form" onSubmit={handleSubmit}>
          <Image src={logoIcon} w="75px" mb="24px" mx="auto" mt="36px" />

          <TextIntl
            fontSize="24px"
            fontWeight="bold"
            textTransform="uppercase"
            color="#090404"
            mb="36px"
            textAlign="center"
            text="activeAccount"
          />

          <Box mb="36px">
            <LabeledTextField
              placeholder="Email address"
              name="email"
            />
          </Box>
          <Box mb="36px">
            <LabeledTextField
              placeholder="Activation code"
              name="activationCode"
            />
          </Box>
          <Flex justifyContent="center">
            <Button
              bgColor="#FF8008"
              borderRadius="16px"
              h="60px"
              w="210px"
              mb="18px"
              mx="auto"
              _hover={{
                backgroundColor: '#FF8008',
              }}
              type="submit"
              isLoading={activeAccountMutation.isLoading}
              fontSize="18px"
              fontWeight="600"
            >
              <TextIntl text="activeAccount" />
            </Button>
          </Flex>

        </form>
      )}
    />
  );
}

function ActiveAccountWrapper() {
  return (
    <Suspense fallback="loading...">
      <ActiveAccountForm />
    </Suspense>
  );
}

export default ActiveAccountWrapper;
