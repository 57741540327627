import {
  Tr, Td, Text, Image, Flex,
  Tooltip,
  Icon,
  IconButton,
  Link,
} from "@chakra-ui/react";
import { OrderDocument, useCancelOrder } from "src/api/profile";
import { ICON_BY_TOKENS } from "src/constants/token";
import { ORDER_STATE_OBJ, ORDER_TYPE, ORDER_TYPE_OBJ } from "src/constants/transaction";
import dayjs from 'dayjs';
import { formatStringToNumber } from "src/utils/utils";
import { MdCancel } from 'react-icons/md';
import GlobalStore from "src/stores/GlobalStore";
import TextIntl from "src/components/TextIntl";

const statusColors: any = {
  success: 'green.500',
  failed: 'red.500',
  pending: 'yellow.300',
  canced: '#999999',
  processing: 'yellow.500',
};

function OrderItem({ order } :{ order: OrderDocument}) {
  const cancelOrderMutation = useCancelOrder();
  return (
    <Tr py="14px" verticalAlign="center" key={order.id}>
      <Td borderBottom={0}>
        <Text
          fontSize="20px"
          fontWeight="bold"
          color="#fff"
          letterSpacing="0.01em"
        >
          {ORDER_TYPE_OBJ[order.order_type]}
        </Text>
      </Td>
      <Td borderBottom={0} px="20px">
        <Text
          fontSize="16px"
          fontWeight="400"
          color="rgba(255, 255, 255, 0.5)"
          letterSpacing="0.01em"
          minW="220px"
        >
          {dayjs(order.created_at).format('DD MMM YYYY hh:mm A')}
        </Text>
      </Td>
      <Td borderBottom={0}>
        <Flex alignItems="center">
          <Image src={ICON_BY_TOKENS[order.token_symbol]} w="24px" mr="11px" />
          <Text
            fontSize="20px"
            fontWeight="bold"
            color={order?.order_type === ORDER_TYPE.withdraw ? "red.300" : 'green.300'}
            letterSpacing="0.01em"
          >
            {order?.order_type === ORDER_TYPE.withdraw && '-'}
            { formatStringToNumber(order.amount) }
          </Text>
        </Flex>
      </Td>
      <Td borderBottom={0}>
        <TextIntl
          fontSize="20px"
          fontWeight="400"
          color={statusColors[order.state] || '#999999'}
          letterSpacing="0.01em"
          text={ORDER_STATE_OBJ[order.state]}
        />

        { order.transaction_hash
          && (
            <Link
              isExternal
              display="block"
              color="#FF8008"
              mt="10px"
              textDecoration="underline"
              href={`${process.env.REACT_APP_AVALANCHE_SCAN}/tx/${order.transaction_hash}`}
              target="_blank"
            >
              <TextIntl
                fontSize="16px"
                fontWeight="400"
                letterSpacing="0.01em"
                text="viewTransaction"
              />
            </Link>

          )}
      </Td>

      <Td borderBottom={0}>
        { order.state === 'pending' && order.order_type === ORDER_TYPE.withdraw
                  && (
                  <Tooltip label="Cancel order">
                    <IconButton
                      icon={<Icon boxSize={6} as={MdCancel} />}
                      variant="ghost"
                      colorScheme="red"
                      aria-label="Cancel"
                      isLoading={cancelOrderMutation.isLoading}
                      disabled={cancelOrderMutation.isLoading}
                      onClick={() => {
                        GlobalStore.handleShowConfirmDialog({
                          title: 'Confirm',
                          description: 'Do you want cancel withdraw?',
                          callback: () => {
                            cancelOrderMutation.mutate(order.id);
                          },
                        });
                      }}
                    />
                  </Tooltip>
                  )}

      </Td>
    </Tr>
  );
}

export default OrderItem;
