import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/modal";

import LoginForm from "./LoginForm";

interface LoginProps {
  isOpen: boolean;
  onClose: () => void;
}

function LoginModal({
  isOpen, onClose,
}: LoginProps) {
  return (
    <Modal
      closeOnOverlayClick
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        w="600px"
        maxW="95%"
        className="modal-content"
        bg="linear-gradient(rgba(20, 61, 84, 1),
rgba(95, 166, 188, 0.96))"
        borderRadius="42px"
        p="2px"
      >
        <ModalBody px={{ sm: "50px", md: "115px" }} bg="rgba(3, 20, 31, 1)" borderRadius="42px">
          <LoginForm />
        </ModalBody>

      </ModalContent>
    </Modal>
  );
}

export default LoginModal;
