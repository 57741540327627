import React, { Suspense, useState } from "react";
import {
  Text,
  SimpleGrid,
  Box,
  Button,
  Flex,
  Image,
  // useDisclosure,
  Link,
  HStack,
  Badge,
  Switch,
} from '@chakra-ui/react';
import {
  Link as RouterLink, useNavigate, useParams,
} from 'react-router-dom';
import { Form } from "react-final-form";
import useSearchParams from "src/utils/useSearchParams";
import LabeledNumberField from "src/components/LabeledNumberField";
import { formatStringToNumber, getLifeTimeText } from "src/utils/utils";
import GlobalStore from "src/stores/GlobalStore";
import SuccessModal from "src/components/SuccessModal";
import TextIntl from "src/components/TextIntl";
import SendOutModal from "src/components/Item/SendOut/SendOutModal";
import NftStore from "src/stores/NftStore";
import ItemAttribute from "src/components/Item/ItemDetail/ItemAttribute";
import shoe from '../../assets/images/shoe.png';
import bgShoe from '../../assets/images/bgShoe.png';
import {
  SellItemDto, useBuyItem, useItem, useOrderDetail, useSellItem, useSendOutItem,
} from "../../api/item";
// import { ModalBuyItem } from "./sneakerModalBuyItem";

interface IError {
  amount?: string
}

const validate = (values: SellItemDto) => {
  const errors: IError = {};
  if (!values.amount) {
    errors.amount = 'Please enter amount';
  } else if (+values.amount < 100) {
    errors.amount = 'Please enter amount more than or equal 100';
  }

  return errors;
};

function SneakerDetail() {
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const { id } = useParams();
  const { params } = useSearchParams();
  const { data } = params.sell === 'true' ? useItem(id || '') : useOrderDetail(id || '');

  const buyItemMutation = useBuyItem();
  const sellItemMutation = useSellItem();

  const navigate = useNavigate();
  const sendOutItemMutation = useSendOutItem();

  const [successInfo, setSuccessInfo] = useState<any>();
  const [showBaseAttribute, setShowBaseAttribute] = useState(false);
  const handleSendOutClick = () => {
    NftStore.handleShowSendOutModal(data, () => {});
  };

  const handleBuy = () => {
    GlobalStore.handleShowConfirmDialog({
      title: 'Confirm',
      description: 'Do you want buy this item?',
      callback: async () => {
        if (id) {
          await buyItemMutation.mutateAsync(id);
          setSuccessInfo({
            title: 'Buy item success',
            description: 'You did buy item successful!',
            btnText: 'myItems',
            onClick: () => {
              navigate('/profile/my-items');
            },
          });
        }
      },
    });
  };

  const handleSell = (values: SellItemDto) => {
    // onOpen();
    GlobalStore.handleShowConfirmDialog({
      title: 'Confirm',
      description: 'Do you want sell this item?',
      callback: async () => {
        if (id && values.amount) {
          await sellItemMutation.mutateAsync({
            userItemId: +id as number,
            amount: values.amount,
            token: 'XTR',
          });

          setSuccessInfo({
            title: 'Sell item success',
            description: 'You did sell item successful!',
            btnText: 'Marketplace',
            onClick: () => {
              navigate('/');
            },
          });
        }
      },
    });
  };

  return (
    <>
      <SendOutModal />
      <Form
        keepDirtyOnReinitialize
        onSubmit={handleSell}
        validate={validate}
        render={({ handleSubmit }) => (
          <form id="login-form" onSubmit={handleSubmit}>
            <Box m={{ base: '50px 0 65px 0', xl: '80px 64px 139px 64px' }}>
              <SimpleGrid
                gridAutoRows="1fr"
                columns={{ base: 1, md: 2, xl: 2 }}
                spacing="67px"
                p={{ base: '20px' }}
                borderRadius={{ lg: '26px', base: 0 }}
                w="100%"
              >
                <Box
                  bgImage={bgShoe}
                  bgPosition="bottom"
                  bgRepeat="no-repeat"
                  bgSize={{ base: 'contain' }}
                  alignItems="center"
                  width="100%"
                  position="relative"
                >
                  <Image
                    w="70%"
                    position="absolute"
                    src={data?.image || shoe}
                    left="50%"
                    top="50%"
                    transform="translate(-50%, -50%)"
                  />

                </Box>
                <Box
                  rounded="26px"
                  p="30px 40px 45px 40px"
                  // borderRadius="38px"
                  bgGradient="linear-gradient(358.62deg, rgba(197, 210, 235, 0.24) 2.84%, rgba(138, 169, 227, 0.0192) 96.69%)"
                  border="3px solid #EDCCA6"
                >
                  <HStack pb={{ base: '29px', xl: '36px' }}>
                    <Text as="h3" fontSize={{ base: '30px', xl: '40px' }} fontWeight="700">
                      {data?.name}
                    </Text>
                    <Badge colorScheme="green" p="10px" borderRadius="12px">
                      LV
                      {' '}
                      {data?.level}
                    </Badge>
                  </HStack>
                  <Flex pb={{ base: '16px', xl: '20px' }}>
                    <TextIntl
                      color="rgba(255, 255, 255, 0.6)"
                      fontSize={{ base: '20px', xl: '20px' }}
                      fontWeight="400"
                      flex={{ base: '45%', xl: '40%' }}
                      text="Lifetime"
                    />
                    <Text>{getLifeTimeText(data?.life_time ?? 0, data?.lifetime_count ?? 0)}</Text>
                  </Flex>
                  <Flex pb={{ base: '16px', xl: '20px' }}>
                    <TextIntl
                      color="rgba(255, 255, 255, 0.6)"
                      fontSize={{ base: '20px', xl: '20px' }}
                      fontWeight="400"
                      flex={{ base: '45%', xl: '40%' }}
                      text="Lifetime Recovery used"
                    />
                    <Text>{`${data?.lifetime_recover_by_token_nums}/3`}</Text>
                  </Flex>
                  <Flex pb={{ base: '16px', xl: '20px' }}>
                    <TextIntl
                      color="rgba(255, 255, 255, 0.6)"
                      fontSize={{ base: '20px', xl: '20px' }}
                      fontWeight="400"
                      flex={{ base: '45%', xl: '40%' }}
                      text="Base"
                    />
                    <Switch pr="20px" onChange={() => { setShowBaseAttribute(!showBaseAttribute); }} isChecked={showBaseAttribute} />
                  </Flex>
                  <ItemAttribute
                    text="Mints"
                    value={`${data?.num_of_mints}/5`}
                    percentageVal={(data?.num_of_mints ?? 0 / 5) * 100}
                    accentColor={showBaseAttribute}
                  />
                  <ItemAttribute
                    text="Efficiency"
                    value={showBaseAttribute ? data?.base_efficiency : data?.efficiency}
                    percentageVal={showBaseAttribute ? data?.base_efficiency : data?.efficiency}
                    accentColor={showBaseAttribute}
                  />
                  <ItemAttribute
                    text="Recovery"
                    value={showBaseAttribute ? data?.base_recovery : data?.recovery}
                    percentageVal={showBaseAttribute ? data?.base_recovery : data?.recovery}
                    accentColor={showBaseAttribute}
                  />
                  <ItemAttribute
                    text="Luck"
                    value={showBaseAttribute ? data?.base_luck : data?.luck}
                    percentageVal={showBaseAttribute ? data?.base_luck : data?.luck}
                    accentColor={showBaseAttribute}
                  />
                  <ItemAttribute
                    text="Distance"
                    value={showBaseAttribute ? data?.base_distance : data?.distance}
                    percentageVal={showBaseAttribute ? data?.base_distance : data?.distance}
                    accentColor={showBaseAttribute}
                  />

                  { params.sell !== 'true' && (
                    <HStack alignItems="baseline">
                      <Text fontSize={{ base: '30px', xl: '40px' }} fontWeight="700" color="#FFFFFF" pb={{ base: '19px', xl: '42px' }} pl={{ base: '50px', md: '0' }}>
                        { formatStringToNumber(data?.amount || data?.price)}
                        {' '}
                        {data?.token || 'XTR'}
                      </Text>
                      <Text fontSize="16px">(include system fee)</Text>
                    </HStack>
                  )}

                  { params.sell === 'true' && (
                    <Box mb="20px">
                      <LabeledNumberField
                        placeholder="Sell price (XTR)"
                        name="amount"
                        className="h60"
                      />
                    </Box>
                  )}

                  <Box>

                    <Button
                      size="sm"
                      color="#FFFFFF"
                      backgroundColor="#FF8008"
                      width="100%"
                      borderRadius={16}
                      height={{ base: 42, xl: 63 }}
                      fontSize={{ base: 20, xl: 32 }}
                      fontWeight="700"
                      onClick={() => (params.sell !== 'true' && handleBuy())}
                      isLoading={buyItemMutation.isLoading || sellItemMutation.isLoading}
                      type={(params.sell === 'true' ? 'submit' : 'button')}
                    >
                      <TextIntl text={params.sell === 'true' ? 'sellNow' : 'buyNow'} />
                    </Button>
                  </Box>

                  { params.sell === 'true'
                  && (
                  <Box mt="20px">
                    <Button
                      size="sm"
                      color="#FFF"
                      backgroundColor="#FF8008"
                      width="100%"
                      borderRadius={16}
                      height={{ base: 42, xl: 63 }}
                      fontSize={{ base: 20, xl: 32 }}
                      fontWeight="700"
                      onClick={handleSendOutClick}
                      isLoading={sendOutItemMutation.isLoading}
                      type={(params.sell === 'true' ? 'submit' : 'button')}
                    >
                      <TextIntl text="sendToYourWallet" />
                    </Button>
                  </Box>
                  )}
                </Box>
              </SimpleGrid>

              <SuccessModal
                isOpen={!!successInfo}
                onClose={() => {
                  setSuccessInfo(null);
                }}
                title={successInfo?.title}
                description={successInfo?.description}
                btnText={successInfo?.btnText}
                onClick={successInfo?.onClick}
              />

            </Box>
          </form>
        )}
      />
    </>
  );
}

function SneakerTableWrapper() {
  return (
    <Suspense fallback="loading...">
      <SneakerDetail />
    </Suspense>
  );
}

export default SneakerTableWrapper;
