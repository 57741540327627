import React, { Suspense } from "react";
import {
  Box, Image, useBreakpointValue,
} from '@chakra-ui/react';
// import FilterByPage from "src/components/FilterByPage";
import { useRemainBoxes } from "src/api/item";
import TextIntl from "src/components/TextIntl";
import FilterByPage from "src/components/FilterByPage";
import banner from 'src/assets/images/shop/shop-bn.webp';
import bannerMb from 'src/assets/images/shop/shop-bn-mb.webp';
import Boxes from "./components/Boxes";

function Shop() {
  const { data } = useRemainBoxes();
  const imageSrc = useBreakpointValue({
    base: bannerMb,
    xl: banner,
  });
  return (
    <Box>
      <Image src={imageSrc} mx="auto" w="100%" />
      <Boxes boxesInfo={data} />
      {/* <FilterByPage totalPages={Math.ceil((data?.boxAvailable || 0) / 20)} /> */}

    </Box>
  );
}

function ShopWrapper() {
  return (
    <Suspense fallback="loading...">
      <Shop />
    </Suspense>
  );
}

export default ShopWrapper;
