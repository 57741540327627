import axios from "axios";
import AuthStore from "src/stores/AuthStore";
import { ACCESS_TOKEN, DEVICE } from '../constants/localStorage';

axios.defaults.baseURL = process.env.REACT_APP_ENDPOINT_URL || 'http://localhost:8081';

const ignoreUrls = [
  '/',
  '/marketplace',
  '/shop',
];
const instance = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT_URL,
});

instance.interceptors.request.use(
  (config) => {
    const newConfig:any = config;
    // Do something before request is sent
    if (config.headers === undefined) {
      newConfig.headers = {};
    }
    newConfig.headers.Authorization = `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`;
    newConfig.headers.deviceId = localStorage.getItem(DEVICE);
    return newConfig;
  },
  (error) => Promise.reject(error)
  ,
);

instance.interceptors.response.use((response) => response, (error) => {
  if (error?.response?.status === 401) {
    // localStorage.removeItem(ACCESS_TOKEN);
    // localStorage.removeItem(USER_INFO);
    // window.location.href = '/';
    let isIgnore = false;
    for (let index = 0; index < ignoreUrls.length; index += 1) {
      if (window.location.pathname === ignoreUrls[index]) {
        isIgnore = true;
      }
    }

    if (!isIgnore) {
      AuthStore.handleShowLoginModal(() => {
        window.location.reload();
      });
    }
  }
  return Promise.reject(error);
});

export default instance;
