import React from "react";
import {
  Box, SimpleGrid, Center, VStack, Image, Text, Stack, HStack, Link, Icon, Heading, Divider,
} from '@chakra-ui/react';
import { BsTwitter, BsDiscord, BsFacebook } from 'react-icons/bs';
import logoXtrip from '../assets/images/logoXtrip.png';
import bgrFt from '../assets/images/bgr_ft.webp';
import TextIntl from "./TextIntl";

function Footer() {
  return (
    <Box bgImage={bgrFt} bgSize="cover" rounded="24px" mx={{ base: 2, lg: 4 }} px={{ base: 4, lg: 8 }} pt={16} pb={12} color="white">
      <SimpleGrid columns={[1, 1, 1, 3]} width="100%" spacing="10">
        <Center alignItems="flex-start">
          <VStack alignItems={{ base: 'center', lg: "flex-start" }}>
            <Image
              width="147px"
              src={logoXtrip}
              alt="logo"
              mb="16px"
            />
            <Text
              fontSize="16px"
              fontWeight="400"
              color="#0FE0FD"
              letterSpacing="-0.02em"
              maxW="341px"
              textAlign={{ base: 'center', lg: "left" }}
            >
              9 Temasek Boulevard, Suntec Tower 2
              #07-01, Singapore 038989
            </Text>
          </VStack>
        </Center>
        <Stack>
          <TextIntl
            fontSize={{ base: '20px', lg: "24px" }}
            fontWeight="700"
            lineHeight="35px"
            letterSpacing="-0.02em"
            maxW={{ lg: "420px" }}
            textAlign={{ base: 'center', lg: "left" }}
            text="footer.description"
          />
          <HStack justify={{ base: 'center', lg: 'flex-start' }} pt={4}>
            <Link href="https://twitter.com/xtrip_travel" isExternal>
              <Icon as={BsTwitter} w="30px" color="#fff" mr="20px" />
            </Link>
            <Link href="https://twitter.com/xtrip_travel" isExternal>
              <Icon as={BsDiscord} w="30px" color="#fff" mr="20px" />
            </Link>
            <Link href="https://twitter.com/xtrip_travel" isExternal>
              <Icon as={BsFacebook} w="30px" color="#fff" mr="20px" />
            </Link>
          </HStack>
        </Stack>
        <Box>
          <SimpleGrid columns={[2]}>
            <VStack alignItems="flex-start" pl="10px">
              <TextIntl text="Page" fontWeight="900" fontSize="16px" mb="10px" />
              <Link fontSize="16px" fontWeight="400" mb="8px">
                <TextIntl text="privacyPolicy" />
              </Link>
              <Link fontSize="16px" fontWeight="400" mb="8px">
                <TextIntl text="termOfService" />
              </Link>
              <Link fontSize="16px" fontWeight="400" mb="8px">
                <TextIntl text="howToPlay" />
              </Link>
            </VStack>
            <VStack alignItems="flex-start">
              <Heading as="h3" fontWeight="900" fontSize="16px" mb="10px"> Links</Heading>
              <Link href="https://whitepaper.xtrip.travel/" isExternal fontSize="16px" mb="8px">
                <TextIntl text="Whitepaper" />
              </Link>
              <Link fontSize="16px" fontWeight="400" mb="8px">
                <TextIntl text="Litepaper" />
              </Link>
            </VStack>
          </SimpleGrid>
        </Box>
      </SimpleGrid>
      <Divider size="xl" mt={16} color="#ACACAC" />
      <TextIntl text="copyright" fontSize="14px" fontWeight="400" color="#B0B0B0" textAlign="center" mt={8} />
    </Box>
  );
}

export default Footer;
