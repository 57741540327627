import { Text } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';

function TextIntl({ text, ...rest }: any) {
  const { t } = useTranslation();
  return (
    <Text {...rest}>
      {t(text)}
    </Text>
  );
}

export default TextIntl;
