// import Pagination from "./Pagination";
import { HStack } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import Pagination from "./Panigation";

interface FilterByPageProps {
  totalPages: number | undefined,
}

function FilterByPage({ totalPages = 2 }: FilterByPageProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;

  return (
    <HStack spacing={4} justifyContent="center" mt={10} mb={10}>
      <Pagination
        onPageChange={(e) => setSearchParams({ page: `${e.selected + 1}` })}
        pageCount={totalPages}
        forcePage={+page - 1}
      />
    </HStack>
  );
}

export default FilterByPage;
