import { Box } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { injected } from 'src/utils/connectors';
import classes from './WalletItem.module.scss';

interface WalletItemProps {
  item: any,
  handleConnect: any
}

function WalletItem({ item, handleConnect }: WalletItemProps) {
  const [hasInjected, setHasInjected] = useState(false);

  useEffect(() => {
    setHasInjected((window as any).web3 || (window as any).ethereum);
  }, []);

  return (
    <Box
      className={classes.container}
      onClick={() => {
        if (
          (item.connector === injected && !hasInjected)
        ) {
          window.open(item.installLink);
        } else {
          handleConnect(item);
        }
      }}
    >
      <img src={item.iconName} className={classes.icon} alt="icon" />
      <p className={classes.name}>
        {(item.connector === injected && !hasInjected)
          ? item.installName
          : item.name}
      </p>
    </Box>
  );
}

export default WalletItem;
