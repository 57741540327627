import {
  Flex,
  Image,
  Box,
  Stack,
  Link,
  Button,
  Center,
  VStack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from '@chakra-ui/react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import {
  HamburgerIcon,
} from "@chakra-ui/icons";
import { isLoggedIn } from "src/utils/utils";
import AuthStore from "src/stores/AuthStore";
import LangStore from "src/stores/LangStore";
import logoXtrip from '../assets/images/logoXtrip.png';
import TextIntl from "./TextIntl";
import Language from "./Language";
import Avatar from "./Avatar";

function Header() {
  const {
    isOpen: isOpenModalHeader,
    onOpen: onOpenModalHeader,
    onClose: onCloseModalHeader,
  } = useDisclosure();

  const navigate = useNavigate();

  const handleClickShop = () => {
    navigate('/shop');

    // if (isLoggedIn()) {
    //   navigate('/shop');
    // } else {
    //   AuthStore.handleToggleLoginModal(() => {
    //     navigate('/shop');
    //   });
    // }
    onCloseModalHeader();
  };

  const { pathname } = useLocation();
  const isActiveRoute = (route: string) => pathname.startsWith(route);
  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      px={{ base: 4, lg: 6, xl: 16 }}
      py={{ base: 4, xl: 7 }}
      bg="#04141f"
      color="white"
      width="100%"
      position="fixed"
      top={0}
      left={0}
      right={0}
      zIndex={99}
      borderBottom="1px solid #2A567E"
    >
      <Link
        as={RouterLink}
        to="/marketplace"
        pr={8}
      >
        <Flex align="center" mr={5}>
          <Image
            w={{ base: '109px', lg: "147px" }}
            src={logoXtrip}
            alt="logo"
          />
        </Flex>
      </Link>

      <Modal onClose={onCloseModalHeader} size="xl" isOpen={isOpenModalHeader}>
        <ModalOverlay />
        <ModalContent bg="#040B10">
          <ModalCloseButton color="white" />
          <Center flexGrow={1}>
            <VStack fontSize="20px" fontWeight="700" color="white" pt="60px">

              <Link
                as={RouterLink}
                to="/marketplace"
                pb={4}
                onClick={onCloseModalHeader}
                color={(pathname === '/' || pathname === "/marketplace"
                  || isActiveRoute("/itemDetail")) ? "#FF8008" : "#fff"}
              >
                <TextIntl text="Marketplace" />

              </Link>

              <Link pb={4} href={`https://www.xtrip.travel/${LangStore.lang}/litepaper`} isExternal onClick={onCloseModalHeader}><TextIntl text="Litepaper" /></Link>
              <Link href="https://whitepaper.xtrip.travel/" isExternal pb={4}><TextIntl text="Whitepaper" /></Link>
              <Box
                pb={4}
                onClick={handleClickShop}
                color={isActiveRoute("/shop") ? "#FF8008" : "#fff"}
              >
                <TextIntl text="Shop" />
              </Box>
              <Box pb={4}>
                <Language />
              </Box>
              {!isLoggedIn()
                && (
                <Box pb={4}>
                  <Button
                    pb={4}
                    _hover={{ boxShadow: "0px 3px 22px rgba(255, 128, 8, 0.71);", textDecoration: "none" }}
                    bgPosition="center"
                    bgRepeat="no-repeat"
                    bgSize="contain"
                    bg="#FF8008"
                    px={8}
                    py={4}
                    fontSize="20px"
                    fontWeight="bold"
                    border="none"
                    onClick={() => AuthStore.handleShowLoginModal()}
                  >
                    <TextIntl text="login" />
                  </Button>
                </Box>
                )}
            </VStack>
          </Center>
        </ModalContent>
      </Modal>

      <Stack
        direction={{ base: "column", lg: "row" }}
        display={{ base: "none", lg: "flex" }}
        width={{ base: "full", lg: "auto" }}
        alignItems="center"
        justify="flex-end"
        flexGrow={1}
        mt={{ base: 4, lg: 0 }}
        fontSize="15px"
        fontWeight="bold"
      >

        <Link
          as={RouterLink}
          to="/marketplace"
          pr={8}
          color={(pathname === "/marketplace" || isActiveRoute("/itemDetail")) ? "#FF8008" : "#fff"}
        >
          <TextIntl text="Marketplace" />
        </Link>

        <Link
          pr={8}
          href={`https://www.xtrip.travel/${LangStore.lang}/litepaper`}
          isExternal
        >
          <TextIntl text="Litepaper" />

        </Link>
        <Link href="https://whitepaper.xtrip.travel/" isExternal pr={8}><TextIntl text="Whitepaper" /></Link>
        <Box
          onClick={handleClickShop}
          pr={4}
          cursor="pointer"
          _hover={{
            textDecoration: 'underline',
          }}
          color={isActiveRoute("/shop") ? "#FF8008" : "#fff"}
        >
          <TextIntl text="Shop" />
        </Box>
      </Stack>
      <Box display={{ base: "none", lg: "block" }} pr={4}>
        <Language />
      </Box>
      {!isLoggedIn()
        && (
          <Button
            display={{ base: "none", lg: "flex" }}
            _hover={{ boxShadow: "0px 3px 22px rgba(255, 128, 8, 0.71);", textDecoration: "none" }}
            bgPosition="center"
            bgRepeat="no-repeat"
            bgSize="contain"
            bg="#FF8008"
            px={8}
            py={4}
            fontSize="20px"
            fontWeight="bold"
            border="none"
            onClick={() => AuthStore.handleShowLoginModal()}
          >
            <TextIntl text="login" />
          </Button>
        )}
      <Box
        position={{ base: 'absolute', lg: 'static' }}
        left="50%"
        top="50%"
        transform={{ base: "translate(-50%, -50%)", lg: 'none' }}
      >
        <Avatar />
      </Box>
      <Box display={{ base: "block", lg: "none" }} onClick={() => onOpenModalHeader()} _hover={{ cursor: "pointer" }}>
        <HamburgerIcon w={8} h={8} />
      </Box>
    </Flex>
  );
}

export default Header;
