import React, { Suspense, useEffect, useState } from "react";
import {
  Text,
  Box,
  Link,
  Flex,
  Image,
  Button,
  HStack,
  Spacer,
  Divider,
  VStack,
  RadioGroup,
  Radio,
  Stack,
  FormControl,
  FormLabel,
  Input,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Fade,
  ScaleFade,
} from '@chakra-ui/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// import shoeImage from 'src/assets/images/shoe-group.png';
import {
  useBuyBox, useRemainBoxes, useWallets, stripeCheckCanBuy, stripeBuyAfterPayment,
} from "src/api/item";
import avaxToken from 'src/assets/images/avax.svg';
import { useUserInfo } from "src/api/profile";
import { formatStringToNumber } from "src/utils/utils";
import xtrToken from 'src/assets/images/xtr.svg';
import gxtToken from 'src/assets/images/gxt.svg';
import {
  CardElement, useStripe, useElements,
} from '@stripe/react-stripe-js';
import { useRive } from '@rive-app/react-canvas';
import { ICON_BY_TOKENS } from "src/constants/token";
import AvatarImage from "src/components/AvatarImage";
import iconVS from '../../assets/images/payment-vs.webp';
import iconMC from '../../assets/images/payment-mc.webp';
import iconAE from '../../assets/images/payment-ae.webp';
import iconJCB from '../../assets/images/payment-jcb.webp';
import ConfirmBuyDialog from "./ConfirmBuyDialog";

// import Confetti from 'react-confetti';
// import bycircleImage from 'src/assets/images/bycircle-group.png';

const AVAX = 'avax';
const CARD = 'card';
const USD = 'usd';

function BoxDetail() {
  const toast = useToast({ position: "top" });
  const navigate = useNavigate();
  const [buyInfo, setBuyInfo] = useState<any>();
  const [showRevi, setShowRevi] = useState(true);
  const { data: userInfo } = useUserInfo();
  const { data: wallets } = useWallets();
  const buyBoxMutation = useBuyBox();
  const cards = [iconVS, iconMC, iconAE, iconJCB];
  const { data: boxesInfo } = useRemainBoxes();
  const stripe = useStripe();
  const elements = useElements();
  const [payMethod, setPayMethod] = useState(AVAX);
  const [unit, setUnit] = useState(AVAX);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenGenesis, onOpen: onOpenGenesis, onClose: onCloseGenesis } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [finalPrice, setPrice] = useState(0);
  // const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    if (boxesInfo) {
      setPrice(boxesInfo.price);
    }
  }, [boxesInfo]);
  useEffect(() => {
    if (boxesInfo) {
      const { currencyRate = 1, price = 0 } = boxesInfo;
      switch (payMethod) {
        case AVAX:
          setPrice(boxesInfo?.price || 0);
          setUnit(AVAX);
          break;
        case CARD:
        default:
          setPrice(Math.round(price * currencyRate * 100) / 100);
          setUnit(USD);
          break;
      }
    }
  }, [payMethod]);
  const handleSubmit = async () => {
    // event.preventDefault();
    setLoading(true);
    let isBuySuccess = false;
    if (payMethod === CARD && stripe && elements && boxesInfo) {
      try {
        // create payment intent
        const data = await stripeCheckCanBuy({
          currentPrice: boxesInfo?.price,
          currencyRate: boxesInfo?.currencyRate,
        });
        const { clientSecret = '' } = data;
        const payload = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements?.getElement(CardElement) || { token: '' },
          },
        });
        if (payload.error) {
          toast({ status: 'error', description: payload.error.message });
          setLoading(false);
          return;
        }

        const item = await stripeBuyAfterPayment({
          paymentIntentId: payload.paymentIntent.id,
        });
        setBuyInfo(item);
        isBuySuccess = true;
      } catch (error: any) {
        setLoading(false);
        toast({
          status: 'error',
          description: error?.response.data.message || error?.response.message
            || error?.message,
        });
        return;
      }
    } else {
      try {
        const item = await buyBoxMutation.mutateAsync({});
        isBuySuccess = true;
      } catch (error) {
        // empty
      }
    }
    setLoading(false);
    if (isBuySuccess) {
      toast({
        title: 'Success',
        description: `Payment successful!`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      onOpenGenesis();
    }
  };

  const handleRadioChange = (value: any) => {
    setPayMethod(value);
  };

  const { RiveComponent } = useRive({
    src: "open_box.riv",
    autoplay: true,
    onLoop: () => {
      setShowRevi(false);
    },
  });

  return (

    <Box
      mt={{ base: '8px', md: '16px' }}
      p={{ base: '8px' }}
      maxW="1064px"
      mx="auto"
      color="#fff"
    >
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpenGenesis}
        onClose={onCloseGenesis}
        size="lg"
        isCentered
      >
        <ModalOverlay
          // bg="blue.100"
          backdropFilter="blur(10px)"
        />
        <ModalContent
          bg="transparent"
          boxShadow="unset"
          borderRadius="18px"
          display="flex"
          justifyItems="center"
          alignItems="center"
        >

          {showRevi
            && (
              <RiveComponent
                style={{
                  height: '500px',
                }}
              />
            )}
          {!showRevi && buyInfo && (
            <>
              <ScaleFade in initialScale={0.7}>
                <Image src={buyInfo.image} mb="8px" height="400px" />
              </ScaleFade>

              <Text
                fontSize="20px"
                fontWeight="700"
                p="4px 12px"
                bg="linear-gradient(324.93deg, #65D063 -3.41%, rgba(190, 255, 189, 0.51) 75.66%)"
                mb="16px"
                borderRadius="36px"
                color="#000"
              >

                {`#${buyInfo?.name}`}
              </Text>
              <Button
                as={Link}
                mt={4}
                mx="auto"
                bg="none"
                onClick={() => navigate('/profile/my-items')}
                color="#9F9F9F"
              >
                Go to my NFTs
              </Button>
            </>
          )}
        </ModalContent>
      </Modal>
      <ConfirmBuyDialog
        isOpen={isOpen}
        onClose={onClose}
        confirmInfo={{
          title: "Buy genesis box",
          description: "Are you sure to buy this genesis box?",
          callback: async () => {
            handleSubmit();
          },
        }}
      />

      <Text
        fontSize="20px"
        fontWeight="400"
        p={{ base: '20px  0px', md: '28px 0px ' }}
      >
        <Link
          as={RouterLink}
          to="/shop"
        >
          Shop
          {' '}

        </Link>
        |
        {' '}
        Payment
      </Text>
      <HStack
        alignItems="center"
        justifyContent="space-between"
        p={{ base: '12px 16px', md: '16px 24px' }}
        bg="#132035"
        borderRadius="10px"
        mb={{ base: '20px', md: '40px' }}

      >
        <AvatarImage
          profileAvatar={userInfo?.profileAvatar}
          w={{ base: '30px', md: '70px' }}
          h={{ base: '30px', md: '70px' }}
          mr="9px"
        />
        <Box>
          <Text fontSize="20px" fontWeight="800" color="#fff" m="0px">
            {userInfo?.fullName}
          </Text>
          <Text fontSize="18px" fontWeight="500" color="#fff" mb="0">
            {userInfo?.email}
          </Text>
        </Box>
        <Spacer />
        <Flex
          flexDirection={{ base: 'column', md: 'row' }}
          gap="12px"
        >
          <HStack>
            <Image src={xtrToken} w={{ base: '14px', md: '24px' }} />
            <Text fontSize="15px" fontWeight="500" color="#ffffff" margin-inline-end={0} mr={{ base: '24px', md: '44px' }}>
              {formatStringToNumber(wallets?.XTR)}
            </Text>
          </HStack>
          <HStack>

            <Image src={gxtToken} w={{ base: '14px', md: '24px' }} />
            <Text fontSize="15px" fontWeight="500" color="#ffffff" m={0} mr={{ base: '24px', md: '44px' }}>
              {formatStringToNumber(wallets?.GXT)}
            </Text>
          </HStack>
          <HStack>

            <Image src={avaxToken} w={{ base: '14px', md: '24px' }} />
            <Text fontSize="15px" fontWeight="500" color="#ffffff">
              {formatStringToNumber(wallets?.AVAX || 0)}
            </Text>
          </HStack>

        </Flex>
      </HStack>
      <form onSubmit={handleSubmit}>

        <Flex
          flexDirection={{ base: 'column', md: 'row' }}
          gap={{ base: '20px', md: '25px' }}
        >

          <Box
            flex={{ base: 1, md: "57%" }}
            p="32px"
            bg="#132035"
            borderRadius="10px"
          >
            <Text
              fontSize={{ base: '14px', md: '20px' }}
              fontWeight="800"
            >
              Payment method
            </Text>
            <RadioGroup onChange={handleRadioChange} value={payMethod}>
              <Stack>
                <Radio value={AVAX} mb="16px" mt="20px">
                  <Flex
                    w="102px"
                    h="64px"
                    ml="18px"
                    borderRadius="6px"
                    background="linear-gradient(#03141F, #03141F) padding-box, linear-gradient(to bottom, #3A4453, #3A445300) border-box"
                    border=" 1px solid transparent"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Image src={avaxToken} w="24px" />
                    <Text
                      fontWeight="bold"
                      fontSize="20px"
                      ml="4px"
                    >
                      AVAX
                    </Text>
                  </Flex>
                </Radio>
                <Radio value={CARD}>
                  <Flex gap="8px" ml="18px" flexWrap="wrap">
                    {cards.map((c) => (
                      <Box
                        key={c}
                        w="102px"
                        h="64px"
                        borderRadius="6px"
                        background="linear-gradient(#03141F, #03141F) padding-box, linear-gradient(to bottom, #3A4453, #3A445300) border-box"
                        border=" 1px solid transparent"
                      >
                        <Box w="100%" height="100%" bgImage={c} bgRepeat="no-repeat" bgPos="center" />
                      </Box>
                    ))}
                  </Flex>
                </Radio>
              </Stack>
            </RadioGroup>
            <Divider bg="#03141F" borderRadius="6px" mb="22px" h="2px" my="22px" />

            {payMethod === 'card'
              && (
                <FormControl isRequired>
                  <CardElement
                    id="card-element"
                    options={{
                      hidePostalCode: true,
                      style: {
                        base: {
                          fontSize: '16px',
                          color: '#c9c9c9',
                          '::placeholder': {
                            color: '#aab7c4',
                          },
                          // backgroundColor: '#203659',
                          // padding: "12px 20px",
                        },
                        invalid: {
                          color: '#fa755a',
                        },
                      },
                    }}
                  />
                </FormControl>
              )}
          </Box>

          <Box
            flex={{ base: 1, md: "43%" }}
            w={{ base: '100%', md: 'auto' }}
            p="32px"
            bg="#132035"
            borderRadius="10px"
            fontSize={{ base: '14px', md: '18px' }}
          >
            <Text
              fontSize={{ base: '14px', md: '20px' }}
              fontWeight="800"
              mb={{ base: '24px', md: '34px' }}
            >
              Order summary
            </Text>
            <Flex justifyContent="space-between" mb="22px">
              <Text>Genesis box:</Text>
              <Text>1</Text>
              <Flex gap="2">
                <Image src={ICON_BY_TOKENS[unit]} w="20px" />
                <Text textTransform="uppercase">
                  {finalPrice}
                </Text>
              </Flex>
            </Flex>
            <Flex justifyContent="space-between" mb="22px">
              <Text>Discount:</Text>
              <Text>0</Text>
            </Flex>
            <Flex justifyContent="space-between" mb="22px">
              <Text>VAT:</Text>
              <Text>0</Text>
            </Flex>
            <Divider bg="#03141F" borderRadius="6px" mb="22px" h="2px" />
            <Flex justifyContent="space-between" mb="22px">
              <Text>Total:</Text>
              <Flex gap="2">
                <Image src={ICON_BY_TOKENS[unit]} w="20px" />
                <Text textTransform="uppercase">
                  {finalPrice}
                </Text>
              </Flex>

            </Flex>
            <Box textAlign="center">
              <Button
                mt={4}
                mx="auto"
                px={10}
                py={4}
                bg="#FF8008"
                isLoading={loading}
                onClick={() => onOpen()}
                variant={loading ? "solid" : "stripe"}
                color="#fff"
              >
                Payment
              </Button>
            </Box>

          </Box>

        </Flex>
      </form>
    </Box>
  );
}

function BoxDetailWrapper() {
  return (
    <Suspense fallback="loading...">
      <BoxDetail />
    </Suspense>
  );
}

export default BoxDetailWrapper;
