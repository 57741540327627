import {
  Flex, Slider, SliderFilledTrack, SliderTrack,
  Text,
} from "@chakra-ui/react";
import TextIntl from "src/components/TextIntl";

interface ItemAttributeProps {
  text: string;
  percentageVal: number;
  value: number|string;
  accentColor: boolean
  // fontSize?: string;
}

function ItemAttribute({
  text, percentageVal, value, accentColor = false,
}:ItemAttributeProps) {
  return (
    <Flex pb={{ base: '16px', xl: '20px' }}>
      <TextIntl
        color="rgba(255, 255, 255, 0.6)"
        fontSize={{ base: '20px', xl: '20px' }}
        fontWeight="400"
        flex={{ base: '30%', xl: '25%' }}
        text={text}
      />
      <Slider
        focusThumbOnChange={false}
        value={percentageVal}
        flex={{ base: '40%', xl: '50%' }}
        colorScheme={accentColor ? "green" : "teal"}
      >
        <SliderTrack boxSize={4} bgColor="#FFFFFF" borderRadius={18}>
          <SliderFilledTrack borderRightRadius={18} />
        </SliderTrack>
      </Slider>
      <Text flex={{ base: '15%', xl: '10%' }} align="center" fontSize="16px">{value}</Text>
    </Flex>
  );
}

export default ItemAttribute;
