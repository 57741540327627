import {
  Box, Flex, Text, Image,
} from "@chakra-ui/react";
import { ICON_BY_TOKENS } from "src/constants/token";
import { formatStringToNumber } from "src/utils/utils";
import WalletChart from "./WalletChart";

interface WalletItemProps {
  borderColor: string,
  backgroundColor: string,
  token: string,
  balance: number,
  color1?: string,
  color2?: string
}

function WalletItem({
  borderColor,
  backgroundColor,
  token,
  balance,
  color1,
  color2,
}: WalletItemProps) {
  return (
    <Box
      borderWidth={1}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      px="20px"
      py="26px"
      w="100%"
      borderRadius="29px"
    >
      {/* <Text fontSize={{ xl: "32px", base: '24px' }}
       fontWeight="bold" color="#000000" mb={{ xl: "28px", base: '16px' }}>
        Rate
      </Text> */}
      {/* {color1 && color2
        && (
          <WalletChart
            color1={color1}
            color2={color2}
          />
        )} */}

      <Flex alignItems="center" mb="10px">
        <Image src={ICON_BY_TOKENS[token] as any} w="35px" mr="8px" />
        <Text fontSize="20px" fontWeight="bold" color="rgba(0, 0, 0, 0.7)">
          {token}
        </Text>
      </Flex>
      <Box
        py="16px"
        bgColor="white"
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRadius={{ xl: "26px", base: '16px' }}
      >
        <Text fontSize={{ xl: "40px", base: '32px' }} fontWeight="bold" color="rgba(0, 0, 0, 0.7)">
          {formatStringToNumber(balance)}
        </Text>
      </Box>
    </Box>
  );
}

export default WalletItem;
