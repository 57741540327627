import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from "@chakra-ui/modal";
import { useDepositToken, DepositDto, useUserInfo } from "src/api/profile";
import { Form } from "react-final-form";
import {
  Box, Flex, Button, useToast,
} from '@chakra-ui/react';
import { formatStringToNumber, toWei } from "src/utils/utils";
import { useGxtContract, useTravelContract, useXtrContract } from "src/hooks/useContract";
import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { TOKEN_ADDRESS, TOKEN_SYMBOLS } from "src/constants/token";
import LabeledNumberField from './LabeledNumberField';
import SelectTokenField from "./SelectTokenField";
import TextIntl from "./TextIntl";

interface DepositProps {
  isOpen: boolean;
  onClose: () => void;
  wallets: any,
  initialValues: any,
  getBalances: any
}

interface IError {
  amount?: string,
  token?: string
}

const validate = (values: DepositDto) => {
  const errors: IError = {};

  if (!values.token) {
    errors.token = 'Please select a token';
  }

  if (!values.amount) {
    errors.amount = 'Please enter amount';
  } else if (+values.amount < 0) {
    errors.amount = 'Please enter amount > 0';
  } else if (values.wallets
    && values.token
    && +values.amount > +(values.wallets[values.token] || 0)) {
    errors.amount = `Please enter amount <= ${formatStringToNumber(values.wallets[values.token] || 0)}`;
  }

  return errors;
};

function Deposit({
  isOpen, onClose,
  wallets,
  initialValues,
  getBalances,
}: DepositProps) {
  const { data: userInfo } = useUserInfo();
  const { account } = useWeb3React();
  const [loading, setLoading] = useState(false);
  const [allowances, setAllowances] = useState<any>({});
  const gxtContract = useGxtContract();
  const xtrContract = useXtrContract();
  const travelContract = useTravelContract();
  const depositMutation = useDepositToken();

  const toast = useToast({ position: 'top' });

  const handleDeposit = async (values: DepositDto) => {
    try {
      setLoading(true);
      let result: any;
      if (values.token === 'AVAX') {
        result = await travelContract.methods.deposit(
          `${process.env.REACT_APP_PREFIX_USERID}${userInfo.id}`,
        ).send({
          from: account,
          value: toWei({ val: values.amount, decimals: 18 }),
        });
      } else {
        result = await travelContract.methods.depositToken(
          TOKEN_ADDRESS[values.token],
          toWei({ val: values.amount, decimals: 18 }),
          `${process.env.REACT_APP_PREFIX_USERID}${userInfo.id}`,
        ).send({
          from: account,
        });
      }

      setTimeout(() => {
        getBalances();
      }, 1000);

      await depositMutation.mutateAsync({
        transactionHash: result?.transactionHash,
        fromAddress: account,
        amount: values.amount,
        tokenSymbol: values.token,
      });

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }

    onClose();
  };

  const getAllowances = async () => {
    try {
      const [GXT, XTR] = await Promise.all([
        gxtContract.methods.allowance(account, process.env.REACT_APP_TRAVEL_CONTRACT).call(),
        xtrContract.methods.allowance(account, process.env.REACT_APP_TRAVEL_CONTRACT).call(),
      ]);

      setAllowances({
        GXT,
        XTR,
      });
      return null;
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    if (gxtContract && xtrContract) {
      getAllowances();
    }
  }, [gxtContract, xtrContract]);

  const handleApprove = async (values: DepositDto) => {
    try {
      if (!values.amount) {
        toast({ status: "error", description: 'Please enter amount' });
        return;
      }
      setLoading(true);
      if (values.token === TOKEN_SYMBOLS.XTR) {
        await xtrContract.methods.approve(process.env.REACT_APP_TRAVEL_CONTRACT, '115792089237316195423570985008687907853269984665640564039457584007913129639935').send({
          from: account,
        });
      } else if (values.token === TOKEN_SYMBOLS.GXT) {
        await gxtContract.methods.approve(process.env.REACT_APP_TRAVEL_CONTRACT, '115792089237316195423570985008687907853269984665640564039457584007913129639935').send({
          from: account,
        });
      }

      await getAllowances();

      handleDeposit(values);

      // setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      isCentered
    >
      <ModalOverlay />
      <ModalContent w="600px" maxW="95%" className="modal-content" bg="#132035">
        <ModalCloseButton color="#fff" right="30px" top="16px" />
        <ModalBody className="modal-body">
          <Form
            keepDirtyOnReinitialize
            onSubmit={handleDeposit}
            validate={validate}
            initialValues={initialValues}
            render={({ handleSubmit, values }) => (
              <form id="deposit-form" onSubmit={handleSubmit}>
                <TextIntl
                  fontSize="24px"
                  fontWeight="bold"
                  color="#fff"
                  mb="36px"
                  textAlign="center"
                  mt="32px"
                  text="depositToken"
                />

                <Box mb="36px">
                  <SelectTokenField
                    label="Select token"
                    name="token"
                    wallets={wallets}
                  />
                </Box>

                <Box mb="36px">
                  <LabeledNumberField
                    placeholder={values.token ? `Max: ${formatStringToNumber(wallets[values.token] || 0)}` : "Amount"}
                    name="amount"
                    maxValue={wallets[values.token] || 0}
                  />
                </Box>
                { loading
                     && <TextIntl text="depositLoadingText" textAlign="center" color="#333333" mb="4px" />}
                <Flex justifyContent="center">

                  { (!values?.token || values?.token === 'AVAX' || allowances[values.token] > 0)
                    ? (
                      <Button
                        bgColor="#FF8008"
                        borderRadius="16px"
                        h="60px"
                        minW="210px"
                        mb="18px"
                        mx="auto"
                        _hover={{
                          backgroundColor: '#FF8008',
                        }}
                        type="submit"
                        isLoading={loading}
                      >
                        <TextIntl text={loading ? "Waiting execute smart contract..." : "deposit"} />
                      </Button>
                    ) : (
                      <Button
                        bgColor="#FF8008"
                        borderRadius="16px"
                        h="60px"
                        w="210px"
                        mb="18px"
                        mx="auto"
                        _hover={{
                          backgroundColor: '#FF8008',
                        }}
                        type="button"
                        isLoading={loading}
                        onClick={() => {
                          handleApprove(values);
                        }}
                      >
                        <TextIntl text="approve" />
                      </Button>
                    )}

                </Flex>

              </form>
            )}
          />
        </ModalBody>

      </ModalContent>
    </Modal>
  );
}

export default Deposit;
