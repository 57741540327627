import { makeAutoObservable } from "mobx";
import { NftBase } from "../api/item";

class NftStore {
  constructor() {
    makeAutoObservable(this);
  }

  showSendOutModal = false;

  sendOutCallback: any;

  selectedNft?: NftBase | undefined;

  handleShowSendOutModal = (item: NftBase, callback?: any) => {
    this.selectedNft = item;
    this.showSendOutModal = true;
    if (callback) {
      this.sendOutCallback = callback;
    }
  };

  hideSendOutModal = () => {
    this.selectedNft = undefined;
    this.showSendOutModal = false;
  };
}

export default new NftStore();
