// theme/index.js
import { extendTheme } from '@chakra-ui/react';

// Global style overrides
// import styles from './styles'

// Foundational style overrides
// import borders from './foundations/borders'

// Component style overrides
import { modalTheme } from './components/modal';

const overrides = {
  // styles,
  // borders,
  // Other foundational style overrides go here
  components: {
    Modal: modalTheme,
    // Other components go here
  },
  styles: {
    global: {
      "*, *::before, *::after": {
        margin: 0,
        padding: 0,
        boxSizing: "border-box",
      },
    },
  },
};

export default extendTheme(overrides);
