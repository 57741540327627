import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from "@chakra-ui/modal";
import {
  requestOtp, useWithdraw, useWithdrawFee, WithdrawDto,
} from "src/api/profile";
import { Form } from "react-final-form";
import {
  Box, Flex, Text, Button, Icon, Image, useToast,
} from '@chakra-ui/react';
import { IoMdRefresh } from 'react-icons/io';
import { useState } from "react";
import { formatStringToNumber } from "src/utils/utils";
import Web3 from 'web3';
import { ICON_BY_TOKENS } from "src/constants/token";
import LabeledNumberField from './LabeledNumberField';
import LabeledTextField from './LabeledTextField';
import SelectTokenField from "./SelectTokenField";
import TextIntl from "./TextIntl";

interface WithdrawProps {
  isOpen: boolean;
  onClose: () => void;
  wallets: any,
  initialValues: any,
  getBalances: any
}

interface IError {
  amount?: string,
  token?: string,
  otp?: string,
  walletAddress?: string
}
const validate = (values: WithdrawDto) => {
  const errors: IError = {};

  if (!values.token) {
    errors.token = 'Please select a token';
  }

  if (!values.amount) {
    errors.amount = 'Please enter amount';
  } else if (+values.amount < 0) {
    errors.amount = 'Please enter amount > 0';
  } else if (values.wallets
    && values.token
    && +values.amount > +(values.wallets[`${values.token}_Max`] || 0)) {
    errors.amount = `Please enter amount <= ${values.wallets[`${values.token}_Max`] || 0}`;
  }

  if (!values.walletAddress) {
    errors.walletAddress = 'Please enter wallet address';
  } else if (!Web3.utils.isAddress(values.walletAddress)) {
    errors.walletAddress = 'Please enter valid wallet address';
  }

  return errors;
};

const validateOTP = (values: WithdrawDto) => {
  const errors: IError = {};

  if (!values.otp) {
    errors.otp = 'Please enter otp';
  }

  return errors;
};

let interval: any;

function Withdraw({
  isOpen, onClose,
  wallets,
  initialValues,
  getBalances,
}: WithdrawProps) {
  const [withdrawInfo, setWithdrawInfo] = useState<WithdrawDto>();
  const [show, setShow] = useState('FORM');
  const [otpTime, setOtpTime] = useState(0);
  const [formValue, setFormValue] = useState<any>({});

  const withdrawMutation = useWithdraw();

  const toast = useToast({ position: 'top' });

  const { data: withdrawFee } = useWithdrawFee({
    tokenSymbol: formValue.token,
    amount: formValue.amount,
  });

  const handleWithdraw = async (values: any) => {
    if (withdrawInfo) {
      await withdrawMutation.mutateAsync({
        amount: +withdrawInfo.amount,
        tokenSymbol: withdrawInfo?.token,
        walletAddress: withdrawInfo?.walletAddress,
        otp: values.otp,
      });
      getBalances();
      setShow('FORM');
      setWithdrawInfo(undefined);
      onClose();
    }
  };

  const handleResendOtp = () => {
    requestOtp();
    setOtpTime(30);
    interval = setInterval(() => {
      setOtpTime((prev) => {
        if (prev === 0) {
          clearInterval(interval);
        }
        return prev - 1;
      });
    }, 1000);
  };

  const gotoConfirm = async (values: WithdrawDto) => {
    const amount = +values.amount - withdrawFee.totalFee;

    if (amount <= 0) {
      toast({ status: 'error', description: "You don't enough balance" });
      return;
    }

    setWithdrawInfo(values);
    setShow('OTP');

    handleResendOtp();
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      isCentered
    >
      <ModalOverlay />
      <ModalContent w="600px" maxW="95%" className="modal-content" bg="#132035">
        <ModalCloseButton color="#fff" right="30px" top="16px" />
        <ModalBody className="modal-body">
          { show === 'FORM'
            ? (
              <Form
                keepDirtyOnReinitialize
                onSubmit={gotoConfirm}
                validate={validate}
                initialValues={withdrawInfo || initialValues}
                render={({ handleSubmit, values }) => (
                  <form id="withdraw-form" onSubmit={handleSubmit}>
                    <TextIntl
                      fontSize="24px"
                      fontWeight="bold"
                      color="#fff"
                      mb="24px"
                      textAlign="center"
                      mt="32px"
                      text="withdrawToken"
                    />

                    <Box mb="24px">
                      <SelectTokenField
                        label="Select token"
                        name="token"
                        wallets={wallets}
                        onChangeValue={(val: string) => {
                          setFormValue((prev: any) => ({
                            ...prev,
                            token: val,
                          }));
                        }}
                      />
                    </Box>

                    <Box mb="24px">
                      <LabeledTextField
                        placeholder="Receiver wallet address"
                        name="walletAddress"
                      />
                    </Box>

                    <Box mb="16px">
                      <LabeledNumberField
                        placeholder={values?.token ? `Max: ${wallets[`${values?.token}_Max`]?.toFixed(2) || 0}` : "Amount"}
                        name="amount"
                        maxValue={wallets[`${values?.token}_Max`] || 0}
                        onChangeValue={(val: string) => {
                          setFormValue((prev: any) => ({
                            ...prev,
                            amount: val,
                          }));
                        }}
                      />
                    </Box>

                    <Flex
                      alignItems="center"
                      mb="24px"
                      justifyContent="space-between"
                      px="16px"
                    >
                      <TextIntl fontSize="16px" color="#666666" mr="10px" text="Fee (Transfer fee & System fee)" />
                      <Flex alignItems="center">
                        <Text fontSize="16px" color="#333333" fontWeight="bold">
                          { formatStringToNumber(0.1 as any, 6)}
                        </Text>
                        <Image src={ICON_BY_TOKENS.AVAX} w="20px" ml="10px" />
                      </Flex>
                    </Flex>

                    <Flex justifyContent="center">
                      <Button
                        bgColor="#FF8008"
                        borderRadius="16px"
                        h="60px"
                        w="210px"
                        mb="18px"
                        mx="auto"
                        _hover={{
                          backgroundColor: '#FF8008',
                        }}
                        type="submit"
                        isLoading={withdrawMutation.isLoading}
                      >
                        <TextIntl text="withdraw" />
                      </Button>
                    </Flex>

                  </form>
                )}
              />
            ) : (
              <Form
                keepDirtyOnReinitialize
                onSubmit={handleWithdraw}
                validate={validateOTP}
                initialValues={{}}
                render={({ handleSubmit }) => (
                  <form id="withdraw-otp" onSubmit={handleSubmit}>
                    <TextIntl
                      fontSize="24px"
                      fontWeight="bold"
                      color="#090404"
                      mb="36px"
                      textAlign="center"
                      mt="32px"
                      text="confirmWithdraw"
                    />
                    <Flex alignItems="center" mb="8px">
                      <TextIntl fontSize="16px" color="#666666" mr="10px" text="token" />
                      <Text fontSize="16px" color="#333333" fontWeight="bold">
                        {withdrawInfo?.token}
                      </Text>
                    </Flex>
                    <Flex alignItems="center" mb="8px">
                      <TextIntl fontSize="16px" color="#666666" mr="10px" text="walletAddress" />
                      <Text fontSize="16px" color="#333333" fontWeight="bold">
                        {withdrawInfo?.walletAddress}
                      </Text>

                    </Flex>
                    <Flex
                      alignItems="center"
                      mb="8px"
                      pb="8px"
                      borderBottom="1px solid #ebebeb"
                    >
                      <TextIntl fontSize="16px" color="#666666" mr="10px" text="amount" />
                      <Text fontSize="16px" color="#333333" fontWeight="bold">
                        { formatStringToNumber(withdrawInfo?.amount as any)}
                      </Text>
                      <Image src={ICON_BY_TOKENS[withdrawInfo?.token as any]} w="20px" ml="10px" />
                    </Flex>

                    <Flex alignItems="center" mb="8px">
                      <TextIntl fontSize="16px" color="#666666" mr="10px" text="Fee (Transfer fee & System fee)" />
                      <Text fontSize="16px" color="#333333" fontWeight="bold">
                        {withdrawFee?.systemFee}
                      </Text>
                      <Image src={ICON_BY_TOKENS.AVAX} w="20px" ml="10px" />
                    </Flex>
                    {false && (
                    <Flex
                      alignItems="center"
                      mb="8px"
                      pb="8px"
                      borderBottom="1px solid #ebebeb"
                    >
                      <TextIntl fontSize="16px" color="#666666" mr="10px" text="transferFee" />
                      <Text fontSize="16px" color="#333333" fontWeight="bold">
                        { formatStringToNumber(withdrawFee?.transferFee as any, 6)}
                      </Text>
                      <Image src={ICON_BY_TOKENS[withdrawInfo?.token as any]} w="20px" ml="10px" />
                    </Flex>
                    )}

                    <Flex
                      alignItems="center"
                      mb="36px"
                      pb="8px"
                      borderBottom="1px solid #ebebeb"
                    >
                      <TextIntl fontSize="16px" color="#666666" mr="10px" text="receivedAmount" />
                      <Text fontSize="16px" color="#333333" fontWeight="bold">
                        { formatStringToNumber(withdrawInfo?.token !== 'AVAX' ? +withdrawInfo!.amount : +withdrawInfo!.amount - 0.1, 6)}
                      </Text>
                      <Image src={ICON_BY_TOKENS[withdrawInfo?.token as any]} w="20px" ml="10px" />
                    </Flex>

                    <Box mb="36px">
                      <LabeledTextField
                        placeholder="Enter otp is sent to your email"
                        name="otp"
                        onChangeValue={(val: any) => {
                          if (val?.length === 6) {
                            handleWithdraw({
                              otp: val,
                            });
                          }
                        }}
                      />

                      { otpTime > 0
                        ? (
                          <Text
                            color="#666666"
                            textAlign="center"
                            mt="5px"
                            fontWeight="500"
                            fontSize="14px"
                            lineHeight="40px"
                          >
                            Resend OTP after
                            {' '}
                            {otpTime}
                            {' '}
                            seconds
                          </Text>
                        )
                        : (
                          <Button
                            variant="ghost"
                            leftIcon={<Icon as={IoMdRefresh} color="orange" boxSize={5} />}
                            color="orange"
                            mx="auto"
                            justifyContent="center"
                            display="flex"
                            mt="5px"
                            alignItems="center"
                            onClick={handleResendOtp}
                            fontSize="14px"
                          >
                            <TextIntl text="resendOtp" />
                          </Button>
                        )}

                    </Box>

                    <Flex justifyContent="space-between">
                      <Button
                        border="1px solid #FF8008"
                        color="#FF8008"
                        borderRadius="16px"
                        h="60px"
                        w="210px"
                        mb="18px"
                        _hover={{
                          backgroundColor: '#FF8008',
                          color: '#ffffff',
                        }}
                        type="button"
                        onClick={() => {
                          setShow('FORM');
                        }}
                      >
                        <TextIntl text="back" />
                      </Button>

                      <Button
                        bgColor="#FF8008"
                        borderRadius="16px"
                        h="60px"
                        w="210px"
                        mb="18px"
                        _hover={{
                          backgroundColor: '#FF8008',
                        }}
                        type="submit"
                        isLoading={withdrawMutation.isLoading}
                      >
                        <TextIntl text="withdraw" />
                      </Button>
                    </Flex>

                  </form>
                )}
              />
            )}

        </ModalBody>

      </ModalContent>
    </Modal>
  );
}

export default Withdraw;
