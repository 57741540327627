import { Form } from "react-final-form";
import { Suspense } from "react";
import {
  Button,
  Box,
  Flex,
} from "@chakra-ui/react";
import { ChangePasswordDto, useChangePassword } from "src/api/profile";
import LabeledTextField from "src/components/LabeledTextField";
import TextIntl from "src/components/TextIntl";

interface ChangePasswordError {
  currentPassword?: string,
  newPassword?: string,
  confirmNewPassword?: string
}

const validate = (values: ChangePasswordDto) => {
  const errors: ChangePasswordError = {};

  if (!values.currentPassword || !values.currentPassword.trim()) {
    errors.currentPassword = 'Please enter current password';
  }

  if (!values.newPassword || !values.newPassword.trim()) {
    errors.newPassword = 'Please enter new password';
  } else if (values.newPassword !== values.confirmNewPassword) {
    errors.confirmNewPassword = 'Password and new password not matches';
  }

  return errors;
};

function ChangePasswordForm() {
  const changePasswordMutation = useChangePassword();
  const handleChangePassword = async (values: ChangePasswordDto) => {
    changePasswordMutation.mutateAsync(values);
  };

  return (
    <Box
      maxW="600px"
      mx="auto"
      bgColor="#132035"
      borderRadius="29px"
      p="40px"
    >
      <Form
        keepDirtyOnReinitialize
        onSubmit={handleChangePassword}
        validate={validate}
        render={({ handleSubmit }) => (
          <form id="change-password" onSubmit={handleSubmit}>

            <TextIntl
              fontSize="24px"
              fontWeight="bold"
              textTransform="uppercase"
              color="#fff"
              mb="36px"
              textAlign="center"
              text="changePassword"
            />

            <Box mb="36px">
              <LabeledTextField
                placeholder="Current password"
                name="currentPassword"
                type="password"
              />
            </Box>
            <Box mb="36px">
              <LabeledTextField
                placeholder="New password"
                name="newPassword"
                type="password"
              />
            </Box>
            <Box mb="36px">
              <LabeledTextField
                placeholder="Confirm new password"
                name="confirmNewPassword"
                type="password"
              />
            </Box>
            <Flex justifyContent="center">
              <Button
                bgColor="#FF8008"
                borderRadius="16px"
                h="60px"
                w="210px"
                mb="18px"
                mx="auto"
                _hover={{
                  backgroundColor: '#FF8008',
                }}
                isLoading={changePasswordMutation.isLoading}
                type="submit"
              >
                <TextIntl text="changePassword" />
              </Button>
            </Flex>

          </form>

        )}
      />
    </Box>
  );
}

function ChangePasswordWrapper() {
  return (
    <Suspense fallback="loading...">
      <ChangePasswordForm />
    </Suspense>
  );
}

export default ChangePasswordWrapper;
