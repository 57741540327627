import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/modal";

import ResetPasswordForm from "./ResetPasswordForm";

interface ResetPasswordProps {
  isOpen: boolean;
  onClose: () => void;
}

function ResetPasswordModal({
  isOpen, onClose,
}: ResetPasswordProps) {
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      isCentered
    >
      <ModalOverlay />
      <ModalContent w="600px" maxW="95%" className="modal-content">
        <ModalBody className="modal-body">
          <ResetPasswordForm />
        </ModalBody>

      </ModalContent>
    </Modal>
  );
}

export default ResetPasswordModal;
