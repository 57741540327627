import {
  Modal, ModalBody, ModalContent, ModalOverlay,
} from "@chakra-ui/modal";
import { observer } from "mobx-react";
import {
  Flex, Image, Text,
} from "@chakra-ui/react";
import TextIntl from "../../TextIntl";
import NftStore from "../../../stores/NftStore";
import SendOutForm from "./SendOutForm";

function SendOutModal() {
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={NftStore.showSendOutModal as boolean}
      onClose={() => {
        NftStore.hideSendOutModal();
      }}
      size="lg"
      isCentered
    >
      <ModalOverlay />
      <ModalContent w="600px" maxW="95%" className="modal-content">
        <ModalBody className="modal-body">
          <TextIntl
            fontSize="24px"
            fontWeight="bold"
            textTransform="uppercase"
            color="#090404"
            mb="36px"
            textAlign="center"
            text="sendItemToWallet"
          />

          <Flex justifyContent="center">
            <Text color="#000" fontSize={18} fontWeight="bold">
              #
              {NftStore.selectedNft?.name}
            </Text>
          </Flex>
          <Flex justifyContent="center" mb="35px">
            <Image src={NftStore.selectedNft?.image ?? ''} w="200px" />
          </Flex>
          <SendOutForm
            itemId={NftStore.selectedNft?.id ?? 0}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
export default observer(SendOutModal);
