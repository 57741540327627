import {
  Box, Button, Flex, Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { useCheckSendOutFee, useSendOutItem } from "src/api/item";
import NftStore from "src/stores/NftStore";
import { getNFTSendOutFee } from "src/utils/utils";
import LabeledTextField from "../../LabeledTextField";
import TextIntl from "../../TextIntl";

interface SendOutFormError {
  address?: string;
  password?: string;
}
const validate = (values: any) => {
  const errors: SendOutFormError = {};
  if (!values.address || !values.address.trim()) {
    errors.address = 'Please enter you wallet address';
  }
  if (!values.password || !values.password.trim()) {
    errors.password = 'Please confirm your password';
  }
  return errors;
};

interface SendOutFormProps {
  itemId: number;
}

function SendOutForm({ itemId }: SendOutFormProps) {
  const sendOutItemMutation = useSendOutItem();
  const checkSendOutMutation = useCheckSendOutFee();
  const navigate = useNavigate();

  const [fee, setFee] = useState();
  const onSubmit = async (values: any) => {
    if (!fee) {
      const _fee = await checkSendOutMutation.mutateAsync({ ...values, itemId });
      setFee(_fee);
    } else {
      await sendOutItemMutation.mutateAsync({ ...values, itemId });
      NftStore.hideSendOutModal();
      navigate('/profile/my-items');
    }
  };
  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form id="send-out-item" onSubmit={handleSubmit}>

          <Box mb="36px">
            <LabeledTextField placeholder="Wallet Address" name="address" />
          </Box>
          <Box mb="36px">
            <LabeledTextField placeholder="Verify your password" name="password" type="password" />
          </Box>
          {fee && (
          <Flex alignItems="center" mb="36px">
            <TextIntl
              fontSize="18px"
              fontWeight="bold"
              textTransform="capitalize"
              color="#090404"
              text="fee"
            />
            <Text
              fontSize="16px"
              textTransform="capitalize"
              color="#090404"
            >
              :
              {' '}
              {getNFTSendOutFee(fee)}
            </Text>
          </Flex>
          )}
          <Box mb="36px">
            <Flex justifyContent="center">
              <Button
                bgColor="#ACAFB2"
                borderRadius="16px"
                h="60px"
                w="210px"
                mb="18px"
                mx="auto"
                _hover={{
                  backgroundColor: '#898B8E',
                }}
                type="button"
                fontSize="18px"
                fontWeight="600"
                onClick={() => { NftStore.hideSendOutModal(); }}
                isLoading={sendOutItemMutation.isLoading || checkSendOutMutation.isLoading}
              >
                <TextIntl text="cancel" />
              </Button>

              <Button
                bgColor="#FF8008"
                borderRadius="16px"
                h="60px"
                w="210px"
                mb="18px"
                mx="auto"
                _hover={{
                  backgroundColor: '#FF8008',
                }}
                type="submit"
                fontSize="18px"
                fontWeight="600"
                isLoading={sendOutItemMutation.isLoading || checkSendOutMutation.isLoading}
              >
                <TextIntl text={fee ? "send" : "checkFee"} />
              </Button>

            </Flex>
          </Box>
        </form>
      )}
    />
  );
}

export default SendOutForm;
