import React, { Suspense, useState } from "react";
import {
  Text,
  Box,
  Link,
  Flex,
  Image,
  Button,
  HStack,
  Spacer,
  Divider,
} from '@chakra-ui/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import bgGroup from 'src/assets/images/bg-group.png';
import box from 'src/assets/images/box-only.png';
// import shoeImage from 'src/assets/images/shoe-group.png';
import successImage from 'src/assets/images/success.png';
import GlobalStore from "src/stores/GlobalStore";
import { useBuyBox, useRemainBoxes } from "src/api/item";
import TextIntl from "src/components/TextIntl";
import { getCurrencySVG } from "src/utils/coincurrency";
import iconECard from '../../assets/images/iconECard.png';
import iconRCard from '../../assets/images/iconRCard.png';
import iconLCard from '../../assets/images/iconLCard.png';
import iconDCard from '../../assets/images/iconDCard.png';
// import Confetti from 'react-confetti';
// import bycircleImage from 'src/assets/images/bycircle-group.png';

function BoxDetail() {
  const navigate = useNavigate();
  const [buyInfo, setBuyInfo] = useState<any>();

  const buyBoxMutation = useBuyBox();

  const { data: boxesInfo } = useRemainBoxes();
  const currencySrc = getCurrencySVG(boxesInfo?.currency || '');

  const handleBuy = () => {
    GlobalStore.handleShowConfirmDialog({
      title: 'Confirm',
      description: 'Do you want to buy this box?',
      callback: async () => {
        // const item = await buyBoxMutation.mutateAsync();
        // setBuyInfo(item);
      },
    });
  };

  return (
    <Box>
      <Box
        // backgroundColor="#000000"
        // boxShadow="0px 4px 5px 2px rgba(200, 200, 200, 0.25)"
        // borderRadius={{ lg: '26px' }}
        p={{ base: '8px', xl: '0px' }}
        maxW="1064px"
        mx="auto"
        color="#fff"

      >
        <Text
          fontSize="20px"
          fontWeight="400"
          p={{ base: '20px  0px', md: '28px 0px ' }}
        >
          <Link
            as={RouterLink}
            to="/shop"
          >
            Shop
          </Link>
          {' '}
          |
          Box
        </Text>
        <Flex
          flexDirection={{ base: 'column', lg: 'row' }}
        // alignItems="center"
        >
          <Box flex={1} mb={{ base: '20px', lg: 0 }} position="relative">
            <Image
              src={bgGroup}
              width="100%"
            />
            <Box
              position="absolute"
              top="14%"
              left="50%"
              transform="translateX(-50%)"
            >
              <Image
                src={buyInfo?.image || box}
                mx="auto"
              />
              {buyInfo
                && (
                  <>
                    <Text as="h3" align="center" fontSize={24} fontWeight={700} mb="10px">
                      {buyInfo?.name}
                    </Text>
                    <HStack justifyContent="space-between">
                      <HStack justifyContent="center">
                        <Image src={iconECard} w={{ base: '25%', xl: '20%' }} />
                        <Text color="#31B07B">{buyInfo?.efficiency}</Text>
                      </HStack>
                      <HStack justifyContent="center">
                        <Image src={iconRCard} w={{ base: '25%', xl: '20%' }} />
                        <Text color="#31B07B">{buyInfo?.distance}</Text>
                      </HStack>
                      <HStack justifyContent="center">
                        <Image src={iconLCard} w={{ base: '25%', xl: '20%' }} />

                        <Text color="#31B07B">{buyInfo?.luck}</Text>
                      </HStack>
                      <HStack justifyContent="center">
                        <Image src={iconDCard} w={{ base: '25%', xl: '20%' }} />
                        <Text color="#31B07B">{buyInfo?.recovery}</Text>
                      </HStack>
                    </HStack>
                  </>
                )}

            </Box>
          </Box>
          <Box flex={1} pl={{ lg: "100px" }} w={{ base: '100%', lg: 'auto' }}>
            {buyInfo
              ? (
                <Box
                  background="linear-gradient(358.62deg, rgba(211, 243, 253, 0.24) 2.84%, rgba(138, 169, 227, 0.0192) 96.69%);"
                  height="100%"
                  px={{ lg: "40px", base: '20px' }}
                  border="3px solid #EDCCA6"
                  borderRadius="38px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  py="40px"
                >
                  <Image src={successImage} w={{ '2xl': "173px", xl: '140px', base: '100px' }} mb="24px" />

                  <TextIntl
                    m={{ '2xl': '32px 0 64px 0', base: "0 0 40px 0" }}
                    fontSize={{ '2xl': "40px", base: '30px' }}
                    fontWeight="bold"
                    textAlign="center"
                    text="purcharseSuccess"
                  // mb="24px"
                  />

                  <Button
                    w="100%"
                    border="1px solid #FF8008"
                    borderRadius="16px"
                    color="#ffffff"
                    fontSize={{ lg: "26px", base: '20px' }}
                    fontWeight="bold"
                    bgColor="#FF8008"
                    h={{ lg: "63px", base: '45px' }}
                    _hover={{
                      backgroundColor: "#FF8008",
                    }}
                    onClick={() => {
                      navigate('/profile/my-items');
                    }}
                  >
                    <TextIntl text="myItems" />
                  </Button>
                </Box>
              )
              : (
                <Box
                  // background="linear-gradient(358.62deg, rgba(211, 243, 253, 0.24) 2.84%,
                  //  rgba(138, 169, 227, 0.0192) 96.69%);"
                  height="100%"
                  px={{ lg: "40px", base: '20px' }}
                // border="3px solid #EDCCA6"
                // borderRadius="38px"
                >
                  <Text
                    fontSize={{ lg: "25px", base: '14px' }}
                    fontWeight="bold"
                  >
                    #BOX
                  </Text>
                  <Divider my="24px" borderRadius="6px" bg="#50718F" h="2px" />
                  <HStack alignItems="center">
                    <Text
                      fontSize={{ lg: "40px", base: '30px' }}
                      fontWeight="bold"
                      color="#FF8008"
                    >
                      {boxesInfo?.price}
                      {' '}
                      {/* {boxesInfo?.currency} */}
                    </Text>
                    <Image src={currencySrc} w="24px" mr="6px" display={{ base: 'none', lg: 'block' }} />

                    <Spacer />
                    <Button
                      flex={1}
                      border="1px solid #FF8008"
                      borderRadius="8px"
                      color="#ffffff"
                      fontSize={{ lg: "18px", base: '18px' }}
                      fontWeight="bold"
                      bgColor="#FF8008"
                      _hover={{
                        backgroundColor: "#FF8008",
                      }}
                      p="12px 46px"
                      // onClick={handleBuy}
                      onClick={() => {
                        navigate('/box-payment');
                      }}
                      isLoading={buyBoxMutation.isLoading}
                    >
                      <TextIntl text="buyNow" />
                    </Button>
                  </HStack>
                  {/* <TextIntl
                    fontSize="18px"
                    color="rgba(255, 255, 255, 0.6)"
                    mb="10px"
                    text="chanceShoe"
                  /> */}

                  {/* <Flex
                    mb={{ "2xl": "39px", base: "24px" }}
                  >
                    <Box flex={1}>
                      <Box
                        h="25px"
                        borderRadius="18px"
                        w="100%"
                        bgColor="#FFFFFF"
                        position="relative"
                        overflow="hidden"
                      >
                        <Box
                          position="absolute"
                          top={0}
                          left={0}
                          bottom={0}
                          bgColor="#35FFCF"
                          borderRadius="18px"
                          w="95%"
                        />
                      </Box>
                    </Box>
                    <Text ml="24px" w="50px" color="#35FFCF" fontSize="18px">
                      95%
                    </Text>
                  </Flex> */}

                  {/* <TextIntl
                    fontSize="18px"
                    color="rgba(255, 255, 255, 0.6)"
                    mb="10px"
                    text="chanceBicycle"
                  /> */}

                  {/* <Flex mb={{ "2xl": "89px", base: "40px" }}>
                    <Box flex={1}>
                      <Box
                        h="25px"
                        borderRadius="18px"
                        w="100%"
                        bgColor="#FFFFFF"
                        position="relative"
                        overflow="hidden"
                      >
                        <Box
                          position="absolute"
                          top={0}
                          left={0}
                          bottom={0}
                          bgColor="#35FFCF"
                          borderRadius="18px"
                          w="5%"
                        />
                      </Box>
                    </Box>
                    <Text ml="24px" w="50px" color="#35FFCF" fontSize="18px">
                      5%
                    </Text>
                  </Flex> */}

                  {/* <Flex alignItems="center" mb="24px">
                    <Button
                      flex={1}
                      mr="23px"
                      border="1px solid #FF8008"
                      borderRadius="16px"
                      color="#FF8008"
                      fontSize="18px"
                      fontWeight="bold"
                      bgColor="transparent"
                      h="56px"
                      display={{ base: 'none', lg: 'block' }}
                      onClick={() => {
                        navigate('/shop');
                      }}
                    >
                      <TextIntl text="cancel" />

                    </Button>

                  </Flex> */}

                </Box>
              )}

          </Box>
        </Flex>
      </Box>
    </Box>
  );
}

function BoxDetailWrapper() {
  return (
    <Suspense fallback="loading...">
      <BoxDetail />
    </Suspense>
  );
}

export default BoxDetailWrapper;
