import React from "react";
import ReactPaginate, { ReactPaginateProps } from "react-paginate";
import styled from "@emotion/styled";
import { IconButton, useMediaQuery } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Partial } from "../types/Partial";

const StyledPagination = styled(ReactPaginate)`
  color: blue;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  gap: 32px;
  margin-bottom: 8px;

  .selected {
    a {
      background: none;
      color: #FF8E26;
    }
  }
  a {
    width: 40px;
    height: 40px;
    color: #4a5568;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
    // border-radius: 100px;
    color: #B9B9B9;
  }
`;

function Pagination(props: Partial<ReactPaginateProps>) {
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  return (
    <StyledPagination
      pageCount={1}
      pageRangeDisplayed={1}
      marginPagesDisplayed={isMobile ? 1 : 2}
      pageClassName="page"
      nextLabel={(
        <IconButton
          variant="ghost"
          ml={0}
          icon={<ChevronRightIcon boxSize={8} />}
          colorScheme="black"
          aria-label="next"

        />
      )}
      previousLabel={(
        <IconButton
          variant="ghost"
          mr={0}
          icon={<ChevronLeftIcon boxSize={8} />}
          colorScheme="black"
          aria-label="next"
        />
      )}
      nextClassName={isMobile ? "none" : ''}
      previousClassName={isMobile ? "none" : ''}
      {...props}
    />
  );
}

export default Pagination;
