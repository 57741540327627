import avaxToken from 'src/assets/images/avax.svg';
import gxtToken from 'src/assets/images/gxt.svg';
import xtrToken from 'src/assets/images/xtr.svg';
import usdToken from 'src/assets/images/usd.svg';

export const TOKEN_SYMBOLS = {
  XTR: 'XTR',
  GXT: 'GXT',
};

export const ICON_BY_TOKENS: any = {
  AVAX: avaxToken,
  avax: avaxToken,
  GXT: gxtToken,
  gxt: gxtToken,
  XTR: xtrToken,
  xtr: xtrToken,
  USD: usdToken,
  usd: usdToken,
};

export const TOKENS = [{
  label: 'XTR',
  value: 'XTR',
}, {
  label: 'GXT',
  value: 'GXT',
}, {
  label: 'AVAX',
  value: 'AVAX',
}];

export const TOKEN_ADDRESS: any = {
  XTR: process.env.REACT_APP_TOKEN_XTR,
  GXT: process.env.REACT_APP_TOKEN_GXT,
};
