import { Suspense } from "react";
import {
  Box, Image, useBreakpointValue,
} from '@chakra-ui/react';
import banner from 'src/assets/images/market-bn.webp';
import bannerMb from 'src/assets/images/market-bn-mb.webp';
import { CardSneaker } from "./sneakerCard";
import TextFilter from "../../components/TextFilter";
import SneakerPaging from './sneakerPaging';

function Sneaker() {
  const imageSrc = useBreakpointValue({
    base: bannerMb,
    xl: banner,
  });

  return (
    <Box>
      {/* <BannerHeader title="Marketplace" /> */}
      <Image src={imageSrc} />
      <Box maxW={{ base: "100%", xl: "76.8%" }} margin="auto" p={{ base: 2, sm: 4, xl: 0 }}>
        <TextFilter title="xtripMarketplace" />
        <CardSneaker />
        <SneakerPaging />
      </Box>

    </Box>
  );
}

function MarketTableWrapper() {
  return (
    <Suspense fallback="loading...">
      <Sneaker />
    </Suspense>
  );
}

export default MarketTableWrapper;
