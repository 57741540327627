import React from "react";

import { useLocation, Navigate } from "react-router-dom";
import { ACCESS_TOKEN } from '../../constants/localStorage';

function AuthToken() {
  const param = useLocation();
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    localStorage.setItem(ACCESS_TOKEN, (param?.search.substring(1)));
    return <Navigate to="/" state={{ from: param }} replace />;
  }
  if (localStorage.getItem(ACCESS_TOKEN)) {
    return <Navigate to="/" state={{ from: param }} replace />;
  }
  return (
    <div />
  );
}

export default AuthToken;
