import {
  Box,
  Text,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
} from '@chakra-ui/react';
import { ICON_BY_TOKENS } from 'src/constants/token';
import { itemShoe, useCancelItemOrder } from '../../api/item';
import { formatStringToNumber } from '../../utils/utils';
import { useUserInfo } from '../../api/profile';
import GlobalStore from '../../stores/GlobalStore';
import TextIntl from '../../components/TextIntl';
import shoe from '../../assets/images/shoe.png';
import nftBg from '../../assets/images/nft-bg.png';
import bgCard from '../../assets/images/bgCard.svg';
import iconECard from '../../assets/images/iconECard.png';
import iconRCard from '../../assets/images/iconRCard.png';
import iconLCard from '../../assets/images/iconLCard.png';
import iconDCard from '../../assets/images/iconDCard.png';

interface ItemProps {
  item: itemShoe,
  userId?: number,
  handleDetail: any,
  showPrice?: boolean
}

function Item({
  item, userId, handleDetail, showPrice = true,
}: ItemProps) {
  const cancelItemOrderMutation = useCancelItemOrder();

  const { data: userInfo } = useUserInfo();

  const handleCancelOrder = () => {
    GlobalStore.handleShowConfirmDialog({
      title: 'Confirm',
      description: 'Are you sure to stop selling this item?',
      callback: () => {
        cancelItemOrderMutation.mutate(item?.id as any);
      },
    });
  };

  return (
    <Box
      key={item.id}
      position="relative"
      w="100%"
      rounded="8px"
      bgColor="rgba(0,0,0,0)"
      pt="127.27%"
      // bgImage="linear-gradient(rgba(19, 52, 63, 1),
      // rgba(7, 52, 66, 1),
      // rgba(17, 51, 58, 1));"
      _hover={{
        // outline: "2px solid rgba(255, 142, 38, 1);",
        boxShadow: "0px 0px 5px 5px rgba(0, 206, 219, 0.25);",
        transition: "all 0.1s ease 0s",
        cursor: 'pointer',
      }}
      bgImage={bgCard}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="contain"
      onClick={() => userInfo?.id !== item?.seller && handleDetail(item.id)}

    >
      {/* <Image src={bgCard} w="100%" /> */}
      <Box
        rounded="26px"
        justifyContent="center"
        // h={{ base: '394px', xl: '450px' }}
        onClick={() => userInfo?.id !== item?.seller && handleDetail(item.id)}
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        display="flex"
        justifyItems="flex-start"
        flexDirection="column"
        borderTopLeftRadius="8px"
        borderTopRightRadius="8px"

      >
        <Box
          position="relative"
          pt="106%"
          rounded="2"

        >
          <Box
            height="100%"
            position="absolute"
            borderTopLeftRadius="8px"
            borderTopRightRadius="8px"
            top={0}
            left={0}
            right={0}
            bottom={0}
          // background="linear-gradient(180deg, #132A3F 2.56%, #073442 45.31%, #11333A 100%);"
          >
            <Grid
              height="100%"
              // margin={4}
              borderRadius="8px"
              // borderWidth="1px"
              p={{ base: "4px", md: "6px" }}
              // bgImage={nftBg}
              // bgImage={`url(${item.image || shoe}),url(${nftBg})`}
              bgImage={item.image || shoe}
              bgPosition="50% 20%"
              bgRepeat="no-repeat"
              bgSize="81.81%"
              // borderColor="linear-gradient(rgba(255, 255, 255, 1),
              // rgba(255, 255, 255, 0));"
              templateRows="repeat(6, 1fr)"
              templateColumns="repeat(4, 1fr)"

            >
              <GridItem
                flexDirection="column"
                colStart={1}
                colSpan={4}
                rowStart={6}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Text
                  align="center"
                  mb="0"
                  fontSize={{ base: 14, xl: 18 }}
                  fontWeight={{ base: 800 }}
                >
                  #
                  {item?.name}
                </Text>
                <HStack
                  justifyContent="space-between"
                  color="#fff"
                  fontSize={{ base: 14, xl: 18 }}
                  fontWeight={{ base: 800 }}
                >

                  <HStack justifyContent="center">
                    <Text>{item?.efficiency}</Text>
                    <Image src={iconECard} w={{ base: '30%', xl: '20%', '2xl': '30%' }} />
                  </HStack>
                  <HStack justifyContent="center">
                    <Text>{item?.recovery}</Text>
                    <Image src={iconRCard} w={{ base: '30%', xl: '20%', '2xl': '30%' }} />
                  </HStack>
                  <HStack justifyContent="center">
                    <Text>{item?.luck}</Text>
                    <Image src={iconLCard} w={{ base: '30%', xl: '20%', '2xl': '30%' }} />
                  </HStack>
                  <HStack justifyContent="center">
                    <Text>{item?.distance}</Text>
                    <Image src={iconDCard} w={{ base: '30%', xl: '20%', '2xl': '30%' }} />
                  </HStack>

                </HStack>
              </GridItem>

            </Grid>
          </Box>

        </Box>
        <Flex
          justifyContent="space-between"
          // flexDirection="row-reverse"
          alignItems="center"
          // bg="#12475C"
          flex={1}
          borderBottomLeftRadius="8px"
          borderBottomRightRadius="8px"
          px={{ base: "6%" }}
          fontSize={{ base: 14, xl: 18 }}
          fontWeight={{ base: 800 }}
        >
          <Text align="center" mb="0">
            Lv:
            {" "}
            {item?.level}
          </Text>
          <Text align="center" mb="0">
            Mint:
            {' '}
            {" "}
            {item?.num_of_mints}
          </Text>

          {showPrice && (
            <Flex gap="8px">
              <Text>
                {formatStringToNumber(+(item?.amount || item?.price || 0) + (item.tax_amount ?? 0))}
                {' '}
              </Text>
              <Image src={ICON_BY_TOKENS[item?.token || 'XTR']} w="20px" />
            </Flex>
          )}
          {userInfo?.id === item?.seller
            && (
              <Button
                color="#FFFFFF"
                backgroundColor="#FF8008"
                borderRadius="8px"
                onClick={handleCancelOrder}
                isLoading={cancelItemOrderMutation.isLoading}
                py={{ base: '6px' }}
                px={{ base: '14px' }}
                height="auto"
                fontSize={{ base: 14, xl: 18 }}
              >
                {/* <TextIntl text="cancelOrder" /> */}
                Cancel
              </Button>
            )}
        </Flex>
      </Box>
    </Box>
  );
}

export default Item;
