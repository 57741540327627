import React, { useState } from 'react';
import {
  Flex, Image, Button, Text,
} from '@chakra-ui/react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
} from "@chakra-ui/modal";
import pulseIcon from 'src/assets/images/pulse.svg';

function WrongNetwork({ handleDisconnect }: any) {
  const [showWrongNetworkModal, setShowWrongNetworkModal] = useState(false);

  return (
    <div>
      <Flex
        alignItems="center"
        justifyContent="center"
        h="48px"
        rounded="18px"
        px="20px"
        cursor="pointer"
        onClick={() => {
          setShowWrongNetworkModal(true);
        }}
      >
        <Image src={pulseIcon} w="16px" mr="8px" alt="icon" />
        Wrong Network
      </Flex>

      <Modal
        closeOnOverlayClick={false}
        isOpen={showWrongNetworkModal}
        onClose={() => {
          setShowWrongNetworkModal(false);
        }}
        size="md"
        isCentered
      >
        <ModalOverlay />
        <ModalContent className="modal-content">
          <ModalHeader>
            Wrong network
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="modal-body">
            <div className="px-[24px] pb-[24px]">
              <p className="text-[20px] font-medium text-white mb-[16px]">
                Please connect to the avalanche network.
              </p>

              <Button
                bgColor="#FF8008"
                borderRadius="16px"
                h="60px"
                w="210px"
                mb="18px"
                mx="auto"
                _hover={{
                  backgroundColor: '#FF8008',
                }}
                type="submit"
                onClick={handleDisconnect}
              >
                <Text>
                  Disconnect
                </Text>
              </Button>
            </div>
          </ModalBody>

        </ModalContent>
      </Modal>

    </div>
  );
}

export default WrongNetwork;
