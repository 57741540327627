import React, { useState, useEffect, useRef } from "react";
import {
  SimpleGrid,
  // Box,
  Text,
  Menu,
  MenuButton,
  MenuList,
  Button,
  // Slider,
  // SliderTrack,
  // SliderFilledTrack,
  // SliderThumb,
  Spacer,
  HStack,
  MenuItem,

} from '@chakra-ui/react';
import { TriangleDownIcon } from '@chakra-ui/icons';
// import { FaFilter } from 'react-icons/fa';
import { useSearchParams } from "react-router-dom";
import { debounce } from "lodash";
import TextIntl from "./TextIntl";

function TextFilter({ title }: { title?: string }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [valueFilter, setValueFilter] = useState('Newest');
  const [value, setValue] = useState(searchParams.get('orderBy') || '');
  const [valueSort, setValueSort] = useState(searchParams.get('sort') || "");
  // const [valueLevel, setValueLevel] = useState(searchParams.get('level') || '');

  const firstUpdate = useRef(true);
  const debounced = React.useCallback(
    debounce((v, x) => setSearchParams({ orderBy: v, sort: x }), 300),
    [],
  );

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (value || valueSort) {
      debounced(value, valueSort);
    }
  }, [value, valueSort, debounced]);

  // const [mint, setMint] = useState(0);
  // const [level, setLevel] = useState(0);
  // const handleMint = (mintValue: any) => setMint(mintValue);
  // const handleLevel = (levelValue: any) => {
  //   setValueLevel(levelValue);
  //   setLevel(levelValue);
  // };
  const handleOldest = (e: any) => {
    setValue(e.target.value);
    setValueSort('ASC');
    setValueFilter(e.target.innerHTML);
  };
  const handleNewest = (e: any) => {
    setValue(e.target.value);
    setValueSort('DESC');
    setValueFilter(e.target.innerHTML);
  };
  const handleHighest = (e: any) => {
    setValue(e.target.value);
    setValueSort('DESC');
    setValueFilter(e.target.innerHTML);
  };
  const handleLowest = (e: any) => {
    setValue(e.target.value);
    setValueSort('ASC');
    setValueFilter(e.target.innerHTML);
  };

  return (
    <SimpleGrid
      columns={{ base: 1 }}
      spacing={{ md: 10 }}
      // background="#fff"
      // m={{ base: '20px 20px 20px 20px', xl: '56px 63px 63px 81px' }}
      mb={{ base: '36px', xl: '50px' }}
      gridAutoRows="1fr"
      color="#000"
    >
      {/* <TextIntl
        fontSize={{ base: '30px', md: '40px' }}
        fontWeight="700"
        color="#000000"
        mb={{ base: '16px', md: '0' }}
        text={title}
      /> */}
      {/* <Text display={{ base: 'none', md: 'none', xl: 'block' }}>
        <Spacer />
      </Text> */}
      <HStack justifyContent="flex-end">
        {/* <Spacer mr={{ md: 6, xl: -12 }} /> */}
        <Menu>
          <MenuButton
            as={Button}
            // border="1px solid #D8D8D8"
            borderRadius="45px"
            px="24px"
            fontSize={{ base: '18px', xl: '24px' }}
            rightIcon={<TriangleDownIcon />}
            backgroundColor="#1C2F4E"
            color="#E1E1E1"
            fontWeight={500}
            h={{ base: '40px', xl: '50px' }}
            _hover={{
              backgroundColor: "#1C2F4E",
            }}
            _active={{
              backgroundColor: "#1C2F4E",
            }}
          >
            {valueFilter}
          </MenuButton>
          <MenuList bgColor="#fff" p="0 8px">
            <MenuItem value="amount" fontSize={{ base: '15px', xl: '20px' }} color="rgba(0, 0, 0, 0.7)" onClick={(e) => handleLowest(e)}>Lowest Price</MenuItem>
            <MenuItem value="amount" fontSize={{ base: '15px', xl: '20px' }} color="rgba(0, 0, 0, 0.7)" onClick={(e) => handleHighest(e)}>Highest Price</MenuItem>
            <MenuItem value="created_at" fontSize={{ base: '15px', xl: '20px' }} color="rgba(0, 0, 0, 0.7)" onClick={(e) => handleNewest(e)}>Newest</MenuItem>
            <MenuItem value="created_at" fontSize={{ base: '15px', xl: '20px' }} color="rgba(0, 0, 0, 0.7)" onClick={(e) => handleOldest(e)}>Oldest</MenuItem>
          </MenuList>
        </Menu>
        {/* <Box width="100%" borderRadius="17px" position="relative">
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<FaFilter />}
              border="1px solid #D8D8D8"
              fontSize={{ base: '16px', xl: '24px' }}
              width="100%"
              borderRadius="17px"
              color="#575757"
              fontWeight={400}
              h={{ base: '40px', xl: '58px' }}
            >
              <TextIntl text="Filter" />
            </MenuButton>
            <MenuList bgColor="#fff" p="16px" w="100%">
              <SimpleGrid columns={2}>
                <Text align="left" color="rgba(0, 0, 0, 0.7)"
                fontSize={{ base: '14px', md: '18px' }}>Mint</Text>
                <Text align="right" color="rgba(0, 0, 0, 0.7)"
                fontSize={{ base: '14px', md: '18px' }}>
                  {mint}
                  /5
                </Text>
              </SimpleGrid>
              <Slider
                focusThumbOnChange={false}
                value={mint}
                colorScheme="teal"
                onChange={handleMint}
                max={5}
              >
                <SliderTrack boxSize={3} bgColor="#EBEBEB" borderRadius={18}>
                  <SliderFilledTrack borderRightRadius={18} bgColor="#FF8008" />
                </SliderTrack>
                <SliderThumb fontSize="sm" boxSize={4} />
              </Slider>
              <SimpleGrid columns={2}>
                <Text align="left" color="rgba(0, 0, 0, 0.7)"
                fontSize={{ base: '14px', md: '18px' }}>Level</Text>
                <Text align="right" color="rgba(0, 0, 0, 0.7)"
                fontSize={{ base: '14px', md: '18px' }}>
                  {level}
                  /10
                </Text>
              </SimpleGrid>
              <Slider
                focusThumbOnChange={false}
                value={level}
                colorScheme="teal"
                onChange={handleLevel}
                max={10}
              >
                <SliderTrack boxSize={3} bgColor="#EBEBEB" borderRadius={18}>
                  <SliderFilledTrack borderRightRadius={18} bgColor="#FF8008" />
                </SliderTrack>
                <SliderThumb fontSize="sm" boxSize={4} />
              </Slider>

            </MenuList>
          </Menu>
        </Box> */}
      </HStack>
    </SimpleGrid>
  );
}

export default TextFilter;
