import avaxToken from 'src/assets/images/avax.svg';
import xtrToken from 'src/assets/images/xtr.svg';
import gxtToken from 'src/assets/images/gxt.svg';

export const AVAX = 'avax';
export const XTR = 'xtr';
export const GXT = 'gxt';

export const getCurrencySVG = (unit: string) => {
  switch (unit.toLowerCase()) {
    case AVAX:
      return avaxToken;
    case GXT:
      return gxtToken;
    case XTR:
    default:
      return xtrToken;
  }
};
