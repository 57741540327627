import { makeAutoObservable } from "mobx";
import { LANG } from "src/constants/localStorage";
import vietnam from 'src/assets/images/vietnam.png';
import england from 'src/assets/images/england.png';
import japan from 'src/assets/images/japan.png';
import i18n from '../i18n';

export const LANGUAGES = [{
  label: 'EN',
  value: 'en',
  image: england,
}, {
  label: 'JP',
  value: 'jp',
  image: japan,
}, {
  label: 'VI',
  value: 'vi',
  image: vietnam,
}];

class GlobalStore {
  lang = 'en';

  constructor() {
    makeAutoObservable(this);

    this.lang = localStorage.getItem(LANG) || 'en';
    i18n.changeLanguage(this.lang);
  }

  changeLang = (lang: string) => {
    i18n.changeLanguage(lang);
    this.lang = lang;
    localStorage.setItem(LANG, lang);
  };
}

export default new GlobalStore();
