import {
  Box, Table,
  HStack,
  Tbody,
  Heading,
  Flex,
} from "@chakra-ui/react";
import FilterSingle from 'src/components/FilterSingle';
import FilterByRangerDate from 'src/components/FilterByRangerDate';
import SortByCreatedTime from 'src/components/SortByCreatedTime';
import {
  ORDER_STATE_OPTIONS,
  ORDER_STATE_OBJ,
  TOKENS,
  ORDER_TYPE_OPTIONS,
  ORDER_TYPE_OBJ,
} from 'src/constants/transaction';

import useSearchParams from "src/utils/useSearchParams";
import { OrderDocument, useOrders } from "src/api/profile";
import FilterByPage from "src/components/FilterByPage";
import TextIntl from "src/components/TextIntl";
import OrderItem from "./OrderItem";

function Transactions() {
  const { params } = useSearchParams();
  const { data } = useOrders(params);

  return (
    <Box>
      <Box
        bgColor="#132035"
        borderRadius="29px"
        py="24px"
        px={{ lg: '20px', base: "8px" }}
      >
        <Heading color="#fff" fontSize="24px" mb="8px">
          MANAGE ORDERS
        </Heading>
        <Flex
          mb="8px"
          pb="10px"
          flexWrap="wrap"
          gap="8px"
        >
          <FilterSingle
            title="Type"
            name="order_type"
            options={ORDER_TYPE_OPTIONS}
            optionsObj={ORDER_TYPE_OBJ}
          />

          <FilterByRangerDate />

          <FilterSingle
            title="State"
            name="state"
            options={ORDER_STATE_OPTIONS}
            optionsObj={ORDER_STATE_OBJ}
          />

          <FilterSingle
            title="Token"
            name="token"
            options={TOKENS}
            optionsObj={{}}
          />
          <SortByCreatedTime />
          {/* <FilterByReviewType /> */}
        </Flex>
        <hr style={{
          height: '4px',
          background: "#2A3951",
          border: "none",
          marginBottom: "18px",
        }}
        />
        {(data?.meta?.totalPages && data?.meta?.totalPages > 0)
          ? (
            <Box overflowX="auto">
              <Table w="full">
                <Tbody>
                  {data?.items?.map((order: OrderDocument) => (
                    <OrderItem order={order} key={order.id} />
                  ))}
                </Tbody>

              </Table>
            </Box>
          )
          : (
            <TextIntl color="#666666" textAlign="center" mt="40px" text="dontHaveTransactionOrders" />
          )}

        {data?.meta?.totalPages && data?.meta?.totalPages > 0
          && <FilterByPage totalPages={data?.meta?.totalPages} />}

      </Box>
    </Box>
  );
}

export default Transactions;
