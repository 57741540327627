import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip, Icon, Box } from '@chakra-ui/react';
import { FiCopy } from 'react-icons/fi';

function CopyButton({ text }: {text: string}) {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <Box>

      <CopyToClipboard
        text={text}
        onCopy={() => {
          setShowTooltip(true);
          setTimeout(() => {
            setShowTooltip(false);
          }, 3000);
        }}
      >

        <Icon
          as={FiCopy}
          w="20px"
          cursor="pointer"
          color="#fff"
        />
      </CopyToClipboard>
      <Tooltip isOpen={showTooltip} label="Copied">&nbsp;</Tooltip>

    </Box>
  );
}

export default CopyButton;
