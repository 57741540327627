import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/modal";

import ActiveAccountForm from "./ActiveAccountForm";

interface ActiveAccountProps {
  isOpen: boolean;
  onClose: () => void;
}

function ActiveAccountModal({
  isOpen, onClose,
}: ActiveAccountProps) {
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      isCentered
    >
      <ModalOverlay />
      <ModalContent w="600px" maxW="100%" className="modal-content">
        <ModalBody className="modal-body">
          <ActiveAccountForm />
        </ModalBody>

      </ModalContent>
    </Modal>
  );
}

export default ActiveAccountModal;
