import { Form } from "react-final-form";
import { Suspense } from "react";
import {
  Image,
  Button,
  Box,
  Flex,
} from "@chakra-ui/react";
import { ForgotPasswordDto, useForgotPassword } from "src/api/login";
import logoIcon from 'src/assets/images/logo.png';
import AuthStore from "src/stores/AuthStore";
import LabeledTextField from "../LabeledTextField";
import { validateEmail } from "../../utils/validators";
import TextIntl from "../TextIntl";

interface ForgotPasswordError {
  email?: string
}

const validate = (values: ForgotPasswordDto) => {
  const errors: ForgotPasswordError = {};

  if (!values.email || !values.email.trim()) {
    errors.email = 'Please enter your email';
  } if (!validateEmail(values.email)) {
    errors.email = 'Please enter valid email';
  }

  return errors;
};

function ForgotPasswordForm() {
  const forgotPasswordMutation = useForgotPassword();

  const handleForgotPassword = async (values: ForgotPasswordDto) => {
    await forgotPasswordMutation.mutateAsync(values);

    AuthStore.handleToggleForgotPasswordModal();

    setTimeout(() => {
      AuthStore.handleToggleResetPasswordModal(values.email);
    }, 300);
  };

  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={handleForgotPassword}
      validate={validate}
      render={({ handleSubmit }) => (
        <form id="forgot-password-form" onSubmit={handleSubmit}>
          <Image src={logoIcon} w="75px" mb="24px" mx="auto" mt="36px" />

          <TextIntl
            fontSize="24px"
            fontWeight="bold"
            textTransform="uppercase"
            color="#FAFAFA"
            mb="36px"
            textAlign="center"
            text="forgotPassword"
          />

          <Box mb="36px">
            <LabeledTextField
              placeholder="Email address"
              name="email"
            />
          </Box>
          <Flex justifyContent="center">
            <Button
              bgColor="#FF8008"
              borderRadius="16px"
              h="60px"
              w="210px"
              mb="18px"
              mx="auto"
              _hover={{
                backgroundColor: '#FF8008',
              }}
              type="submit"
              isLoading={forgotPasswordMutation.isLoading}
              fontSize="18px"
              fontWeight="600"
            >
              <TextIntl text="forgotPassword" />
            </Button>
          </Flex>

          <Flex
            fontSize="18px"
            lineHeight="21px"
            color="rgba(255, 255, 255, 0.5)"
            textAlign="center"
            justifyContent="center"
            mb="46px"
          >
            <TextIntl text="hasAccount" />

            {' '}
            <TextIntl
              mb="0"
              color="#FF8008"
              fontWeight="bold"
              ml="8px"
              cursor="pointer"
              onClick={() => {
                AuthStore.handleToggleForgotPasswordModal();
                setTimeout(() => {
                  AuthStore.handleToggleLoginModal();
                }, 300);
              }}
              text="login"
              _hover={{
                textDecoration: 'underline',
              }}
            />
          </Flex>

        </form>
      )}
    />
  );
}

function ForgotPasswordFormWrapper() {
  return (
    <Suspense fallback="loading...">
      <ForgotPasswordForm />
    </Suspense>
  );
}

export default ForgotPasswordFormWrapper;
