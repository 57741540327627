// import Sneaker from "./containers/sneaker";
import SneakerDetail from "./containers/sneaker/sneakerDetail";
import Shop from "./containers/shop";
import AuthToken from "./containers/auth-token";
import BoxDetailWrapper from "./containers/box-detail";
import Profile from "./containers/profile";
import BoxPaymentWrapper from './containers/box-payment';
import Sneaker from './containers/sneaker';

export interface RouteConfigItem {
  path: string;
  component: any;
  exact?: boolean;
  routes?: Array<RouteConfigItem>;
}

const routes: RouteConfigItem[] = [
  {
    path: "/",
    component: Sneaker,
    exact: true,
  },
  {
    path: "/marketplace",
    component: Sneaker,
    exact: true,
  },
  {
    path: "/shop",
    component: Shop,
    exact: true,
  },
  {
    path: "/auth",
    component: AuthToken,
    exact: true,
  },
  {
    path: "/itemDetail/:id",
    component: SneakerDetail,
    exact: true,
  },
  {
    path: "/box-detail",
    component: BoxDetailWrapper,
    exact: true,
  },
  {
    path: "/box-payment",
    component: BoxPaymentWrapper,
    exact: true,
  },
  {
    path: "/profile/*",
    component: Profile,
    exact: true,
  },

];

export default routes;
