import {
  Box,
  Text,
  HStack,
  Divider,
  Button,
  Image,
  Flex,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useNftContract } from 'src/hooks/useContract';
import { itemKeys, WalletItemDocument } from 'src/api/item';
import { useWeb3React } from '@web3-react/core';
import { useUserInfo } from 'src/api/profile';
import { useQueryClient } from '@tanstack/react-query';

// import { formatStringToNumber } from 'src/utils/utils';
// import { useUserInfo } from 'src/api/profile';
import GlobalStore from 'src/stores/GlobalStore';
// import TextIntl from 'src/components/TextIntl';
import iconECard from '../../assets/images/iconECard.png';
import iconRCard from '../../assets/images/iconRCard.png';
import iconLCard from '../../assets/images/iconLCard.png';
import iconDCard from '../../assets/images/iconDCard.png';
import bgCard from '../../assets/images/bgCard.png';
import shoe from '../../assets/images/shoe.png';

interface ItemProps {
  item: WalletItemDocument,
  // userId?: number,
  removeItemById?: any
}

function Item({ item, removeItemById }: ItemProps) {
  // const cancelItemOrderMutation = useCancelItemOrder();
  const toast = useToast({ position: "top" });

  const nftContract = useNftContract();
  const { account } = useWeb3React();
  const queryClient = useQueryClient();
  const { data: userInfo } = useUserInfo();
  const [isLoading, setIsLoading] = useState(false);
  const { attributes } = item;
  const luck = attributes.find((e) => e.trait_type === 'Luck');
  const efficiency = attributes.find((e) => e.trait_type === 'Efficiency');
  const distance = attributes.find((e) => e.trait_type === 'Distance');
  const recovery = attributes.find((e) => e.trait_type === 'Recovery');
  const handleCancelOrder = () => {
    GlobalStore.handleShowConfirmDialog({
      title: 'Confirm',
      description: 'Do you want to import this item?',
      callback: async () => {
        setIsLoading(true);
        try {
          const result = await nftContract.methods.sendInternal(item.tokenId, userInfo.id).send({
            from: account,
          });
          if (result.status) {
            toast({ title: 'Import item successfully', status: 'success' });
            removeItemById(item.tokenId);
          } else {
            throw new Error('Import item failed!');
          }
        } catch (error) { /* empty */
          toast({ title: 'Import item failed!', status: 'error' });
        }
        setIsLoading(false);

        setTimeout(() => {
          queryClient.invalidateQueries(itemKeys.all);
        }, 10000);
      },
    });
  };

  return (
    <Box
      key={item.name}
      position="relative"
      w="100%"
      boxShadow="0px 4px 5px 4px rgba(0, 0, 0, 0.25)"
      rounded={{ base: "40px" }}
      bgColor="rgba(0,0,0,0)"
    >
      <Image src={bgCard} w="100%" />
      <Box
        rounded="26px"
        justifyContent="center"
        // h={{ base: '394px', xl: '450px' }}
        // onClick={() => userInfo?.id !== item?.seller && handleDetail(item.id)}
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
      >
        <Box height="50%" rounded="2xl" p={8}>
          <Box
            height="100%"
            bgImage={item.image || shoe}
            bgPosition="center"
            bgRepeat="no-repeat"
            bgSize="contain"
          />
        </Box>
        <Flex
          flexDirection="column"
          justifyContent="space-around"
          height="50%"
          px={{ base: '20px', lg: '10px' }}
        >
          <Text as="h3" align="center" fontSize={24} fontWeight={700} mb="0">
            {item?.name}
          </Text>
          <HStack justifyContent="space-between">
            <HStack justifyContent="center">
              <Image src={iconECard} w={{ base: '25%', xl: '20%' }} />
              <Text color="#31B07B">{efficiency?.value}</Text>
            </HStack>
            <HStack justifyContent="center">
              <Image src={iconRCard} w={{ base: '25%', xl: '20%' }} />
              <Text color="#31B07B">{distance?.value}</Text>
            </HStack>
            <HStack justifyContent="center">
              <Image src={iconLCard} w={{ base: '25%', xl: '20%' }} />

              <Text color="#31B07B">{luck?.value}</Text>
            </HStack>
            <HStack justifyContent="center">
              <Image src={iconDCard} w={{ base: '25%', xl: '20%' }} />
              <Text color="#31B07B">{recovery?.value}</Text>
            </HStack>
          </HStack>
          <Divider size="xl" mt={0} color="#ACACAC" />
          <HStack
            justifyContent="flex-end"
            alignItems="center"
            p={{ base: '0 10px 10px 10px', xl: '0 20px 20px 20px' }}
          >
            {/* <Text>
              {formatStringToNumber(item?.amount || item?.price)}
              {' '}
              {item?.token || 'XTR'}
            </Text> */}

            {/* {userInfo?.id !== item?.seller
              ? (
                <Button size="sm" color="#FFFFFF"
                backgroundColor="#FF8008" width="104px" borderRadius="20px">
                  {userId ? 'Sell' : 'Buy'}
                </Button>
              ) : (
                <Button
                  size="sm"
                  color="#FFFFFF"
                  backgroundColor="#FF8008"
                  width="104px"
                  borderRadius="20px"
                  onClick={handleCancelOrder}
                  isLoading={cancelItemOrderMutation.isLoading}
                >
                  <TextIntl text="cancelOrder" />
                </Button>
              )} */}

            <Button
              size="sm"
              color="#FFFFFF"
              backgroundColor="#FF8008"
              width="104px"
              borderRadius="20px"
              onClick={handleCancelOrder}
              isLoading={isLoading}
            >
              Import
              {/* <TextIntl text="cancelOrder" /> */}
            </Button>
          </HStack>
        </Flex>
      </Box>
    </Box>
  );
}

export default Item;
