import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/modal";

import ForgotPasswordForm from "./ForgotPasswordForm";

interface ForgotPasswordProps {
  isOpen: boolean;
  onClose: () => void;
}

function ForgotPasswordModal({
  isOpen, onClose,
}: ForgotPasswordProps) {
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      isCentered

    >
      <ModalOverlay />
      <ModalContent
        w="600px"
        maxW="95%"
        className="modal-content"
        bg="linear-gradient(rgba(20, 61, 84, 1),
rgba(95, 166, 188, 0.96))"
        p="2px"
      >
        <ModalBody
          bg="rgba(3, 20, 31, 1)"
          borderRadius="42px"
        >
          <ForgotPasswordForm />
        </ModalBody>

      </ModalContent>
    </Modal>
  );
}

export default ForgotPasswordModal;
