import { useToast } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import axios from 'src/config/axios';

export interface RegisterDto {
  username: string,
  email: string,
  password: string,
  confirmPassword: string,
  invitationCode: string,
  fullName: string
}

export interface ActiveAccountDto {
  email: string,
  activationCode: string
}

// ======== Register =======
export const register = async (values: RegisterDto) => {
  const { data } = await axios.post("/auth/register", values);
  return data;
};

export const useRegister = () => {
  const toast = useToast({ position: "top" });

  return useMutation(register, {
    onSuccess: () => {
      toast({ status: "success", description: "Register success please active your account" });
    },
    onError: (e: any) => {
      toast({ status: "error", description: e?.response?.data?.message || 'Register error!' });
    },
  });
};

// ======== Register =======
export const activeAccount = async (values: ActiveAccountDto) => {
  const { data } = await axios.patch("/auth/activate", values);
  return data;
};

export const useActiveAccount = () => {
  const toast = useToast({ position: "top" });

  return useMutation(activeAccount, {
    onSuccess: () => {
      toast({ status: "success", description: "Active success please login" });
    },
    onError: (e: any) => {
      toast({ status: "error", description: e?.response?.data?.message || 'Active account error!' });
    },
  });
};
