/* eslint-disable react/no-unescaped-entities */
import {
  Box, Text, SimpleGrid, Flex, useDisclosure, Button, useToast, Heading, Spacer, Image,
} from "@chakra-ui/react";
import { useWeb3React } from "@web3-react/core";
import { useState, useEffect } from "react";
import { useWallets } from "src/api/item";
import Deposit from "src/components/Deposit";
import TextIntl from "src/components/TextIntl";
import WalletStatus from "src/components/WalletStatus";
import Withdraw from "src/components/Withdraw";
import {
  getProvider, useGxtContract, useTravelContract, useXtrContract,
} from "src/hooks/useContract";
import Web3 from "web3";
import NoWallet from 'src/assets/images/nowallet.webp';
import Transactions from "./components/UserTransactions";
import WalletItem from "./components/WalletItem";

export interface WalletBalance {
  GXT?: number,
  XTR?: number,
  AVAX?: number
}

function Wallet() {
  const {
    isOpen: isOpenWithdraw,
    onOpen: onOpenWithdraw,
    onClose: onCloseWithdraw,
  } = useDisclosure();
  const {
    isOpen: isOpenDeposit,
    onOpen: onOpenDeposit,
    onClose: onCloseDeposit,
  } = useDisclosure();

  const { data: wallets } = useWallets();
  const gxtContract = useGxtContract();
  const xtrContract = useXtrContract();
  const travelContract = useTravelContract();

  const toast = useToast({ position: "top" });

  const { account, library } = useWeb3React();
  console.log("🚀 ~ file: index.tsx:44 ~ Wallet ~ account:", account);

  const [eWallet, setEWallet] = useState<WalletBalance>({});

  const getBalances = async () => {
    const provider = await getProvider(library?.provider);
    const web3 = new Web3(provider);
    console.log("🚀 ~ file: index.tsx:50 ~ getBalances ~ web3:", web3);
    const [GXT, XTR, AVAX] = await Promise.all([
      gxtContract.methods.balanceOf(account).call(),
      xtrContract.methods.balanceOf(account).call(),
      web3.eth.getBalance(account as string),
    ]);

    setEWallet({
      GXT: GXT / 10 ** 18,
      XTR: XTR / 10 ** 18,
      AVAX: (AVAX as any) / 10 ** 18,
    });
  };

  useEffect(() => {
    let interval: any;
    if (gxtContract && xtrContract && travelContract && account) {
      getBalances();

      interval = setInterval(() => {
        getBalances();
      }, 10000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [gxtContract, xtrContract, travelContract, account]);

  return (
    <Box>
      <Box
        bgColor="#132035"
        borderRadius="26px"
        p="20px"
        mb="20px"
      >
        <Flex
          justifyContent="space-between"
          w="100%"
          mb="20px"
          alignItems="center"
          flexDirection={{ base: 'column', lg: account ? 'row' : 'column' }}
        >
          {account ? (
            <Text
              color="#fff"
              textTransform="uppercase"
              fontSize="18px"
              fontWeight="bold"
              mb={{ lg: "0", base: '10px' }}
            >
              Spending wallet
            </Text>
          ) : (
            <>
              <Image src={NoWallet} />
              <Text my="16px">You don't have your own wallet</Text>
            </>
          )}

          <WalletStatus />

        </Flex>
        {account
          && (
            <SimpleGrid
              columns={{
                base: 1, md: 2, lg: 3,
              }}
              spacing="22px"
              justifyItems="center"
            >

              <WalletItem
                borderColor="#84D8FC"
                backgroundColor="#E7F6F4"
                token="XTR"
                balance={+(eWallet?.XTR || 0)}
              />
              <WalletItem
                borderColor="#FBCF5F"
                backgroundColor="#FAF3EA"
                token="GXT"
                balance={+(eWallet?.GXT || 0)}
              />
              <WalletItem
                borderColor="#FF9696"
                backgroundColor="#F5E9E9"
                token="AVAX"
                balance={+(eWallet?.AVAX || 0)}
              />
            </SimpleGrid>
          )}
      </Box>
      <Box
        bgColor="#132035"
        borderRadius="26px"
        p="20px"
        mb="20px"
      >
        <Flex
          justifyContent="space-between"
          w="100%"
          mb="20px"
          alignItems="center"
          flexDirection={{ base: 'column', sm: 'row' }}
          gap={{ base: '8px', sm: '16px' }}
        >
          <Text
            color="#fff"
            textTransform="uppercase"
            fontSize="18px"
            fontWeight="bold"
            mb={{ lg: "0", base: '10px' }}
          >
            Spending wallet
          </Text>
          <Spacer />
          <Button
            bgColor="#FF8008"
            borderRadius="16px"
            p="8px 32px"
            _hover={{
              backgroundColor: '#FF8008',
            }}
            onClick={onOpenWithdraw}
            fontSize="16px"
            fontWeight="600"
          >
            Withdraw token
          </Button>

          <Button
            bgColor="#FF8008"
            borderRadius="16px"
            p="8px 32px"
            _hover={{
              backgroundColor: '#FF8008',
            }}
            onClick={() => {
              if (account) {
                onOpenDeposit();
              } else {
                toast({ status: "error", description: "Please connect wallet first" });
              }
            }}
            fontSize="16px"
            fontWeight="600"
          >
            <Text>
              Deposit token
            </Text>
          </Button>
        </Flex>

        <SimpleGrid
          columns={{
            base: 1, md: 2, lg: 3,
          }}
          spacing="22px"
          justifyItems="center"
        >

          <WalletItem
            borderColor="#84D8FC"
            backgroundColor="#E7F6F4"
            token="XTR"
            balance={wallets?.XTR || 0}
            color1="#9FCFFC"
            color2="rgba(151, 205, 255, 0)"
          />
          <WalletItem
            borderColor="#FBCF5F"
            backgroundColor="#FAF3EA"
            token="GXT"
            balance={wallets?.GXT || 0}
            color1="#7FE9B6"
            color2="rgba(207, 255, 232, 0)"
          />
          <WalletItem
            borderColor="#FF9696"
            backgroundColor="#F5E9E9"
            token="AVAX"
            balance={wallets?.AVAX || 0}
            color1="#F9918B"
            color2="rgba(255, 200, 197, 0)"
          />
        </SimpleGrid>
      </Box>
      {/* <Heading color="#666666" fontSize={{ base: '18px', lg: "24px" }} mt="30px" mb="12px">
        <TextIntl text="transactionHistory" textTransform="uppercase" />
      </Heading> */}

      <Transactions />

      <Withdraw
        isOpen={isOpenWithdraw}
        onClose={onCloseWithdraw}
        wallets={wallets || {}}
        initialValues={{
          wallets,
          walletAddress: account || '',
        }}
        getBalances={getBalances}
      />

      <Deposit
        isOpen={isOpenDeposit}
        onClose={onCloseDeposit}
        wallets={eWallet || {}}
        initialValues={{
          wallets: eWallet,
        }}
        getBalances={getBalances}
      />
    </Box>
  );
}

export default Wallet;
