/* eslint-disable eqeqeq */
import {
  Box, Table,
  HStack,
  Tbody,
  Heading,
  SimpleGrid,
  Text,
  Flex,
} from "@chakra-ui/react";
import FilterSingle from 'src/components/FilterSingle';
import FilterByRangerDate from 'src/components/FilterByRangerDate';
import SortByCreatedTime from 'src/components/SortByCreatedTime';
import {
  ITEM_ORDER_STATE_OPTIONS,
  ITEM_ORDER_STATE_OBJ,
  TOKENS,
  ITEM_ORDER_TYPE_OPTIONS,
  ITEM_ORDER_TYPE_OBJ,
  ITEM_ORDER_STATE,
} from 'src/constants/transaction';

import useSearchParams from "src/utils/useSearchParams";
import FilterByPage from "src/components/FilterByPage";
import { itemShoe, useMyOrders } from "src/api/item";
import Item from "src/containers/sneaker/Item";
import { useUserInfo } from "src/api/profile";
import TextIntl from "src/components/TextIntl";
import OrderItem from "./OrderItem";

function Transactions() {
  const { params } = useSearchParams();
  const { data: ordersHistory } = useMyOrders({
    ...params,
    exclude_state: ITEM_ORDER_STATE.waiting,
  });

  const { data: ordersWaiting } = useMyOrders({
    state: ITEM_ORDER_STATE.waiting,
  });

  const { data: userInfo } = useUserInfo();

  return (
    <Box>

      <Box
        bgColor="#132035"
        borderRadius="29px"
        py="24px"
        px={{ lg: "20px", base: '8px' }}
        mb={{ lg: "40px", base: '20px' }}
      >
        <Heading color="#fff" fontSize="24px">
          <TextIntl text="sellingItems" textTransform="uppercase" />
        </Heading>
        {(ordersWaiting?.meta?.totalPages && ordersWaiting?.meta?.totalPages > 0)
          ? (
            <SimpleGrid
              columns={{
                base: 1, md: 2, xl: 3,
              }}
              spacing="36px"
              justifyItems="center"
            >

              {ordersWaiting?.items?.map((item: itemShoe) => (
                <Item
                  key={item.id}
                  item={item}
                  handleDetail={() => { }}
                />
              ))}

            </SimpleGrid>
          ) : (
            <TextIntl color="rgba(255, 255, 255, 0.5)" textAlign="center" my="40px" text="dontHaveTransactionHistory" />
          )}

        {(ordersWaiting?.meta?.totalPages > 0)
          && <FilterByPage totalPages={ordersWaiting?.meta?.totalPages} />}

      </Box>

      <Box
        bgColor="#132035"
        borderRadius="29px"
        py="20px"
        px={{ lg: "20px", base: '8px' }}
        mb="20px"
      >
        <Heading color="#fff" fontSize="24px" mb="8px">
          <TextIntl text="transactionHistory" textTransform="uppercase" />
        </Heading>
        <Flex
          mb="20px"
          pb="10px"
          gap="8px"
          flexWrap="wrap"
        >
          <FilterSingle
            title="Type"
            name="order_type"
            options={ITEM_ORDER_TYPE_OPTIONS}
            optionsObj={ITEM_ORDER_TYPE_OBJ}
          />

          <FilterByRangerDate />

          <FilterSingle
            title="State"
            name="state"
            options={ITEM_ORDER_STATE_OPTIONS}
            optionsObj={ITEM_ORDER_STATE_OBJ}
          />

          <FilterSingle
            title="Token"
            name="token"
            options={TOKENS}
            optionsObj={{}}
          />
          <SortByCreatedTime />
          {/* <FilterByReviewType /> */}
        </Flex>
        <hr style={{
          height: '4px',
          background: "#2A3951",
          border: "none",
          marginBottom: "18px",
        }}
        />
        {(ordersHistory?.meta?.totalPages && ordersHistory?.meta?.totalPages > 0)
          ? (
            <Box overflowX="auto">
              <Table w="full">
                <Tbody>
                  {ordersHistory?.items?.map((order: itemShoe) => (
                    <OrderItem order={order} key={order.id} userId={userInfo?.id} />
                  ))}
                </Tbody>

              </Table>
            </Box>
          ) : (
            <Text color="#666666" textAlign="center" my="40px">
              Don&apos;t have transaction history
            </Text>
          )}

        {ordersHistory?.meta?.totalPages && ordersHistory?.meta?.totalPages > 0
          && <FilterByPage totalPages={ordersHistory?.meta?.totalPages} />}

      </Box>
    </Box>
  );
}

export default Transactions;
