import walletConnectIcon from 'src/assets/images/walletConnectIcon.svg';
import metamask from 'src/assets/images/metamask.png';

import { walletconnect, injected } from './connectors';

export const NetworkContextName = 'Network';

export const SUPPORTED_WALLETS = {
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: metamask,
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
    chainIds: [1, 3, 56, 97],
    mobile: true,
    installLink: 'https://metamask.io/',
    installName: 'Install Metamask',
  },
  WALLETCONNECT_BSC: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: walletConnectIcon,
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#3375BB',
    chainIds: [56],
    mobile: true,
  },
};

export const DECIMALS_TO_UNIT: any = {
  9: 'nano',
  12: 'szabo',
  18: 'ether',
  6: 'picoether',
};
