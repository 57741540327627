import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from "@chakra-ui/modal";
import {
  Button, Image, Box, Flex,
} from "@chakra-ui/react";
import successIcon from 'src/assets/images/success.png';
import TextIntl from "./TextIntl";

interface SuccessModalProps {
  isOpen: boolean,
  onClose: any,
  onClick: any,
  btnText?: string,
  title: string,
  description: string
}

function SuccessModal({
  isOpen, onClose, onClick, btnText,
  title,
  description,
}: SuccessModalProps) {
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="md"
      isCentered
    >
      <ModalOverlay />
      <ModalContent className="modal-content" bg="#132035">
        <ModalCloseButton />
        <ModalBody className="modal-body">
          <Box p="24px">
            <Image src={successIcon} w="100px" mb="40px" mx="auto" mt="20px" />
            <TextIntl
              fontSize="32px"
              fontWeight="bold"
              color="#fff"
              textAlign="center"
              text={title}
            />
            <TextIntl
              fontSize="24px"
              fontWeight="400"
              color="#fff"
              textAlign="center"
              text={description}
            />

            <Flex justifyContent="center" mt="28px">

              <Button
                bgColor="#FF8008"
                borderRadius="16px"
                h="60px"
                w="210px"
                mb="18px"
                mx="auto"
                _hover={{
                  backgroundColor: '#FF8008',
                }}
                type="submit"
                onClick={onClick}
              >
                <TextIntl text={btnText || 'Continue'} />
              </Button>
            </Flex>

          </Box>
        </ModalBody>

      </ModalContent>
    </Modal>
  );
}

export default SuccessModal;
