import {
  Tr, Td, Text, Image, Flex,
} from "@chakra-ui/react";
import { ICON_BY_TOKENS } from "src/constants/token";
import { ITEM_ORDER_STATE_OBJ } from "src/constants/transaction";
import dayjs from 'dayjs';
import { formatStringToNumber } from "src/utils/utils";
import { itemShoe } from "src/api/item";
import TextIntl from "src/components/TextIntl";

const statusColors: any = {
  completed: 'green.500',
  canced: '#999999',
  waiting: 'yellow.500',
};

function OrderItem({ order, userId }: { order: itemShoe, userId: number }) {
  return (
    <Tr py="14px" verticalAlign="center" key={order.id}>
      <Td borderBottom={0} minW="150px">
        <Text
          fontSize="20px"
          fontWeight="bold"
          color="#fff"
          letterSpacing="0.01em"
        >
          {order.seller !== userId ? 'Buy item' : 'Sell item'}
        </Text>
      </Td>
      <Td borderBottom={0}>
        <Flex alignItems="center">
          <Image src={order.image} w="60px" mr="10px" />
          <Text
            fontSize="20px"
            color="#fff"
            letterSpacing="0.01em"
          >
            {order.name}
          </Text>
        </Flex>

      </Td>
      <Td borderBottom={0} minW={{ base: "300px", lg: 'auto' }}>
        <Text
          fontSize="20px"
          fontWeight="400"
          color="rgba(255, 255, 255, 0.5)"
          letterSpacing="0.01em"
        >
          {dayjs(order.updated_at || order.created_at).format('DD MMM YYYY hh:mm A')}
        </Text>
      </Td>
      <Td borderBottom={0}>
        <Flex alignItems="center">
          <Image src={ICON_BY_TOKENS[order.token]} w="24px" mr="11px" />
          <Text
            fontSize="20px"
            fontWeight="bold"
            color={order.seller !== userId ? "red.300" : 'green.300'}
            letterSpacing="0.01em"
          >
            {order.seller !== userId && '-'}
            {formatStringToNumber(order.amount)}
          </Text>
        </Flex>
      </Td>
      <Td borderBottom={0}>
        <TextIntl
          fontSize="20px"
          fontWeight="400"
          color={statusColors[order.state] || '#999999'}
          letterSpacing="0.01em"
          text={ITEM_ORDER_STATE_OBJ[order.state]}
        />
      </Td>
    </Tr>
  );
}

export default OrderItem;
