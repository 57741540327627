export enum TRAN_STATES {
  pending = 'pending',
  success = 'success',
  failed = 'failed',
}

export enum TRAN_FLOW {
  add = 'add',
  sub = 'sub',
}

export enum ITEM_ORDER_STATE {
  waiting = 'waiting',
  completed = 'completed',
  canceled = 'canceled'
}

export const ITEM_ORDER_STATE_OPTIONS = [{
  label: 'Completed',
  value: 'completed',
}, {
  label: 'Canceled',
  value: 'canceled',
}];

export const ITEM_ORDER_STATE_OBJ: any = {
  completed: 'Completed',
  canceled: 'Canceled',
};

export enum TRAN_TYPES {
  deposit = 'deposit',
  withdraw = 'withdraw',
  paidForLevelUp = 'paid-level-up',
  paidForRepair = 'paid-repair',
  buyShopItem = 'buy-shop-item',
  travelReward = 'travel-reward',
  spotReward = 'spot-reward',
  mintItems = 'mint-items',
  spotReviewOverview = 'spot-review-overview',
  spotReviewFeedback = 'spot-review-feedback',
  spotReviewPhoto = 'spot-review-photo',

}

export const TRAN_TYPE_OBJ: any = {
  deposit: 'Deposit',
  withdraw: 'Withdraw',
  'paid-level-up': 'Level up item',
  'paid-repair': 'Repair item',
  'buy-shop-item': 'Buy shop item',
  'travel-reward': 'Travel reward',
  'spot-reward': 'Spot reward',
  'mint-items': 'Mint items',
  'spot-review-overview': 'Review overview',
  'spot-review-feedback': 'Review feedback',
  'spot-review-photo': 'Review photo',
  'spot-review-reward': 'Review reward',
  'sell-marketplace-ite': 'Sell item in marketplace',
  'buy-marketplace-item': 'Buy item in marketplace',
};

export const TRANSACTION_STATE_OBJ = {
  pending: 'Pending',
  success: 'Success',
  failed: 'Failed',
};

export const TRANSACTION_STATE_OPTIONS = [{
  label: 'Pending',
  value: 'pending',
}, {
  label: 'Success',
  value: 'success',
}, {
  label: 'Failed',
  value: 'failed',
}];

export const ORDER_STATE_OBJ: any = {
  pending: 'Pending',
  success: 'Success',
  failed: 'Failed',
  processing: 'Processing',
  canceled: 'Canceled',
};

export const ORDER_STATE_OPTIONS = [{
  label: 'Pending',
  value: 'pending',
}, {
  value: 'processing',
  label: 'Processing',
}, {
  label: 'Success',
  value: 'success',
}, {
  label: 'Failed',
  value: 'failed',
}, {
  label: 'Canceled',
  value: 'canceled',
}];

export const EARN_TRANSACTION_OPTIONS = [{
  value: 'deposit',
  label: 'Deposit',
}, {
  value: 'travel-reward',
  label: 'Travel reward',
}, {
  value: 'spot-reward',
  label: 'Spot reward',
}, {
  value: 'spot-review-overview',
  label: 'Review overview',
}, {
  value: 'spot-review-feedback',
  label: 'Review feedback',
}, {
  value: 'spot-review-photo',
  label: 'Review photo',
}];

export const SPEND_TRANSACTION_OPTIONS = [{
  value: 'withdraw',
  label: 'Withdraw',
}, {
  value: 'paid-level-up',
  label: 'Level up item',
}, {
  value: 'mint-items',
  label: 'Mint items',
}, {
  value: 'paid-repair',
  label: 'Repair item',
}, {
  value: 'buy-shop-item',
  label: 'Buy shop item',
}];

export const ALL_CATEGORIES = [...EARN_TRANSACTION_OPTIONS, ...SPEND_TRANSACTION_OPTIONS];

export const TRANSACTION_TYPE_OPTIONS = [{
  label: 'Earn',
  value: 'add',
}, {
  label: 'Spend',
  value: 'sub',
}];

export const TRANSACTION_TYPE_OBJ = {
  add: 'Earn',
  sub: 'Spend',
};

export const TOKENS = [{
  label: 'XTR',
  value: 'XTR',
}, {
  label: 'GXT',
  value: 'GXT',
}, {
  label: 'AVAX',
  value: 'AVAX',
}];

export const ORDER_TYPE_OPTIONS = [{
  label: 'Withdraw',
  value: 'withdraw',
}, {
  label: 'Deposit',
  value: 'deposit',
}];

export const ORDER_TYPE_OBJ: any = {
  withdraw: 'Withdraw',
  deposit: 'Deposit',
};

export const ORDER_TYPE = {
  withdraw: 'withdraw',
  deposit: 'deposit',
};

export const ITEM_ORDER_TYPE_OPTIONS = [{
  label: 'Buy',
  value: 'buy',
}, {
  label: 'Sell',
  value: 'sell',
}];

export const ITEM_ORDER_TYPE_OBJ: any = {
  buy: 'Buy',
  sell: 'Sell',
};
