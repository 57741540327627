import { makeAutoObservable } from "mobx";

export interface ConfirmInfoDto {
  title: string,
  description: string,
  callback: any,
  isAlert?: boolean
}

class GlobalStore {
  showConfirmDialog = false;

  confirmInfo: ConfirmInfoDto | null | undefined = null;

  handleShowConfirmDialog = (confirmInfo: ConfirmInfoDto) => {
    this.confirmInfo = confirmInfo;
    this.showConfirmDialog = true;
  };

  handleCloseConfirmDialog = () => {
    this.confirmInfo = null;
    this.showConfirmDialog = false;
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export default new GlobalStore();
