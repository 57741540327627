import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Box, Button, Flex } from "@chakra-ui/react";
import { ConfirmInfoDto } from "src/stores/GlobalStore";
import TextIntl from "src/components/TextIntl";
import nftBg from 'src/assets/images/nft-bg.png';
import box from 'src/assets/images/box.webp';

interface ConfirmDialogProps {
  isOpen: boolean;
  onClose: () => void;
  confirmInfo: ConfirmInfoDto | null | undefined
}

function ConfirmBuyDialog({
  isOpen,
  onClose,
  confirmInfo,
}: ConfirmDialogProps) {
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="md"
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        bg="#132035"
        border="1px solid #BAAA96"
        borderRadius="18px"
      >
        <ModalBody
          borderRadius="18px"
          padding="unset"
          pb="20px"
        >
          <Flex flexDirection="column">
            <Box
              h="295px"
              borderRadius="18px 18px 0px 0px"
              bg={`url(${box}),url(${nftBg}),linear-gradient(115.51deg, #FDE0BF 2.78%, #FFFFFF 53.02%, #FEE2C2 99.23%)`}
              bgSize="45%,61.81%,100%"
              bgRepeat="no-repeat,no-repeat,no-repeat"
              bgPosition="center"

            />
            <TextIntl
              fontSize="24px"
              fontWeight="bold"
              color="#fff"
              mb="10px"
              textAlign="center"
              mt="40px"
              text={confirmInfo?.title}
            />

            <TextIntl
              fontSize="16px"
              fontWeight="400"
              color="#fff"
              mb="40px"
              textAlign="center"
              text={confirmInfo?.description}

            />

            <Flex alignItems="center" mb="28px" px="18px">
              <Button
                flex={1}
                mr="23px"
                border="1px solid #FFFFFF"
                borderRadius="8px"
                color="#FFFFFF"
                fontWeight="bold"
                bgColor="transparent"
                onClick={onClose}
                fontSize={{ lg: "16px", base: '14px' }}
              >
                <TextIntl text="cancel" />
              </Button>
              <Button
                flex={1}
                border="1px solid #FF8008"
                borderRadius="8px"
                color="#ffffff"
                fontSize={{ lg: "16px", base: '14px' }}
                fontWeight="bold"
                bgColor="#FF8008"
                _hover={{
                  backgroundColor: "#FF8008",
                }}
                onClick={() => {
                  if (confirmInfo?.callback) {
                    confirmInfo.callback();
                  }
                  onClose();
                }}
              >
                <TextIntl text="Ok" />
              </Button>
            </Flex>
          </Flex>
        </ModalBody>

      </ModalContent>
    </Modal>
  );
}

export default ConfirmBuyDialog;
