import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';

export const ChainId = {
  MAINNET: 43114,
  TESTNET: 43113,
};

export const injected = new InjectedConnector({
  supportedChainIds: [43113, 43114],
});

// mainnet only
export const walletconnect = new WalletConnectConnector({
  rpc: { [ChainId.MAINNET]: process.env.REACT_APP_RPC_MAINNET },
  qrcode: true,
  chainId: ChainId.MAINNET,
});

export const SUPPORT_CHAINIDS = [43113, 43114];
