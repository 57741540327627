import React from "react";
import {
  Box,
} from "@chakra-ui/react";
import { Outlet } from 'react-router-dom';
import LoginModal from 'src/components/Login/LoginModal';
import RegisterModal from "src/components/Register/RegisterModal";
import ActiveAccountModal from "src/components/ActiveAccount/ActiveAccountModal";
import { observer } from 'mobx-react';
import AuthStore from "src/stores/AuthStore";
import ConfirmDialog from "src/components/ConfirmDialog";
import GlobalStore from "src/stores/GlobalStore";
import ForgotPasswordModal from "src/components/ForgotPassword/ForgotPasswordModal";
import ResetPasswordModal from "src/components/ResetPassword/ResetPasswordModal";
import bgr from 'src/assets/images/bgr.webp';
import Header from "../components/Header";
import Footer from "../components/Footer";

function DefaultLayout() {
  const {
    showActiveAccountModal,
    showLoginModal,
    showRegisterModal,
    handleToggleActiveAccountModal,
    handleToggleLoginModal,
    handleToggleRegisterModal,
    showForgotPasswordModal,
    handleToggleForgotPasswordModal,
    showResetPasswordModal,
    handleToggleResetPasswordModal,
  } = AuthStore;

  const {
    showConfirmDialog,
    confirmInfo,
    handleCloseConfirmDialog,

  } = GlobalStore;

  return (
    <Box fontSize="xl" overflow="hidden" bgImage={bgr} bgSize="cover">
      <Header />
      <Box
        p={{ base: '65px 0 ', md: '80px 0', xl: '110px 0' }}
      >
        <Outlet />
      </Box>

      <Footer />

      <LoginModal
        isOpen={showLoginModal as boolean}
        onClose={handleToggleLoginModal}
      />
      <RegisterModal
        isOpen={showRegisterModal as boolean}
        onClose={handleToggleRegisterModal}
      />
      <ActiveAccountModal
        isOpen={showActiveAccountModal as boolean}
        onClose={handleToggleActiveAccountModal}
      />

      <ForgotPasswordModal
        isOpen={showForgotPasswordModal as boolean}
        onClose={handleToggleForgotPasswordModal}
      />

      <ResetPasswordModal
        isOpen={showResetPasswordModal as boolean}
        onClose={handleToggleResetPasswordModal}
      />

      <ConfirmDialog
        isOpen={showConfirmDialog}
        confirmInfo={confirmInfo}
        onClose={handleCloseConfirmDialog}
      />
    </Box>
  );
}

export default observer(DefaultLayout);
