import {
  Box, Flex, Image, Text,
  Link,
  VStack,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import {
  Link as RouterLink, Route, Routes, useLocation,
} from 'react-router-dom';
import { useUserInfo } from 'src/api/profile';
import avatar from 'src/assets/images/default-avatar.png';
import TextIntl from 'src/components/TextIntl';
import { ACCESS_TOKEN, USER_INFO } from 'src/constants/localStorage';
import LangStore from 'src/stores/LangStore';
import GlobalStore from 'src/stores/GlobalStore';
import { map } from 'lodash';
import AvatarImage from 'src/components/AvatarImage';
import ChangePasswordWrapper from './change-password';
import MyItems from './my-items';
import Wallet from './wallet';
import Orders from './orders';
import ItemOrders from './item-orders';

function Profile() {
  const location = useLocation();

  const queryClient = useQueryClient();

  const { data: userInfo } = useUserInfo();
  const menu = [
    {
      to: "/profile/wallet",
      text: "manageWallet",
    },
    {
      to: "/profile/orders",
      text: "transactionOrders",
    },
    {
      to: "/profile/my-items",
      text: "myItems",
    },
    {
      to: "/profile/item-orders",
      text: "itemOrders",
    },
    {
      to: "/profile/change-password",
      text: "changePassword",
    },

  ];

  return (
    <Box m={{ base: '12px 8px 65px 8px', xl: '24px auto 139px auto' }} maxW={{ base: "100%", xl: "80%" }}>
      <Flex gap="24px">
        <Box
          bgColor="#132035"
          borderRadius="26px"
          py="30px"
          px="20px"
          minH="calc(100vh - 200px)"
          width="309px"
          display={{ base: 'none', xl: 'block' }}
        >
          <VStack mb="28px" justifyContent="center">
            <AvatarImage props={avatar} w="56px" h="56px" rounded="full" mr="9px" />
            <Text fontSize="20px" fontWeight="800" color="#fff" m="0px">
              {userInfo?.fullName}
            </Text>
            <Text fontSize="18px" fontWeight="500" color="#fff" mb="0">
              {userInfo?.email}
            </Text>
          </VStack>
          <hr style={{
            height: '4px',
            background: "#041728",
            border: "none",
            marginBottom: "18px",
          }}
          />
          {menu.map((m) => (
            <Link
              as={RouterLink}
              to={m.to}
              fontSize="16px"
              fontWeight="800"
              color="#FDFDFD"
              display="block"
              mb="10px"
              p={{ base: '8px 24px' }}
              border={location.pathname.indexOf(m.to) !== -1 ? "1px solid #FF8008" : undefined}
              borderRadius="8px"
              _hover={{
                textDecoration: 'none',
                bg: "#1C2F4E",
              }}
            >
              <TextIntl text={m.text} />
            </Link>
          ))}
          <Link
            fontSize="16px"
            fontWeight="800"
            color="#FDFDFD"
            display="block"
            p={{ base: '8px 24px' }}
            _hover={{
              textDecoration: 'none',
              bg: "#1C2F4E",
            }}
            borderRadius="8px"
            onClick={() => {
              GlobalStore.handleShowConfirmDialog({
                title: 'Logout',
                description: 'Do you want to logout?',
                callback: () => {
                  localStorage.removeItem(ACCESS_TOKEN);
                  localStorage.removeItem(USER_INFO);
                  queryClient.invalidateQueries(['USER_INFO']);
                  window.location.href = `https://www.xtrip.travel/${LangStore.lang}/`;
                },
              });
            }}
          >
            <TextIntl text="logout" />

          </Link>
        </Box>
        <Box flex={{ xl: 1 }} w="100%">
          <Routes>
            <Route path="change-password" element={<ChangePasswordWrapper />} />
            <Route path="my-items" element={<MyItems />} />
            <Route path="wallet" element={<Wallet />} />
            <Route path="orders" element={<Orders />} />
            <Route path="item-orders" element={<ItemOrders />} />
          </Routes>
        </Box>
      </Flex>

    </Box>
  );
}

export default Profile;
