import WalletConnectProvider from '@walletconnect/web3-provider';
import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import Web3 from 'web3';
import erc20ABI from 'src/contracts/erc20.json';
import travelABI from 'src/contracts/travel.json';
import nftABI from 'src/contracts/nft.json';

export const getProvider = async (provider: any) => {
  const connector = localStorage.getItem('connector');
  let newProvider = provider;
  if (connector === 'WALLET_CONNECT') {
    newProvider = new WalletConnectProvider({
      rpc: { 43114: 'https://rpc.ankr.com/avalanche' },
      qrcode: true,
      chainId: 43114,
    });
    await newProvider.enable();
  }

  return newProvider;
};

export const useGxtContract = () => {
  const [gxtContract, setGxtContract] = useState<any>(null);
  const { library } = useWeb3React();

  useEffect(() => {
    const getGxtContract = async () => {
      if (library?.provider) {
        const newProvider = await getProvider(library?.provider);
        const web3 = new Web3(newProvider);
        const contract = new web3.eth.Contract(
          erc20ABI as any,
          process.env.REACT_APP_TOKEN_GXT,
        );
        setGxtContract(contract);
      } else {
        setGxtContract(null);
      }
    };

    getGxtContract();
  }, [library]);
  return gxtContract;
};

export const useXtrContract = () => {
  const [xtrContract, setXtrContract] = useState<any>(null);
  const { library } = useWeb3React();

  useEffect(() => {
    const getXtrContract = async () => {
      if (library?.provider) {
        const newProvider = await getProvider(library?.provider);
        const web3 = new Web3(newProvider);
        const contract = new web3.eth.Contract(
          erc20ABI as any,
          process.env.REACT_APP_TOKEN_XTR,
        );
        setXtrContract(contract);
      } else {
        setXtrContract(null);
      }
    };

    getXtrContract();
  }, [library]);
  return xtrContract;
};

export const useTravelContract = () => {
  const [travelContract, setTravelContract] = useState<any>(null);
  const { library } = useWeb3React();

  useEffect(() => {
    const getTravelContract = async () => {
      if (library?.provider) {
        const newProvider = await getProvider(library?.provider);
        const web3 = new Web3(newProvider);
        const contract = new web3.eth.Contract(
          travelABI as any,
          process.env.REACT_APP_TRAVEL_CONTRACT,
        );
        setTravelContract(contract);
      } else {
        setTravelContract(null);
      }
    };

    getTravelContract();
  }, [library]);
  return travelContract;
};

export const useNftContract = () => {
  const [nftContract, setNftContract] = useState<any>(null);
  const { library } = useWeb3React();

  useEffect(() => {
    const getNftContract = async () => {
      if (library?.provider) {
        const newProvider = await getProvider(library?.provider);
        const web3 = new Web3(newProvider);
        const contract = new web3.eth.Contract(
          nftABI as any,
          process.env.REACT_APP_NFT_CONTRACT,
        );
        setNftContract(contract);
      } else {
        setNftContract(null);
      }
    };

    getNftContract();
  }, [library]);
  return nftContract;
};
