import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  useDisclosure,
  PopoverFooter,
  ButtonGroup,
  RadioGroup,
  SimpleGrid,
  Radio,
} from "@chakra-ui/react";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { DateKey } from "src/types/DateKey";
import { Sort } from "src/types/Sort";
import { useTranslation } from 'react-i18next';
import TextIntl from "./TextIntl";

const OPTIONS = [
  {
    orderBy: DateKey.CreatedAt,
    sort: Sort.Desc,
    text: "Newest ",
  },
  {
    orderBy: DateKey.CreatedAt,
    sort: Sort.Asc,
    text: "Oldest ",
  },
];

function SortByCreatedTime() {
  const { t } = useTranslation();
  // const initialFocusRef = React.useRef();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();

  const orderBy = searchParams.get("orderBy");
  const sort = searchParams.get("sort");

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const setOption = (value: string) => {
    setSelectedIndex(+(value));
    searchParams.set("orderBy", OPTIONS[+(value)].orderBy);
    searchParams.set("sort", OPTIONS[+(value)].sort);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (orderBy && sort) {
      const currentSelectedIndex = OPTIONS.findIndex(
        (option) => option.orderBy === orderBy && option.sort === sort,
      );
      setSelectedIndex(currentSelectedIndex);
    }
  }, []);

  return (
    <Popover
      onClose={onClose}
      isOpen={isOpen}
    // initialFocusRef={initialFocusRef}
    >
      <PopoverTrigger>
        <Button
          rightIcon={<TriangleDownIcon boxSize={4} />}
          background="#1C2F4E"
          color={selectedIndex !== -1 ? "##FF8008" : '#fff'}
          variant="ghost"
          onClick={onOpen}
          borderRadius="17px"
          h={{ base: '40px', xl: '50px' }}
          display="flex"
          alignItems="center"
          minW="150px"
          justifyContent="space-between"
          fontSize={{ base: '14px', xl: '18px' }}
          // w={{ base: '100%', lg: 'auto' }}
          mr={{ base: "0 !important", lg: '8px !important' }}
          mb={{ base: '10px !important' }}
        >
          {selectedIndex !== -1 ? OPTIONS[selectedIndex].text : t("Sort")}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        bgColor="white"
        color="#5F5F5F"
        boxShadow="4px 5px 16px rgba(0, 0, 0, 0.25);"
        borderRadius="8px"
      >
        <PopoverCloseButton />
        <PopoverHeader borderColor="#EBEBEB" fontWeight="bold" color="#000000">
          <TextIntl text="Sort by" />
        </PopoverHeader>
        <PopoverBody>
          <RadioGroup onChange={setOption} value={selectedIndex}>
            <SimpleGrid borderWidth="1px" columns={1} border="none">
              {OPTIONS.map((option, index) => (
                <Radio
                  size="sm"
                  cursor="pointer"
                  my={2}
                  value={index}
                  key={option.sort}
                  borderColor="#ebebeb"
                  colorScheme="orange"
                >
                  {option.text}
                </Radio>
              ))}
            </SimpleGrid>
          </RadioGroup>
        </PopoverBody>
        <PopoverFooter borderColor="#EBEBEB">
          <ButtonGroup size="sm">
            <Button
              variant="outline"
              colorScheme="orange"
              onClick={() => {
                setSelectedIndex(-1);
                searchParams.delete("orderBy");
                searchParams.delete("sort");
                setSearchParams(searchParams);
                onClose();
              }}
            >
              <TextIntl text="delete" />
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

export default SortByCreatedTime;
