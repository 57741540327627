import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  useDisclosure,
  PopoverFooter,
  ButtonGroup,
  Flex,
  Input,
  Box,
  Text,
} from "@chakra-ui/react";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { Suspense, useMemo, useState } from "react";
import { css } from "@emotion/react";
import { useTranslation } from 'react-i18next';
import TextIntl from "./TextIntl";

interface DateRanger {
  fromDate: string,
  toDate: string
}
interface FilterByRangerDateProps {
  handleDeleteFilter?: () => void,
  handleApplyFilter?: (fromDate: string | null, toDate: string | null) => void,
  date?: DateRanger,
  isCloseClickOutside?: boolean
}

function Filter({
  handleDeleteFilter,
  handleApplyFilter,
  date,
  isCloseClickOutside = true,
}: FilterByRangerDateProps) {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();
  const [fromDate, setFromDate] = useState(date ? date.fromDate : searchParams.get('start_date'));
  const [toDate, setToDate] = useState(date ? date.toDate : searchParams.get('end_date'));

  const fromDateParams = date ? date.fromDate : searchParams.get('start_date');
  const toDateParams = date ? date.toDate : searchParams.get('end_date');

  const displayDate = useMemo(() => {
    if (fromDateParams && toDateParams) {
      return `${dayjs(fromDateParams).format('MM/DD/YYYY')} - ${dayjs(toDateParams).format('MM/DD/YYYY')}`;
    }

    if (fromDateParams) {
      return `>= ${dayjs(fromDateParams).format('MM/DD/YYYY')}`;
    }

    if (toDateParams) {
      return `<= ${dayjs(toDateParams).format('MM/DD/YYYY')}`;
    }

    return t('Date');
  }, [fromDateParams, toDateParams]);

  const currentDate = dayjs().format('YYYY-MM-DD');

  return (
    <Popover onClose={isCloseClickOutside ? onClose : undefined} isOpen={isOpen} size="2xl">
      <PopoverTrigger>
        <Button
          rightIcon={<TriangleDownIcon boxSize={4} />}
          colorScheme="gray"
          background="#1C2F4E"
          color={(fromDate || toDate) ? "##FF8008" : '#fff'}
          variant="ghost"
          // textTransform="capitalize"
          onClick={onOpen}
          borderRadius="17px"
          h={{ base: '40px', xl: '50px' }}
          display="flex"
          alignItems="center"
          minW="150px"
          justifyContent="space-between"
          fontSize={{ base: '14px', xl: '18px' }}
          // w={{ base: '100%', lg: 'auto' }}
          mb={{ base: '10px !important', lg: '0px !important' }}
        >
          {displayDate}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        w={{ lg: "500px", base: '350px' }}
        bgColor="white"
        color="#5F5F5F"
        boxShadow="4px 5px 16px rgba(0, 0, 0, 0.25);"
        borderRadius="8px"
      >
        <PopoverCloseButton onClick={onClose} />
        <PopoverHeader borderColor="#EBEBEB" fontWeight="bold" color="#000000">
          <TextIntl text="Date" />
        </PopoverHeader>
        <PopoverBody>
          <Flex py="20px">
            <Input
              value={fromDate || ""}
              onChange={(e) => {
                if (e.target.value <= currentDate && (!toDate || e.target.value <= toDate)) {
                  setFromDate(e.target.value);
                }
              }}
              type="date"
              placeholder="From date"
              borderRadius="6px"
              w="220px"
              max={toDate || currentDate}
              h="40px"
              borderColor="#ACACAC"
              bgColor="#ffffff"
              color="#333333"
              fontWeight="bold"
              _placeholder={{ color: '#AFAFAF' }}
              focusBorderColor="#FF8008"
              className="input"
              _hover={{
                borderColor: '#FF8008',
              }}
              css={css`
              ::-webkit-calendar-picker-indicator {
                filter: invert(80%);
              }
            `}
            />

            <Box mx="4">
              <Text>-</Text>
            </Box>

            <Input
              value={toDate || ""}
              onChange={(e) => {
                if (e.target.value <= currentDate && (!fromDate || e.target.value >= fromDate)) {
                  setToDate(e.target.value);
                }
              }}
              type="date"
              placeholder="To date"
              borderRadius="6px"
              w="220px"
              min={fromDate || undefined}
              max={currentDate}
              h="40px"
              borderColor="#ACACAC"
              bgColor="#ffffff"
              color="#333333"
              fontWeight="bold"
              _placeholder={{ color: '#AFAFAF' }}
              focusBorderColor="#FF8008"
              className="input"
              _hover={{
                borderColor: '#FF8008',
              }}
              ringColor="#ACACAC"
              css={css`
              ::-webkit-calendar-picker-indicator {
                filter: invert(80%);
              }
            `}
            />
          </Flex>
        </PopoverBody>
        <PopoverFooter borderColor="#EBEBEB">
          <Flex justifyContent="space-between">
            <ButtonGroup size="sm">
              <Button
                variant="outline"
                colorScheme="orange"
                onClick={() => {
                  if (handleDeleteFilter) {
                    handleDeleteFilter();
                  } else {
                    setFromDate('');
                    setToDate('');
                    searchParams.delete('start_date');
                    searchParams.delete('end_date');
                    setSearchParams(searchParams);
                  }

                  onClose();
                }}
              >
                <TextIntl text="delete" />
              </Button>
            </ButtonGroup>
            <ButtonGroup size="sm">
              <Button
                colorScheme="orange"
                onClick={() => {
                  if (handleApplyFilter) {
                    handleApplyFilter(fromDate, toDate);
                  } else {
                    if (fromDate) {
                      searchParams.set('start_date', `${fromDate}`);
                    } else {
                      searchParams.delete('start_date');
                    }

                    if (toDate) {
                      searchParams.set('end_date', `${toDate}`);
                    } else {
                      searchParams.delete('end_date');
                    }

                    setSearchParams(searchParams);
                  }

                  onClose();
                }}
              >
                <TextIntl text="apply" />
              </Button>
            </ButtonGroup>
          </Flex>

        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

function FilterByRangerDate({
  handleDeleteFilter,
  handleApplyFilter,
  date,
  isCloseClickOutside = true,
}: FilterByRangerDateProps) {
  return (
    <Suspense
      fallback={(
        <Button variant="ghost" isLoading>
          Date
        </Button>
      )}
    >
      <Filter
        handleDeleteFilter={handleDeleteFilter}
        handleApplyFilter={handleApplyFilter}
        date={date}
        isCloseClickOutside={isCloseClickOutside}
      />
    </Suspense>
  );
}

export default FilterByRangerDate;
