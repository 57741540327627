import React, { forwardRef, PropsWithoutRef, useState } from "react";
import {
  FormControl,
  Input,
  FormLabel,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  FormHelperText,
  Text,
} from "@chakra-ui/react";
import { useField } from "react-final-form";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { css } from "@emotion/react";
import { useTranslation } from 'react-i18next';

export interface LabeledTextFieldProps
  extends PropsWithoutRef<JSX.IntrinsicElements["input"]> {
  /** Field name. */
  className?: string;
  /** Field name. */
  name: string;
  /** Field label. */
  label?: string;
  /** Field label. */
  required?: boolean;
  /** Field bg color. */
  bgColor?: string;
  /** Field text color. */
  color?: string;
  autoComplete?: string;
  /** Field type. Doesn't include radio buttons and checkboxes */
  type?: "text" | "password" | "email" | "number" | "date" | "time";
  outerProps?: PropsWithoutRef<JSX.IntrinsicElements["div"]>;
  helperText?: string;
  onChangeValue?: any
}

export const LabeledTextField = forwardRef<
  HTMLInputElement,
  LabeledTextFieldProps
>(
  (
    {
      name,
      className,
      label,
      outerProps,
      placeholder,
      type,
      helperText,
      disabled,
      required,
      autoComplete = 'new-password',
      onChangeValue,
      bgColor = '#03141F',
      color = '#CACACA',
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const {
      input,
      meta: {
        touched, error, submitError, submitting,
      },
    } = useField(name, {
      parse: (value) => value,
    });
    const [show, setShow] = useState(false);
    const normalizedError = Array.isArray(error)
      ? error.join(", ")
      : error || submitError;

    return (
      <FormControl isInvalid={touched && normalizedError} {...outerProps}>
        <FormLabel fontSize="sm" mb="0" display="flex" alignItems="center">
          {label && t(label)}
          {' '}
          {required
            && <Text>*</Text>}

        </FormLabel>
        {helperText && <FormHelperText mb="3">{helperText}</FormHelperText>}
        <InputGroup>
          <Input
            {...input}
            disabled={submitting || disabled}
            type={show ? "text" : type}
            // required={required}
            ref={ref}
            placeholder={placeholder && t(placeholder)}
            autoComplete={autoComplete}
            css={css`
              ::-webkit-calendar-picker-indicator {
                filter: invert(80%);
              }
            `}
            h="50px"
            borderWidth="1px"
            borderRadius="8px"
            borderColor="#737373"
            bgColor={bgColor}
            color={color}
            fontWeight="bold"
            _placeholder={{ color: '#AFAFAF' }}
            className={`input${className ? ` ${className}` : ''}`}
            _hover={{
              borderColor: '#FF8008',
            }}
            _focus={{
              borderColor: '#FF8008',
              borderWidth: "1px",
              boxShadow: 'none',
            }}
            boxShadow="none"
            onChange={(e) => {
              const val = e.target.value;
              input.onChange(val);
              if (onChangeValue) {
                onChangeValue(val);
              }
            }}
          />
          {type === "password" && (
            <InputRightElement onClick={() => setShow(!show)} cursor="pointer" top="50%" transform="translateY(-50%)">
              {show ? <AiOutlineEyeInvisible color="#AFAFAF" /> : <AiOutlineEye color="#AFAFAF" />}
            </InputRightElement>
          )}
        </InputGroup>

        {touched && normalizedError && (
          <FormErrorMessage>{t(normalizedError)}</FormErrorMessage>
        )}
      </FormControl>
    );
  },
);

export default LabeledTextField;
