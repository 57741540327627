import { Form } from "react-final-form";
import { Suspense } from "react";
import {
  Image,
  Button,
  Box,
  Flex,
} from "@chakra-ui/react";
import {
  RegisterDto, useRegister,
} from "src/api/register";
import banner from 'src/assets/images/signup-banner.webp';
import AuthStore from "src/stores/AuthStore";
import LabeledTextField from "../LabeledTextField";
import { validateEmail } from "../../utils/validators";
import TextIntl from "../TextIntl";

interface RegisterError {
  email?: string,
  password?: string,
  confirmPassword?: string,
  invitationCode?: string,
  fullName?: string
}

const validate = (values: RegisterDto) => {
  const errors: RegisterError = {};

  if (!values.fullName || !values.fullName.trim()) {
    errors.fullName = 'Please enter full name';
  }

  if (!values.invitationCode || !values.invitationCode.trim()) {
    errors.invitationCode = 'Please enter invitation code';
  }

  if (!values.email || !values.email.trim()) {
    errors.email = 'Please enter your email';
  } if (!validateEmail(values.email)) {
    errors.email = 'Please enter valid email';
  }

  if (!values.password || !values.password.trim()) {
    errors.password = 'Please enter your password';
  } else if (values.password !== values.confirmPassword) {
    errors.confirmPassword = 'Password and confirm password not match';
  }

  return errors;
};

function RegisterForm() {
  const registerMutation = useRegister();

  const handleShowLogin = () => {
    AuthStore.handleToggleRegisterModal();
    setTimeout(() => {
      AuthStore.handleToggleLoginModal();
    }, 300);
  };

  const handleRegisterUser = async (values: RegisterDto) => {
    await registerMutation.mutateAsync(values);
    AuthStore.handleToggleRegisterModal();
    setTimeout(() => {
      AuthStore.handleToggleActiveAccountModal(values.email);
    }, 300);
  };

  return (
    <Form
      keepDirtyOnReinitialize
      onSubmit={handleRegisterUser}
      validate={validate}
      render={({ handleSubmit }) => (
        <form id="register-form" onSubmit={handleSubmit}>
          <Flex>
            <Image src={banner} w="329px" display={{ base: 'none', md: 'block' }} />
            <Box flex={1} px="55px">
              <TextIntl
                fontSize="24px"
                fontWeight="bold"
                textTransform="uppercase"
                color="#FAFAFA"
                mb="20px"
                textAlign="center"
                mt="44px"
                text="sign up"
              />
              <Box mb="16px">
                <LabeledTextField
                  placeholder="Username"
                  name="username"
                />
              </Box>
              <Box mb="16px">
                <LabeledTextField
                  placeholder="Email address"
                  name="email"
                />
              </Box>
              <Box mb="16px">
                <LabeledTextField
                  placeholder="Password"
                  name="password"
                  type="password"
                />
              </Box>
              <Box mb="16px">
                <LabeledTextField
                  placeholder="Confirm password"
                  name="confirmPassword"
                  type="password"
                />
              </Box>
              <Box mb="16px" w="60%" mx="auto">
                <LabeledTextField
                  className="place-holder-center"
                  placeholder="Invitation code"
                  name="invitationCode"
                />
              </Box>
              <Flex justifyContent="center">
                <Button
                  bgColor="#FF8008"
                  borderRadius="8px"
                  h="60px"
                  w="210px"
                  mb="18px"
                  mx="auto"
                  _hover={{
                    backgroundColor: '#FF8008',
                  }}
                  type="submit"
                  isLoading={registerMutation.isLoading}
                  fontSize="18px"
                  fontWeight="600"
                >
                  <TextIntl text="Go" />
                </Button>
              </Flex>

              <Flex
                fontSize="18px"
                lineHeight="21px"
                color="rgba(255, 255, 255, 0.5)"
                textAlign="center"
                justifyContent="center"
                mb="53px"
              >
                <TextIntl text="hasAccount" />

                {' '}
                <TextIntl
                  mb="0"
                  color="#FF8008"
                  fontWeight="bold"
                  ml="8px"
                  cursor="pointer"
                  _hover={{
                    textDecoration: 'underline',
                  }}
                  onClick={handleShowLogin}
                  text="login"
                />
              </Flex>
            </Box>
          </Flex>

        </form>
      )}
    />
  );
}

function RegisterFormWrapper() {
  return (
    <Suspense fallback="loading...">
      <RegisterForm />
    </Suspense>
  );
}

export default RegisterFormWrapper;
