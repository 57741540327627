import { useEffect, useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import { Web3Provider } from '@ethersproject/providers';
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core';
import { NetworkContextName } from 'src/utils/constants';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import DefaultLayout from "./layouts/DefaultLayout";
import routes from "./router";

import './style.css';

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

function getLibrary(provider: any) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 15000;
  return library;
}

export function App() {
  const location = useLocation();
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK || '');

  useEffect(() => {
    localStorage.setItem('chakra-ui-color-mode', 'dark');
  }, []);

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <Elements stripe={stripePromise}>
          <Routes>
            <Route path="/" element={<DefaultLayout />}>
              {routes.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<route.component />}
                />
              ))}
            </Route>
          </Routes>
        </Elements>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  );
}
