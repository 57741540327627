import {
  Image,
} from "@chakra-ui/react";

function AvatarImage(props: any) {
  const { profileAvatar } = props;
  let finalSrc = '/avatar/avatar_1.png';
  if (profileAvatar) {
    if (profileAvatar.startsWith("http")) {
      finalSrc = profileAvatar;
    } else {
      finalSrc = `/avatar/${profileAvatar}`;
    }
  }
  return (
    <Image
      src={finalSrc}
      fallbackSrc="/avatar/avatar_1.png"
      rounded
      w="40px"
      h="40px"
      {...props}

    />
  );
}

export default AvatarImage;
