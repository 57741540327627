import { useToast } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'src/config/axios';
import { DEFAULT_PAGE_SIZE } from 'src/constants/pagination';
import { PaginateDocument } from 'src/types/PaginateDocument';
import { QueryOptionParams } from 'src/types/QueryOptionParams';
import { isLoggedIn } from 'src/utils/utils';

export interface ChangePasswordDto {
  currentPassword: string,
  newPassword: string,
  confirmNewPassword: string
}

export interface WithdrawDto {
  token: string,
  amount: string,
  wallets: any,
  walletAddress: string,
  otp: string
}

export interface DepositDto {
  token: string,
  amount: string,
  wallets: any
}

export interface GetTransactionDto {
  trans_flow?: string,
  user_id?: string,
  search?: string,
  search_type?: string,
  token?: string,
  trans_type?: string,
  trans_state?: string,
  start_date?: string,
  end_date?: string
}

export interface TransactionDocument {
  id: number,
  trans_type: string,
  description: string,
  created_at: string,
  token: string,
  trans_flow: string,
  amount: number,
  trans_hash?: string
}

export interface OrderDocument {
  id: number,
  order_type: string,
  description: string,
  created_at: string,
  token_symbol: string,
  amount: number,
  state: string,
  transaction_hash?: string
}

export interface WithdrawFeeDto {
  tokenSymbol: string,
  amount: number,
}

export const USER_TRANSACTIONS = 'USER_TRANSACTIONS';
export const USER_ORDERS = 'USER_ORDERS';

// ======== Get assets =======
export const getAssets = async () => {
  const loggedIn = isLoggedIn();
  if (loggedIn) {
    const { data } = await axios.get("/asset/daily");
    return data;
  }
  return null;
};

export const useAssets = () => (
  useQuery(['ASSETS'], () => getAssets())
);

// ======== Get user info =======
export const getUserInfo = async () => {
  const loggedIn = isLoggedIn();
  if (loggedIn) {
    const { data } = await axios.get("/user/info");
    return data;
  }
  return null;
};

export const useUserInfo = () => (
  useQuery(['USER_INFO'], () => getUserInfo())
);

// ======== Withdraw =======
export const getWithdrawFee = async (values: WithdrawFeeDto) => {
  if (!values.amount || !values.tokenSymbol) {
    return {};
  }
  const { data } = await axios.post("/wallets/withdraw/fee", values);

  return data;
};

export const useWithdrawFee = (values: WithdrawFeeDto) => (
  useQuery(['WITHDRAW_FEE', values], () => getWithdrawFee(values))
);

// ======== Withdraw =======
export const withdraw = async (values: any) => {
  const { data } = await axios.post("/wallets/withdraw-order", values);

  return data;
};

export const useWithdraw = () => {
  const toast = useToast({ position: "top" });

  const queryClient = useQueryClient();

  return useMutation(withdraw, {
    onSuccess: () => {
      queryClient.invalidateQueries(['wallets']);
      toast({ status: "success", description: "Withdraw token success!" });
    },
    onError: (e: any) => {
      queryClient.invalidateQueries(['wallets']);
      toast({ status: "error", description: e?.response?.data?.message || "Withdraw token failure" });
    },
  });
};

// ======== Withdraw =======
export const depositToken = async (values: any) => {
  const { data } = await axios.post("/wallets/deposit-order", values);

  return data;
};

export const useDepositToken = () => {
  const toast = useToast({ position: "top" });
  const queryClient = useQueryClient();

  return useMutation(depositToken, {
    onSuccess: () => {
      queryClient.invalidateQueries(['wallets']);
      toast({ status: "success", description: "Deposit token success!" });
    },
    onError: () => {
      queryClient.invalidateQueries(['wallets']);
    },
  });
};

// ======== change password =======
export const changePassword = async (values: ChangePasswordDto) => {
  const { data } = await axios.patch("/user/change-password", values);

  return data;
};

export const useChangePassword = () => {
  const toast = useToast({ position: "top" });

  return useMutation(changePassword, {
    onSuccess: () => {
      toast({ status: "success", description: "Change password successful please relogin" });
    },
    onError: (e: any) => {
      toast({ status: "error", description: e?.response?.data?.message || "Change password error please try again" });
    },
  });
};

// ===== Get transactions ======
export const getTransactions = async (
  params?: QueryOptionParams & GetTransactionDto,
) => {
  try {
    const { data } = await axios.get<PaginateDocument<TransactionDocument>>(
      "/transaction/search",
      {
        params: {
          page: 1, limit: DEFAULT_PAGE_SIZE, ...params,
        },
      },
    );
    return data;
  } catch (e) {
    return null;
  }
};

export const useTransactions = (params?: QueryOptionParams & GetTransactionDto) => (
  useQuery([USER_TRANSACTIONS, { params }], () => getTransactions(params), { suspense: false })
);

// ===== Get transactions ======
export const getOrders = async (
  params?: QueryOptionParams & GetTransactionDto,
) => {
  try {
    const { data } = await axios.get<PaginateDocument<OrderDocument>>(
      "/wallets/orders",
      {
        params: {
          page: 1, limit: DEFAULT_PAGE_SIZE, ...params,
        },
      },
    );
    return data;
  } catch (e) {
    return null;
  }
};

export const useOrders = (params?: QueryOptionParams & GetTransactionDto) => (
  useQuery([USER_ORDERS, { params }], () => getOrders(params), { suspense: false })
);

// ======== change password =======
export const cancelOrder = async (orderId: number) => {
  const { data } = await axios.post(`/wallets/cancel-order/${orderId}`);

  return data;
};

export const useCancelOrder = () => {
  const toast = useToast({ position: "top" });

  const queryClient = useQueryClient();

  return useMutation(cancelOrder, {
    onSuccess: () => {
      toast({ status: "success", description: "Cancel order success!" });
      queryClient.invalidateQueries([USER_ORDERS]);
      queryClient.invalidateQueries(['wallets']);
    },
    onError: (e: any) => {
      toast({ status: "error", description: e?.response?.data?.message || "Cancel order failed" });
    },
  });
};

export const requestOtp = async () => {
  const { data } = await axios.get(`/wallets/withdraw/otp`);

  return data;
};
