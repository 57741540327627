import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useDisclosure,
  Box,
  Image,
  Text,
  Link,
  useMediaQuery,
  Flex,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { Link as RouterLink } from 'react-router-dom';
import { useWallets } from "src/api/item";
import { useUserInfo } from "src/api/profile";
import { ACCESS_TOKEN, USER_INFO } from "src/constants/localStorage";

import avaxToken from 'src/assets/images/avax.svg';
import gxtToken from 'src/assets/images/gxt.svg';
import xtrToken from 'src/assets/images/xtr.svg';
import { formatStringToNumber } from "src/utils/utils";
import LangStore from "src/stores/LangStore";
import TextIntl from "./TextIntl";
import defaultAvatar from '../assets/images/default-avatar.png';
import AvatarImage from "./AvatarImage";

function Avatar() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const queryClient = useQueryClient();

  const { data: userInfo } = useUserInfo();

  const [isMobile] = useMediaQuery("(max-width: 768px)");

  const { data: wallets } = useWallets();

  return userInfo ? (
    <Popover onClose={onClose} isOpen={isOpen} colorScheme="red" placement={isMobile ? "bottom" : "bottom-end"}>
      <PopoverTrigger>
        <Button
          colorScheme="gray"
          variant="ghost"
          // textTransform="capitalize"
          onClick={onOpen}
          display="flex"
          alignItems="center"
          fontSize={{ base: '15px' }}
          // w="40px"
          px={0}
          ml={{ xl: "12px" }}
          filter="drop-shadow(0px 1px 18px rgba(255, 128, 8, 0.62))"
          borderRadius="32px"
          border=" 1px solid #FF8008"
          pl={{ lg: "8px", base: '4px' }}
          pr="4px"
          py="4px"
          h="48px"

        >
          <Image src={xtrToken} w="24px" mr="6px" display={{ base: 'none', lg: 'block' }} />
          <Text fontSize="15px" fontWeight="500" color="#ffffff" mr="15px" display={{ base: 'none', lg: 'block' }}>
            {formatStringToNumber(wallets?.XTR)}
          </Text>
          <Image src={gxtToken} w="24px" mr="6px" display={{ base: 'none', lg: 'block' }} />
          <Text fontSize="15px" fontWeight="500" color="#ffffff" mr="15px" display={{ base: 'none', lg: 'block' }}>
            {formatStringToNumber(wallets?.GXT)}
          </Text>
          <Image src={avaxToken} w="24px" mr="6px" display={{ base: 'none', lg: 'block' }} />
          <Text fontSize="15px" fontWeight="500" color="#ffffff" mr="15px" display={{ base: 'none', lg: 'block' }}>
            {formatStringToNumber(wallets?.AVAX || 0)}
          </Text>
          <AvatarImage profileAvatar={defaultAvatar} />
          {/* <Image w="40px" h="40px" borderRadius="50%" objectFit="cover" /> */}

        </Button>
      </PopoverTrigger>
      <PopoverContent
        bgColor="white"
        color="#5F5F5F"
        boxShadow="4px 5px 16px rgba(0, 0, 0, 0.25);"
        borderRadius="8px"
        w="220px"
      >
        <PopoverBody>
          <Box pb="0px">
            <Text fontSize="16px" fontWeight="bold" color="#000000" px="10px">
              {userInfo?.fullName}
            </Text>
            <Text
              fontSize="14px"
              fontWeight="400"
              color="#767676"
              mb="10px"
              px="10px"
            >
              {userInfo?.email}
            </Text>

            <Flex
              pl="10px"
              pb="8px"
              mb="10px"
              borderBottom="1px solid #ebebeb"
              flexFlow="row wrap"
            >
              <Flex alignItems="center">
                <Image src={xtrToken} w="20px" mr="6px" mb="8px" />
                <Text fontSize="14px" fontWeight="500" color="#000000" mr="15px" mb="8px">
                  {formatStringToNumber(wallets?.XTR)}
                </Text>
              </Flex>
              <Flex alignItems="center">
                <Image src={gxtToken} w="20px" mr="6px" mb="8px" />
                <Text fontSize="14px" fontWeight="500" color="#000000" mr="15px" mb="8px">
                  {formatStringToNumber(wallets?.GXT)}
                </Text>
              </Flex>

              <Flex alignItems="center">
                <Image src={avaxToken} w="20px" mr="6px" mb="8px" />
                <Text fontSize="14px" fontWeight="500" color="#000000" mr="15px" mb="8px">
                  {formatStringToNumber(wallets?.AVAX || 0)}
                </Text>
              </Flex>

            </Flex>

            <Link
              as={RouterLink}
              to="/profile/wallet"
              display="block"
              py="6px"
              px="10px"
              borderRadius="6px"
              _hover={{ bg: "orange.100" }}
              onClick={onClose}
            >
              <TextIntl
                text="manageWallet"
                fontSize="14px"
                fontWeight="500"
                color="#000000"
              />

            </Link>
            <Link
              as={RouterLink}
              to="/profile/orders"
              display="block"
              py="6px"
              px="10px"
              borderRadius="6px"
              _hover={{ bg: "orange.100" }}
              onClick={onClose}
            >
              <TextIntl
                text="transactionOrders"
                fontSize="14px"
                fontWeight="500"
                color="#000000"
              />

            </Link>
            <Link
              as={RouterLink}
              to="/profile/my-items"
              display="block"
              py="6px"
              px="10px"
              borderRadius="6px"
              _hover={{ bg: "orange.100" }}
              onClick={onClose}
            >
              <TextIntl
                text="myItems"
                fontSize="14px"
                fontWeight="500"
                color="#000000"
              />

            </Link>
            <Link
              as={RouterLink}
              to="/profile/item-orders"
              display="block"
              py="6px"
              px="10px"
              borderRadius="6px"
              _hover={{ bg: "orange.100" }}
              onClick={onClose}
            >
              <TextIntl
                text="itemOrders"
                fontSize="14px"
                fontWeight="500"
                color="#000000"
              />

            </Link>
            <Link
              as={RouterLink}
              to="/profile/change-password"
              display="block"
              py="6px"
              px="10px"
              borderRadius="6px"
              _hover={{ bg: "orange.100" }}
              onClick={onClose}
            >
              <TextIntl
                text="changePassword"
                fontSize="14px"
                fontWeight="500"
                color="#000000"
              />

            </Link>
            <Link
              onClick={() => {
                onClose();
                localStorage.removeItem(ACCESS_TOKEN);
                localStorage.removeItem(USER_INFO);
                queryClient.invalidateQueries(['USER_INFO']);
                window.location.href = `https://www.xtrip.travel/${LangStore.lang}/`;
              }}
              display="block"
              py="6px"
              px="10px"
              borderRadius="6px"
              _hover={{ bg: "orange.100" }}
            >
              <TextIntl
                text="logout"
                fontSize="14px"
                fontWeight="500"
                color="#000000"
              />

            </Link>

          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  ) : null;
}

export default Avatar;
