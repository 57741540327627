/* eslint-disable no-await-in-loop */
import {
  Box, Button, Heading, HStack, SimpleGrid, Text,
} from "@chakra-ui/react";
import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { useNavigate, redirect } from "react-router-dom";
import { itemShoe, useUserItems, WalletItemDocument } from "src/api/item";
import { useUserInfo } from "src/api/profile";
import TextIntl from "src/components/TextIntl";
import WalletStatus from "src/components/WalletStatus";
import Item from "src/containers/sneaker/Item";
import WalletItem from "src/containers/sneaker/walletItem";
import { useNftContract } from "src/hooks/useContract";

function MyItems() {
  const { data: userInfo } = useUserInfo();
  const nftContract = useNftContract();
  const { account } = useWeb3React();
  const [walletItems, setWalletItems] = useState<WalletItemDocument[]>([]);
  const { data: userItems } = useUserItems();
  const navigate = useNavigate();

  const handleDetail = (id: number) => {
    navigate(`/itemDetail/${id}?sell=true`);
  };

  const getWalletItems = async () => {
    try {
      const ntfs = await nftContract.methods.balanceOf(account).call();
      if (ntfs) {
        const wItems = [] as WalletItemDocument[];
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < ntfs; index++) {
          try {
            const tokenId = await nftContract.methods.tokenOfOwnerByIndex(account, index).call();
            const tokenMetadataURI = await nftContract.methods.tokenURI(tokenId).call();
            const response = await fetch(tokenMetadataURI);
            const data: itemShoe = await response.json();
            wItems.push({
              ...data,
              tokenId,
              description: "",
              external_url: "",
              attributes: [],
            });
          } catch (error) {
            console.log(error);
          }
        }
        setWalletItems(wItems);
      }
    } catch (error) { /* empty */ }
  };

  const removeItemById = (id: number) => {
    setWalletItems((prev) => prev.filter((item) => item.tokenId !== id));
  };
  useEffect(() => {
    if (nftContract) {
      getWalletItems();
    }
  }, [nftContract]);

  return (
    <Box>

      <Box
        bgColor="#132035"
        borderRadius="29px"
        p="40px"
      >
        <Heading color="#fff" fontSize="24px" mb="20px">
          <TextIntl text="myItems" />
        </Heading>
        {userItems?.items && userItems?.items?.length > 0
          ? (
            <SimpleGrid
              columns={{
                base: 1, md: 2, lg: 3, xl: 2, '2xl': 3,
              }}
              spacing="36px"
              justifyItems="center"

            >

              {userItems?.items?.map((item: itemShoe) => (
                <Item
                  showPrice={false}
                  key={item.id}
                  item={item}
                  handleDetail={handleDetail}
                  userId={userInfo?.id}
                />
              ))}

            </SimpleGrid>
          )
          : (
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Text fontSize="18px" color="rgba(255, 255, 255, 0.5)" fontWeight="600" mb="16px" textAlign="center">
                You don&apos;t have any item
              </Text>
              <Button
                bgColor="#FF8008"
                borderRadius="16px"
                h="60px"
                w="210px"
                mb="18px"
                mx="auto"
                _hover={{
                  backgroundColor: '#FF8008',
                }}
                onClick={() => {
                  navigate('/shop');
                }}
              >
                <Text>
                  Shop now
                </Text>
              </Button>
            </Box>
          )}
      </Box>

      <Box
        bgColor="#132035"
        borderRadius="29px"
        p="40px"
        mt="30px"
      >
        <HStack justifyContent="space-between">
          <Heading color="#fff" fontSize="24px" mb="20px">
            {/* <TextIntl text="myItems" /> */}
            Wallet Items

          </Heading>
          <WalletStatus />

        </HStack>
        {walletItems?.length > 0
          ? (
            <SimpleGrid
              columns={{
                base: 1, lg: 3,
              }}
              spacing="36px"
              justifyItems="center"
            >

              {walletItems?.map((item: WalletItemDocument) => (
                <WalletItem
                  key={item.tokenId}
                  item={item}
                  removeItemById={removeItemById}
                // handleDetail={handleDetail}
                // userId={userInfo?.id}
                />
              ))}

            </SimpleGrid>
          )
          : (
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Text
                fontSize="18px"
                color="rgba(255, 255, 255, 0.5)"
                fontWeight="600"
                mb="16px"
                textAlign="center"
              >
                You don&apos;t have any item
              </Text>
            </Box>
          )}
      </Box>
    </Box>
  );
}

export default MyItems;
