import { Box, SimpleGrid, Text } from '@chakra-ui/react';
import React from 'react';
import { CanBuyDto } from 'src/api/item';
import BoxItem from './BoxItem';

function Boxes({ boxesInfo }: { boxesInfo?: CanBuyDto }) {
  return (
    <Box maxW={{ base: "100%", xl: "76.8%" }} margin="auto">
      {/* <Text mx={{ base: '25px', md: '58px' }} fontWeight="bold" fontSize="20px" mb="36px">
        Genesis box :
        {' '}
        {boxesInfo?.boxAvailable}
      </Text> */}
      <SimpleGrid
        columns={{
          base: 2, md: 3, xl: 4,
        }}
        spacing={{ base: '20px', sm: '32px' }}
        justifyItems="center"
        mx={{ base: '25px', md: '58px' }}
        mb="100px"
      >
        {Array.from({ length: Math.min(boxesInfo?.boxAvailable || 0, 24) }).map((item, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <BoxItem key={i} boxesInfo={boxesInfo} />
        ))}
      </SimpleGrid>
    </Box>
  );
}

export default Boxes;
