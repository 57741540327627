import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import axios from '../config/axios';
import { QueryOptionParams } from "../types/QueryOptionParams";
import generateQueryKey from "../utils/queryKeyFactory";
import { PaginateDocument } from "../types/PaginateDocument";

export interface SellItemDto {
  userItemId: number;
  amount: number;
  token: string;
}

export interface SearchItemOrderDto extends QueryOptionParams {
  exclude_state?: string,
  state?: string
}

export interface NftBase {
  id: number;
  image: string;
  code: string;
  name: string
}
export interface itemShoe extends NftBase{
  available_point:number,
  // code: string,
  created_at:string,
  deleted_at: string,
  distance: number,
  efficiency: number,
  equipped_state: number,
  // id: number,
  // image: string,
  item_category_id: number,
  level: number,
  life_time: number,
  lock_expired: any,
  lock_state: number,
  luck: number,
  max_distance: number,
  max_efficiency: number,
  max_luck: number,
  max_recovery: number,
  // name:string,
  num_of_mints: number,
  price: number,
  recovery: number,
  updated_at: string,
  user_id: any,
  amount: number,
  token: string,
  seller: number,
  state: string
  tax_amount: number;
  base_efficiency: number;
  base_luck: number;
  base_distance: number;
  base_recovery: number;
  lifetime_count: number;
  lifetime: number;
  nums_of_lifetime_recovery: number;
}
export interface wallets {
  token: string,
  balance: number,
  lockBalance: number;
}
export interface WalletItemDocument {
  tokenId: number,
  name: string,
  image: string,
  description: string,
  external_url: string,
  attributes: {
    max_value: number,
    value: number,
    trait_type: string
}[]
}

export interface ItemDocument {
  name:string,
  created_at: Date,
  updated_at: Date,
  id: string,
}

export interface UserItemsDto {
  numOfItems: number,
  items: itemShoe[],
  distance: number,
  condition: number,
  maxLifetime: number,
  lifetimeCount: number,
  hasMainItem: boolean,
}

export interface CanBuyDto {
  boxAvailable: number,
  price: number,
  currency: string,
  currencyRate: number
}

export interface PaymentIntentDTO {
  price: number,
  currencyRate: number
}

export interface sendOutItemDto {
  address: string;
  itemId: number;
  password: string;
}

export const USER_ITEM_ORDERS = 'USER_ITEM_ORDERS';

export const getItems = async (
  params?: QueryOptionParams,
) => {
  try {
    const { data } = await axios.get<PaginateDocument<itemShoe>>(
      "/item",
      {
        params: { page: 1, limit: 12, ...params },
      },
    );
    return data;
  } catch (e) {
    return null;
  }
};

export const getUserItems = async () => {
  try {
    const { data } = await axios.get<UserItemsDto>(
      "/user-items/items-statistic",
    );
    return data;
  } catch (e) {
    return null;
  }
};

export const getItem = async (id: string) => {
  try {
    const { data } = await axios.get<itemShoe>(`/item/${id}`);
    return data;
  } catch (e) {
    return null;
  }
};

const getWallets = async () => {
  try {
    const { data } = await axios.get<wallets[]>('/wallets');
    const walletData: any = {};

    data.forEach((item) => {
      walletData[item.token] = item.balance;
      walletData[`${item.token}_Max`] = item.balance - item.lockBalance;
    });
    return walletData;
  } catch (e) {
    return null;
  }
};

export const createUserItems = async (id: string) => {
  const { data } = await axios.post(`/user-items/buy/${id}`);
  return data;
};

export const getRemainBoxes = async () => {
  const { data } = await axios.get<CanBuyDto>(`/user-items/can-buy`);
  return data;
};

// hooks

export const useWallets = () => useQuery(['wallets'], () => getWallets());

export const itemKeys = generateQueryKey("item");

export const useItems = (params?: QueryOptionParams) => (
  useQuery(itemKeys.list(params), () => getItems(params))
);

export const useItem = (id: string) => useQuery(itemKeys.detail(id), () => getItem(id), {
  staleTime: Infinity,
});

export const useRemainBoxes = () => (
  useQuery(itemKeys.list({ remainBoxes: true }), () => getRemainBoxes())
);

export const useUserItems = () => (
  useQuery(itemKeys.list({ userItems: true }), () => getUserItems())
);

export const buyBox = async (payload: any) => {
  const { data } = await axios.post("/user-items/buy-one", payload);
  return data;
};

export const useBuyBox = () => {
  const queryClient = useQueryClient();
  const toast = useToast({ position: "top" });
  return useMutation(buyBox, {
    onSuccess: () => {
      queryClient.invalidateQueries(['wallets']);
      queryClient.invalidateQueries(itemKeys.all);
    },
    onError: (e: any) => {
      // console.log(e?.response);
      toast({
        status: "error",
        description: e?.response?.data?.message
       || e?.response?.data?.raw?.message
       || 'Something wrong!',
      });
    },
  });
};

export const stripeCheckCanBuy = async (payload: any) => {
  const { data } = await axios.post("v2/item-genesis-shop/buy-one/stripe-payment-intent", payload);
  return data;
};
export const stripeBuyAfterPayment = async (payload: any) => {
  const { data } = await axios.post("v2/item-genesis-shop/buy-one/stripe", payload);
  return data;
};

// ======== Sell item =======
export const sellItem = async (values: SellItemDto) => {
  const { data } = await axios.post(`/user-items/sell-item`, values);

  return data;
};

export const useSellItem = () => {
  const toast = useToast({ position: "top" });

  const queryClient = useQueryClient();

  return useMutation(sellItem, {
    onSuccess: () => {
      toast({ status: "success", description: "Sell item success!" });
      queryClient.invalidateQueries([USER_ITEM_ORDERS]);
      queryClient.invalidateQueries(['wallets']);
    },
    onError: (e: any) => {
      toast({ status: "error", description: e?.response?.data?.message || "Sell item failed" });
    },
  });
};

// ======== Buy item =======
export const buyItem = async (orderId: string) => {
  const { data } = await axios.post(`/user-items/buy-item/${orderId}`);

  return data;
};

export const useBuyItem = () => {
  const toast = useToast({ position: "top" });

  const queryClient = useQueryClient();

  return useMutation(buyItem, {
    onSuccess: () => {
      toast({ status: "success", description: "Buy item success!" });
      queryClient.invalidateQueries([USER_ITEM_ORDERS]);
      queryClient.invalidateQueries(['wallets']);
    },
    onError: (e: any) => {
      toast({ status: "error", description: e?.response?.data?.message || "Buy item failed" });
    },
  });
};

// ======== get item orders =======
export const getItemOrders = async (params: SearchItemOrderDto) => {
  const { data } = await axios.get(`/user-items/orders`, {
    params,
  });

  return data;
};

export const useItemOrders = (params: SearchItemOrderDto) => (
  useQuery([USER_ITEM_ORDERS, { params }], () => getItemOrders(params))
);

// ======== get my orders =======
export const getMyOrders = async (params: SearchItemOrderDto) => {
  const { data } = await axios.get(`/user-items/my-orders`, {
    params,
  });

  return data;
};

export const useMyOrders = (params: SearchItemOrderDto) => (
  useQuery([USER_ITEM_ORDERS, { params }], () => getMyOrders(params))
);

// ======== get order detail =======
export const getOrderDetail = async (id: string) => {
  const { data } = await axios.get(`/user-items/orders/${id}`);

  return data;
};

export const useOrderDetail = (id: string) => (
  useQuery([USER_ITEM_ORDERS, id], () => getOrderDetail(id))
);

// ======== Buy item =======
export const cancelItemOrder = async (orderId: string) => {
  const { data } = await axios.post(`/user-items/cancel-order/${orderId}`);
  return data;
};

export const useCancelItemOrder = () => {
  const toast = useToast({ position: "top" });

  const queryClient = useQueryClient();

  return useMutation(cancelItemOrder, {
    onSuccess: () => {
      toast({ status: "success", description: "Cancel item order success!" });
      queryClient.invalidateQueries([USER_ITEM_ORDERS]);
      queryClient.invalidateQueries(['wallets']);
    },
    onError: (e: any) => {
      toast({ status: "error", description: e?.response?.data?.message || "Cancel item order failed" });
    },
  });
};

export const createPaymentIntent = async (paymentIntent: PaymentIntentDTO) => {
  const { data } = await axios.post(`/user-items/can-buy/stripe`, paymentIntent);
  return data;
};
export const sendOutItem = async (reqData: sendOutItemDto) => {
  const { data } = await axios.post(`/item-blockchain/${reqData.itemId}/send-out`, reqData);
  return data;
};

export const useSendOutItem = () => {
  const toast = useToast({ position: 'top' });
  const queryClient = useQueryClient();
  return useMutation(sendOutItem, {
    onSuccess: () => {
      toast({ status: 'success', description: 'Item has been sent to your wallet' });
      queryClient.invalidateQueries(['wallets']);
      queryClient.invalidateQueries([itemKeys.all]);
    },
    onError: (e: any) => {
      toast({ status: 'error', description: e?.response?.data?.message || 'Send item to wallet failed' });
    },
  });
};

export const checkSendOutFee = async (reqData: sendOutItemDto) => {
  const { data } = await axios.get(`/item-blockchain/${reqData.itemId}/send-out-fee?address=${reqData.address}`);
  return data;
};

export const useCheckSendOutFee = () => useMutation(checkSendOutFee);
