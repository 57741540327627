import { ACCESS_TOKEN } from "src/constants/localStorage";
import Web3 from 'web3';
import { DECIMALS_TO_UNIT } from './constants';

export function isLoggedIn() {
  return !!localStorage.getItem(ACCESS_TOKEN);
}

export function formatStringToNumber(value: number, maximumFractionDigits = 2) {
  if (!value && value !== 0) {
    return '0';
  }
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits,
  });

  return formatter.format(value).replace(/,/g, ',');
}

export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return 'iOS';
  }

  return 'unknown';
};

const convertNumber = (val: number, maximumFractionDigits = 0) => (val ? formatStringToNumber(val, maximumFractionDigits).replace(/,/g, '') : 0);

export const toWei = ({ val, decimals }: {val: string | number, decimals: number}) => convertNumber(
  +(DECIMALS_TO_UNIT[decimals]
    ? Web3.utils.toWei(`${convertNumber(+val, decimals)}`, DECIMALS_TO_UNIT[decimals])
    : +val * (10 ** decimals)),
  0,
);
export const fromWei = ({ val, decimals }: {val: string | number, decimals: number}) => (
  convertNumber(+(
    DECIMALS_TO_UNIT[decimals]
      ? Web3.utils.fromWei(`${val}` || "0", DECIMALS_TO_UNIT[decimals])
      : `${+val / (10 ** decimals)}`
  ), 20)
);

export const displayAddress = (address: string) => (address?.length > 12
  ? `${address.slice(0, 6)}...${address.slice(
    address.length - 6,
    address.length,
  )}`
  : address);

export const getNFTSendOutFee = (fee: Array<any> = []) => `${fee[0].fee} ${fee[0].token}`;

export const getLifeTimePercentage = (lifetime: number, lifetimeCount: number) => {
  if (lifetime === 0) {
    return 0;
  }
  return ((lifetime - lifetimeCount) / lifetime) * 100;
};

export const getLifeTimeText = (lifetime: number, lifetimeCount: number) => `${lifetime - lifetimeCount}/${lifetime}`;
