import { useSearchParams } from "react-router-dom";

import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Text,
  HStack,
  useDisclosure,
  Box,
  PopoverFooter,
  ButtonGroup,
  Flex,
  Checkbox,
} from "@chakra-ui/react";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { Suspense, useMemo, useState } from "react";
import { Option } from "src/types/Options";
import { useTranslation } from 'react-i18next';
import TextIntl from "./TextIntl";

interface FilterProps {
  title: string,
  options: Option[],
  name: string,
  optionsObj: any
}

function Filter({
  title, options, name, optionsObj,
}: FilterProps) {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentType = searchParams.get(name);

  const [value, setValue] = useState(currentType?.split(','));

  const displayVal = useMemo(
    () => (currentType
      ? currentType?.split(',')?.map((ite) => optionsObj[ite] || ite).join(', ') : ''),
    [currentType, optionsObj],
  );

  return (
    <Popover onClose={onClose} isOpen={isOpen}>
      <PopoverTrigger>
        <Button
          rightIcon={<TriangleDownIcon boxSize={4} />}
          background="#1C2F4E"
          color={currentType ? "##FF8008" : '#fff'}
          variant="ghost"
          // textTransform="capitalize"
          onClick={onOpen}
          borderRadius="17px"
          h={{ base: '40px', xl: '50px' }}
          display="flex"
          alignItems="center"
          minW="150px"
          justifyContent="space-between"
          fontSize={{ base: '14px', xl: '18px' }}
          // w={{ base: '100%', lg: 'auto' }}
          mb={{ base: '8px !important' }}
        >
          <Text maxW="160px" noOfLines={1} display="block">
            {displayVal || (title && t(title))}
          </Text>

        </Button>
      </PopoverTrigger>
      <PopoverContent
        bgColor="white"
        color="#5F5F5F"
        boxShadow="4px 5px 16px rgba(0, 0, 0, 0.25);"
        borderRadius="8px"
      >
        <PopoverCloseButton />
        <PopoverHeader borderColor="#EBEBEB" fontWeight="bold" color="#000000">{title}</PopoverHeader>
        <PopoverBody>
          <Box maxH="56" overflowY="auto">
            {options.map((item) => (
              <HStack
                color={item.value === currentType ? "orange.500" : undefined}
                cursor="pointer"
                _hover={{ bg: "orange.100" }}
                borderRadius="md"
                key={item.value}
                p={2}
              >
                <Checkbox
                  isChecked={value && value?.indexOf(item.value) !== -1}
                  onChange={() => {
                    setValue((val) => {
                      if (!val) {
                        return [item.value];
                      }
                      if (val?.indexOf(item.value) !== -1) {
                        return val?.filter((v) => v !== item.value);
                      }
                      return [...val, item.value];
                    });
                  }}
                  colorScheme="orange"
                  iconColor="#5F5F5F"
                  borderColor="#ebebeb"
                >
                  <Text
                    fontSize="sm"
                    fontWeight="semibold"
                    textTransform="capitalize"
                    color={(value && value?.indexOf(item.value) !== -1) ? "orange.500" : undefined}
                  >
                    {item.label}
                  </Text>
                </Checkbox>

              </HStack>
            ))}
          </Box>
        </PopoverBody>
        <PopoverFooter borderColor="#EBEBEB">
          <Flex justifyContent="space-between">
            <ButtonGroup size="sm">
              <Button
                variant="outline"
                colorScheme="orange"
                onClick={() => {
                  setValue([]);
                  searchParams.delete(name);
                  setSearchParams(searchParams);
                  onClose();
                }}
              >
                <TextIntl text="delete" />
              </Button>
            </ButtonGroup>
            <ButtonGroup size="sm">
              <Button
                colorScheme="orange"
                onClick={() => {
                  if (value && value?.length > 0) {
                    searchParams.set(name, value?.join(','));
                    setSearchParams(searchParams);
                    onClose();
                  } else {
                    setValue([]);
                    searchParams.delete(name);
                    setSearchParams(searchParams);
                    onClose();
                  }
                }}
              >
                <TextIntl text="apply" />
              </Button>
            </ButtonGroup>
          </Flex>

        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

function FilterByReviewType({
  title, options, name, optionsObj,
}: FilterProps) {
  return (
    <Suspense
      fallback={(
        <Button variant="ghost" isLoading>
          {title}
        </Button>
      )}
    >
      <Filter options={options} title={title} name={name} optionsObj={optionsObj} />
    </Suspense>
  );
}

export default FilterByReviewType;
