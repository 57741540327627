import { makeAutoObservable } from "mobx";

class AuthStore {
  showLoginModal = false;

  showRegisterModal = false;

  showActiveAccountModal = false;

  showForgotPasswordModal = false;

  showResetPasswordModal = false;

  loginCallback: any;

  currentEmail = '';

  handleToggleForgotPasswordModal = () => {
    this.showForgotPasswordModal = !this.showForgotPasswordModal;
  };

  handleToggleResetPasswordModal = (email?: string) => {
    if (email) {
      this.currentEmail = email;
    }

    this.showResetPasswordModal = !this.showResetPasswordModal;
  };

  handleToggleLoginModal = (callback?: any) => {
    this.showLoginModal = !this.showLoginModal;
    if (callback) {
      this.loginCallback = callback;
    }
  };

  handleShowLoginModal = (callback?: any) => {
    this.showLoginModal = true;
    if (callback) {
      this.loginCallback = callback;
    }
  };

  handleToggleRegisterModal = () => {
    this.showRegisterModal = !this.showRegisterModal;
  };

  handleToggleActiveAccountModal = (email?: string) => {
    if (email) {
      this.currentEmail = email;
    }
    this.showActiveAccountModal = !this.showActiveAccountModal;
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export default new AuthStore();
