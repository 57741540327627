import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Text,
  HStack,
  useDisclosure,
  Box,
  Image,
} from "@chakra-ui/react";
import { observer } from 'mobx-react';
import LangStore, { LANGUAGES } from "src/stores/LangStore";
import langIcon from 'src/assets/images/lang.svg';

function Language() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { lang, changeLang } = LangStore;
  return (
    <Popover onClose={onClose} isOpen={isOpen} colorScheme="red">
      <PopoverTrigger>
        <Button
          colorScheme="gray"
          variant="ghost"
          // textTransform="capitalize"
          onClick={onOpen}
          display="flex"
          alignItems="center"
          fontSize={{ base: '15px' }}

        >
          <Image src={langIcon} w="20px" mr="8px" />
          <Text textTransform="uppercase">
            {lang}
          </Text>

        </Button>
      </PopoverTrigger>
      <PopoverContent
        bgColor="white"
        color="#5F5F5F"
        boxShadow="4px 5px 16px rgba(0, 0, 0, 0.25);"
        borderRadius="8px"
        w="150px"
      >
        <PopoverBody>
          <Box maxH="56" overflowY="auto">
            { LANGUAGES.map((item) => (
              <HStack
                onClick={() => {
                  changeLang(item.value);
                }}
                color={item.value === lang ? "orange.500" : undefined}
                cursor="pointer"
                _hover={{ bg: "orange.100" }}
                borderRadius="md"
                key={item.value}
                p={2}
              >
                <Image src={item.image} w="24px" mr="8px" />
                <Text
                  fontSize="sm"
                  fontWeight="semibold"
                  textTransform="capitalize"
                >
                  {item.label}
                </Text>
              </HStack>
            )) }

          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default observer(Language);
