import React, { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import Web3 from 'web3';
import { injected } from 'src/utils/connectors';
import { SUPPORTED_WALLETS } from 'src/utils/constants';
import { getMobileOperatingSystem } from 'src/utils/utils';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
} from "@chakra-ui/modal";
import classes from './ConnectWallet.module.scss';
import WalletItem from './WalletItem';

interface ConnectWalletProps {
  show: boolean,
  handleClose: any,

}

function ConnectWallet({ show, handleClose } : ConnectWalletProps) {
  const { activate } = useWeb3React();

  const [os, setOs] = useState('');

  useEffect(() => {
    setOs(getMobileOperatingSystem());
  }, []);

  const forceConnectAccount = async () => {
    try {
      if ((window as any).ethereum) {
        const web3 = new Web3((window as any).ethereum);

        const chainId = await web3.eth.getChainId();
        // console.log(
        //   process.env.REACT_APP_RPC_URL,
        //   process.env.REACT_APP_CHAIN_NAME,
        // )
        if (chainId !== +(process?.env?.REACT_APP_CHAIN_ID || 0)) {
          await (window as any).ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [
              {
                chainId: process.env.REACT_APP_CHAIN_ID_HEX,
                // chainName: process.env.REACT_APP_CHAIN_NAME,
                // nativeCurrency: {
                //   name: 'Binance Coin',
                //   symbol: 'BNB',
                //   decimals: 18,
                // },
                // rpcUrls: [process.env.REACT_APP_RPC_URL],
                // blockExplorerUrls: [process.env.REACT_APP_EXPLORE_URL],
              },
            ],
          });
          const accounts = await web3.eth.getAccounts();
          if (accounts[0]) {
            activate(injected, undefined, true).catch(() => {
              // console.error('Failed to activate after accounts changed', error)
            });
          }
          return;
        }

        const accounts = await web3.eth.getAccounts();

        if (accounts && accounts.length > 0) {
          await (window as any).ethereum.request({
            method: 'wallet_requestPermissions',
            params: [
              {
                eth_accounts: {},
              },
            ],
          });
          const newAccounts = await web3.eth.getAccounts();
          if (newAccounts[0]) {
            activate(injected, undefined, true).catch(() => {
              // console.error('Failed to activate after accounts changed', error)
            });
          }
          return;
        }
        await (window as any).ethereum.request({
          method: 'eth_requestAccounts',
          params: [
            {
              eth_accounts: {},
            },
          ],
        });

        activate(injected, undefined, true).catch(() => {
          // console.error('Failed to activate after accounts changed', error)
        });
      }
    } catch (e) {
      // console.log(e)
    }
  };

  const handleConnect = async (item: any) => {
    if (item.connector === injected && (window as any).ethereum) {
      forceConnectAccount();
    } else {
      activate(item.connector, undefined, true).catch(() => {
        // console.log('error ===>', error)
      });
    }
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={show}
      onClose={handleClose}
      size="lg"
      isCentered
    >
      <ModalOverlay />
      <ModalContent className="modal-content" py="20px">
        <ModalHeader color="#333333">
          Connect to a wallet
        </ModalHeader>
        <ModalCloseButton color="#333333" top="34px" right="20px" />
        <ModalBody className="modal-body" mt="20px">
          {Object.values(SUPPORTED_WALLETS)
            .filter((item) => os === 'unknown' || item.mobile)
            .map((item) => (
              <div className={classes.item} key={item.name}>
                <WalletItem item={item} handleConnect={handleConnect} />
              </div>
            ))}
        </ModalBody>

      </ModalContent>
    </Modal>

  );
}

export default ConnectWallet;
