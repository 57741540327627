import React, { forwardRef, PropsWithoutRef } from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Text,
  RadioGroup,
  Radio,
  Stack,
  Flex,
} from "@chakra-ui/react";
import { useField } from "react-final-form";
import { TOKENS } from "src/constants/token";
import { formatStringToNumber } from "src/utils/utils";

export interface LabeledTextFieldProps
  extends PropsWithoutRef<JSX.IntrinsicElements["input"]> {
  /** Field name. */
  name: string;
  /** Field label. */
  label?: string;
  /** Field label. */
  required?: boolean;
  autoComplete?: string;
  /** Field type. Doesn't include radio buttons and checkboxes */
  outerProps?: PropsWithoutRef<JSX.IntrinsicElements["div"]>;
  helperText?: string;
  unit?: any,
  wallets: any,
  onChangeValue?: any
}

export const SelectTokenField = forwardRef<
  HTMLInputElement,
  LabeledTextFieldProps
>(
  (
    {
      name,
      label,
      outerProps,
      helperText,
      // disabled,
      required,
      wallets,
      onChangeValue,
    },
    // ref,
  ) => {
    const {
      input,
      meta: {
        touched, error, submitError,
      },
    } = useField(name, {
      parse: (value) => value,
    });
    const normalizedError = Array.isArray(error)
      ? error.join(", ")
      : error || submitError;

    return (
      <FormControl isInvalid={touched && normalizedError} {...outerProps}>
        <FormLabel fontSize="sm" mb={helperText ? "0" : '2'} display="flex" alignItems="center" color="#fff">
          {label}
          {' '}
          { required
            && <Text>*</Text>}

        </FormLabel>
        {helperText && <FormHelperText mb="3">{helperText}</FormHelperText>}
        <RadioGroup
          onChange={(val) => {
            input.onChange(val);
            if (onChangeValue) {
              onChangeValue(val);
            }
          }}
          value={input.value}
        >
          <Stack direction="row" flexWrap="wrap">
            { TOKENS.map((item: any) => (
              <Radio
                key={item.value}
                value={item.value}
                colorScheme="orange"
                size="lg"
                borderColor="#ACACAC"
                ml="0 !important"
                px="10px"
                py="4px"
              >
                <Flex alignItems="center">
                  <Text color="#fff" fontSize="20px" fontWeight="bold">
                    {item.label}
                  </Text>
                  <Flex color="rgba(255, 255, 255, 0.5)" fontSize="20px" fontWeight="bold" ml="10px">
                    (
                    {formatStringToNumber(wallets[item.value] || 0)}
                    )
                  </Flex>

                </Flex>

              </Radio>
            )) }
          </Stack>
        </RadioGroup>

        {touched && normalizedError && (
          <FormErrorMessage>{normalizedError}</FormErrorMessage>
        )}
      </FormControl>
    );
  },
);

export default SelectTokenField;
