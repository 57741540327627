import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'src/config/axios';
import { ACCESS_TOKEN, DEVICE, USER_INFO } from 'src/constants/localStorage';
import AuthStore from 'src/stores/AuthStore';

export interface LoginDto {
  email: string,
  password: string,
  deviceId: string,
  forceLogin: boolean
}

export interface ForgotPasswordDto {
  email: string,
}

export interface ResetPasswordDto {
  email: string,
  code: string,
  password: string,
  confirmPassword: string
}

// ======== Login =======
export const login = async (values: LoginDto) => {
  const { data } = await axios.post("/auth/login", values);

  localStorage.setItem(ACCESS_TOKEN, data.token);
  localStorage.setItem(DEVICE, data.deviceId);
  localStorage.setItem(USER_INFO, data.userInfo);

  if (AuthStore.loginCallback) {
    AuthStore.loginCallback();
    AuthStore.loginCallback = null;
  }

  return data;
};

export const useLogin = () => {
  const toast = useToast({ position: "top" });
  const queryClient = useQueryClient();

  return useMutation(login, {
    onSuccess: () => {
      queryClient.invalidateQueries(['USER_INFO']);
      toast({ status: "success", description: "Login successful!" });
    },
    onError: (e: any) => {
      toast({ status: "error", description: e?.response?.data?.message || "Login error please try again" });
    },
  });
};

// ======== Forgot password =======
export const forgotPassword = async (values: ForgotPasswordDto) => {
  const { data } = await axios.post("/auth/forgot-password", values);

  return data;
};

export const useForgotPassword = () => {
  const toast = useToast({ position: "top" });

  return useMutation(forgotPassword, {
    onSuccess: () => {
      toast({ status: "success", description: "Forgot password successful!" });
    },
    onError: (e: any) => {
      toast({ status: "error", description: e?.response?.data?.message || "Forgot password error please try again" });
    },
  });
};

// ======== Reset password =======
export const resetPassword = async (values: ResetPasswordDto) => {
  const { data } = await axios.patch("/auth/reset-password", values);

  return data;
};

export const useResetPassword = () => {
  const toast = useToast({ position: "top" });

  return useMutation(resetPassword, {
    onSuccess: () => {
      toast({ status: "success", description: "Reset password successful. Please login" });
    },
    onError: (e: any) => {
      toast({ status: "error", description: e?.response?.data?.message || "Reset password error please try again" });
    },
  });
};
